




















import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'CompanyLabelsCpt',
  props: {
    labels: Array as PropType<string[]>,
  },
});
