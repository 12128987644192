












import Vue from 'vue';

import { HeaderCpt, SidebarCpt } from './components';

export default Vue.extend({
  name: 'Dashboard',
  components: {
    HeaderCpt,
    SidebarCpt,
  },
  data: () => ({
    drawer: true,
  }),
  methods: {
    closeMenu() {
      this.drawer = !this.drawer;
    },
  },
});
