import Vue from 'vue';

export const NotificationProvider = new Vue({
  methods: {
    async success(text: string) {
      this.notifyColor(text, 'success');
    },
    async error(text: string) {
      this.notifyColor(text, 'error');
    },
    async info(text: string) {
      this.notifyColor(text, 'info');
    },
    async warning(text: string) {
      this.notifyColor(text, 'warning');
    },
    async notify(text: string) {
      this.notifyColor(text, '');
    },
    // eslint-disable-next-line
    async notifyColor(text: string, color: string) {
      this.$eventHub.$emit('SHOW-SNACK-BAR', { text, color });
    },
  },
});
