
































































































import Vue, { PropType } from 'vue';
import ButtonCpt from './ButtonCpt.vue';
import ConfirmCpt from './ConfirmCpt.vue';
import SpinnerCpt from './SpinnerCpt.vue';

export default Vue.extend({
  name: 'SortableListCpt',
  components: {
    ButtonCpt,
    ConfirmCpt,
    SpinnerCpt,
  },
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    list: {
      type: Array as PropType<any[]>,
      required: true,
    },
    selectable: {
      type: Boolean,
      required: false,
      default: false,
    },
    mandatory: {
      type: Boolean,
      required: false,
      default: false,
    },
    showItemActions: {
      type: Boolean,
      required: false,
      default: false,
    },
    listName: {
      type: String,
      required: false,
      default: '',
    },
    model: {
      type: Number,
      required: false,
      default: null,
    },
    titleProp: {
      type: String,
      required: false,
      default: 'title',
    },
    requireConfirming: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  watch: {
    model() {
      if (this.selectable) {
        this.selectedItem = this.model;
      }
    },
  },
  created() {
    if (this.selectable) {
      this.selectedItem = this.model;
    }
  },
  data: () => ({
    selectedItem: -1,
    draggingItem: { index: 0, id: 0, dropIndex: 0 },
    showConfirm: false,
  }),
  methods: {
    itemSelected() {
      if (!this.selectable) {
        this.selectedItem = -1;
        return;
      }

      const selected: any = this.list ? this.list[this.selectedItem] : { id: 0 };
      this.$emit('selected', selected.id || 0, this.selectedItem);
    },
    editItem(id: number) {
      this.$emit('edit', id);
    },
    deleteItem(id: number) {
      this.$emit('delete', id);
    },
    dragStart(e: DragEvent, id: number, index: number) {
      const target = e.target as any;
      target.classList.add('dragElem');
      this.draggingItem.id = id;
      this.draggingItem.index = index;
    },
    dragOver(e: DragEvent, currentIndex: number) {
      if (e.preventDefault) {
        e.preventDefault(); // Necessary. Allows us to drop.
      }
      const target = e.target as any;
      // const currentIndex = this.getMousePositionIndex(target);
      if (this.draggingItem.index > currentIndex) {
        this.setOverClassOnElement(target, 'over-top');
      } else {
        this.setOverClassOnElement(target, 'over-bottom');
      }
    },
    getMousePositionIndex(target: any, targetId: number) {
      if (this.list && target.className.indexOf('dropzone') >= 0 && targetId !== this.draggingItem.id) {
        const arrCurrentGroup = this.list.filter(item => (item as any).id === targetId);
        if (arrCurrentGroup.length > 0) {
          return this.list.indexOf(arrCurrentGroup[0]);
        }
      }
      return -1;
    },
    setOverClassOnElement(element: any, cssClass: string) {
      element = this.findBoxElement(element);
      element.classList.add(cssClass);
    },
    findBoxElement(element: any) {
      // this covers the classes v-list-item__content and v-list-item__title
      if (element.className.indexOf('box') < 0) {
        element = element.parentElement;
        if (element.className.indexOf('box') < 0) {
          element = element.parentElement;
        }
      }
      return element;
    },
    dragLeave(e: DragEvent) {
      const element = this.findBoxElement(e.target);
      element.classList.remove('over-top');
      element.classList.remove('over-bottom');
    },
    async drop(e: DragEvent) {
      if (e.stopPropagation) {
        e.stopPropagation(); // Stops some browsers from redirecting.
      }

      const element = this.findBoxElement(e.target);
      element.classList.remove('over-top');
      element.classList.remove('over-bottom');
      const groupId = Number(element.id);
      this.draggingItem.dropIndex = this.getMousePositionIndex(element, groupId);
      if (this.draggingItem.dropIndex >= 0) {
        if (this.requireConfirming) {
          this.showConfirm = true;
        } else {
          this.confirmClosed(true);
        }
        return;
      }
      this.resetDraggingItem();
    },
    async confirmClosed(result: boolean) {
      this.showConfirm = false;

      if (result) {
        this.$emit('drop', this.draggingItem.id, this.draggingItem.dropIndex + 1);
      }
      this.resetDraggingItem();
    },
    dragEnd(e: DragEvent) {
      (e.target as any).classList.remove('dragElem');
      const element = this.findBoxElement(e.target);
      element.classList.remove('over-top');
      element.classList.remove('over-bottom');
      element.classList.remove('dragElem');
    },
    resetDraggingItem() {
      this.draggingItem = { index: 0, id: 0, dropIndex: 0 };
    },
  },
});
