import Vue from 'vue';
import axios, { AxiosRequestConfig } from 'axios';

import { NotificationProvider } from './notification.provider';
import router from '@/router';

export const AxiosFactory = new Vue({
  methods: {
    create() {
      const config: AxiosRequestConfig = {
        baseURL: process.env.VUE_APP_WEBAPI_URL,
        headers: {
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: '0',
        },
      };

      const axiosInstance = axios.create(config);

      axiosInstance.interceptors.request.use(
        async config => {
          if (this.$msal.isAuthenticated()) {
            const token = await this.$msal.getAccessToken();
            config.headers.Authorization = `Bearer ${token}`;
          }
          return config;
        },
        error => error,
      );

      axiosInstance.interceptors.response.use(
        response => response,
        error => this.onError(error),
      );

      return axiosInstance;
    },
    async onError(error: any) {
      if (error?.response?.status === 422) {
        // 422 is model validation error and we don't want any extra handling in that case
        return Promise.reject(error);
      }

      let message = 'An error occurred.';
      if (error?.response?.status) {
        switch (error.response.status) {
          case 401: {
            if (!this.$msal.isAuthenticated()) {
              message = 'Invalid session, please log in.';
              console.log('pushing /');
              router.push('/');
            } else {
              message = 'Unauthorized action.';
            }
            break;
          }
          case 403: {
            message = 'Permission denied.';
            break;
          }
          case 500: {
            if (error?.response?.data?.message) {
              message = error.response.data.message;
            }
            break;
          }
          default: {
            break;
          }
        }
      }

      NotificationProvider.error(message);
      return Promise.reject(error);
    },
  },
});
