















































































import AppVue from '@/AppVue.vue';
import { LogoCpt, ButtonCpt } from '@/core/components';

function trimEmail(email: string) {
  const limit = 15;
  return email.length < limit ? email : email.substring(0, limit);
}

export default AppVue.extend({
  name: 'HeaderCpt',
  components: {
    LogoCpt,
    ButtonCpt,
  },
  computed: {
    isDashboard() {
      return this.$route.meta?.layout === 'dashboard';
    },
    showDashboardButton() {
      return this.isAuthenticated && (this.$route.meta?.layout === 'landing' || this.$route.meta?.layout === 'default');
    },
    showAuth() {
      return this.isAuthenticated === false && this.$route.name !== 'authenticate';
    },
    showAccountSwitcher() {
      return this.currentUserId && this.currentUserId <= 2 && (this.isDevEnv || this.isTestEnv);
    },
    trimmedUserName() {
      return this.currentUserEmail ? `${trimEmail(this.currentUserEmail)}..` : 'LOADING PROFILE...';
    },
    roleContext() {
      if (this.isAdmin) {
        return 'Admin';
      }

      if (this.partnerProfile) {
        return this.partnerProfile.companyName;
      }

      return '';
    },
  },
  data: () => ({
    users: [{ id: 2, caption: 'Kit AD' }],
  }),
  methods: {
    closeMenu() {
      return this.$emit('close-menu');
    },
    async login() {
      return await this.$msal.login();
    },
    register() {
      return this.$router.push('register');
    },
    logout() {
      return this.$msal.logout();
    },
  },
});
