












import Vue from 'vue';

import { HeaderCpt } from './components';
// import { HeaderCpt, TabNavigation } from './components';

export default Vue.extend({
  name: 'Landing',
  components: {
    HeaderCpt,
    // TabNavigation,
  },
});
