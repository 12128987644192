

























































import Vue from 'vue';
import ButtonCpt from './ButtonCpt.vue';

export default Vue.extend({
  name: 'FormDialogCpt',
  components: {
    ButtonCpt,
  },
  props: {
    maxWidth: {
      type: String,
      required: false,
      default: '500',
    },
    model: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    model() {
      this.internalModel = this.model;
    },
  },
  created() {
    this.internalModel = this.model;
  },
  data: () => ({
    internalModel: false,
  }),
  methods: {
    close() {
      this.internalModel = false;
      this.$emit('close');
    },
    submitForm() {
      this.$emit('submit');
    },
  },
});
