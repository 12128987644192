



























































































import AppVue from '@/AppVue.vue';
import { LogoCpt } from '@/core/components';

interface SidebarLink {
  text: string;
  icon?: string;
  to?: string;
  subs?: SidebarLink[];
}

export default AppVue.extend({
  name: 'SidebarCpt',
  components: {
    LogoCpt,
  },
  props: {
    drawer: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  watch: {
    drawer() {
      this.internalDrawer = this.drawer;
    },
  },
  data: () => ({
    internalDrawer: true,
  }),
  created() {
    this.internalDrawer = this.drawer;
  },
  computed: {
    version: () => process.env.VUE_APP_VERSION,
    menuNodes() {
      if (!this.userProfile) {
        return [];
      }

      return this.userProfile?.isAdmin ? getAdminMenuNodes() : getUserMenuNodes();
    },
  },
  methods: {
    logout() {
      return this.$msal.logout();
    },
  },
});

/* eslint-disable indent */
function getAdminMenuNodes(): SidebarLink[] {
  return [
    { text: 'Dashboard', icon: 'mdi-view-dashboard', to: '/dashboard' },
    { text: 'Diagnostics', icon: 'mdi-file-question', to: '/admin/questionnaires' },
    { text: 'Companies', icon: 'mdi-office-building', to: '/companies' },
    { text: 'User Management', icon: 'mdi-account-multiple', to: '/admin/users' },
    { text: 'Support Resources', icon: 'mdi-web', to: '/admin/support-resources' },
    { text: 'House Floors', icon: 'mdi-home', to: '/admin/house-floors' },
    { text: 'Content management', icon: 'mdi-image-text', to: '/admin/content-management' },
    { text: 'Events management', icon: 'mdi-calendar-blank-multiple', to: '/admin/events-management' },
  ];
}

function getUserMenuNodes(): SidebarLink[] {
  const nodes: SidebarLink[] = [
    { text: 'Dashboard', icon: 'mdi-view-dashboard', to: '/dashboard' },
    { text: 'Diagnostics', icon: 'mdi-file-question', to: '/user/questionnaires' },
  ];

  return nodes;
}
/* eslint-enable indent */
