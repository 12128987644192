





import Vue from 'vue';

export default Vue.extend({
  name: 'LogoLargeMonoWhiteCpt',
  computed: {
    logoUrl: () => require('@/assets/ida-logo-white.svg'),
  },
});
