












import Vue from 'vue';

import { HeaderCpt } from './components';

export default Vue.extend({
  name: 'Default',
  components: {
    HeaderCpt,
  },
});
