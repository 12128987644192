import { AuthService } from '@/core/services/auth.service';
import { IMsalPlugin } from './IMsalPlugin';

export default class MsalPlugin {
  static install(Vue: any, options: any) {
    Vue.prototype.$msal = new MsalPlugin(options, Vue);
  }

  // eslint-disable-next-line
  constructor(options: any, Vue?: any) {
    const authService = new AuthService();

    const exposed: IMsalPlugin = {
      isAuthenticated() {
        return authService.isAuthenticated();
      },
      register() {
        authService.register();
      },
      login() {
        authService.login();
      },
      logout(redirectToRoute?: string) {
        authService.logout(redirectToRoute);
      },
      getAccessToken() {
        return authService.getAccessToken();
      },
      getLoggedInAccount() {
        return authService.getLoggedInAccount();
      },
      getLoggedInAccountOrThrow() {
        return authService.getLoggedInAccountOrThrow();
      },
      getStatus() {
        return `S ${this.isAuthenticated()}`;
      },
    };

    return exposed;
  }
}
