







import Vue from 'vue';

export default Vue.extend({
  name: 'ColourDisplayCpt',
  props: {
    colour: {
      type: String,
      required: false,
      default: '#FFFFFF',
    },
    isError: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    emptyColour() {
      // eslint-disable-next-line max-len
      return 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAGElEQVQYlWNgYGCQwoKxgqGgcJA5h3yFAAs8BRWVSwooAAAAAElFTkSuQmCC) repeat';
    },
  },
});
