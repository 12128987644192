import _ from 'lodash';

import { SimpleLookup } from '@/core/webapi';

export const LookupsService = {
  findLookup(lookups: SimpleLookup[], lookupId: number) {
    return _.find(lookups, { id: lookupId });
  },
  findLookupId(lookups: SimpleLookup[], description: string) {
    const lookup = _.find(lookups, { description });
    return lookup ? lookup.id : null;
  },
  findLookupDescription(lookups: SimpleLookup[], lookupId: number): string {
    return this.findLookupProperty(lookups, lookupId, 'description');
  },
  findLookupName(lookups: SimpleLookup[], lookupId: number): string {
    return this.findLookupProperty(lookups, lookupId, 'name');
  },
  findLookupProperty(lookups: any[], lookupId: number, propName: string): any {
    const lookup = this.findLookup(lookups, lookupId) as any;
    return lookup ? lookup[propName as any] : null;
  },
};
