import Vue from 'vue';

import { Validatable } from '@/core/models';

// TODO: Think of a better name for this file / const
export const AppFormComponent = new Vue({
  methods: {
    errorMessage(model: Validatable, prop: string) {
      if (!prop || !model || !model.errors) {
        return '';
      }

      const messages = model.errors[prop];

      if (!messages || messages.length === 0) {
        return '';
      }
      return messages[0] || '';
    },
    clearErrorMessage(model: Validatable, prop: string) {
      if (!prop || !model || !model.errors) {
        return;
      }

      const messages = model.errors[prop];

      if (!messages || messages.length === 0) {
        return;
      }

      delete model.errors[prop];
    },
    onChange(vm: any, value: any, model: Validatable, prop: string) {
      this.$emit('change', value);
      vm.$emit('change', value);

      if (!prop || !model || !model.errors) {
        return;
      }

      model.errors[prop] = null as any;
    },
  },
});
