



















import Vue from 'vue';
import ButtonCpt from './ButtonCpt.vue';

export default Vue.extend({
  name: 'SnackbarCpt',
  components: {
    ButtonCpt,
  },
  data: () => ({
    snackbar: false,
    text: '',
    color: '',
  }),
  created() {
    this.$eventHub.$off('SHOW-SNACK-BAR');

    // On grid interaction, update the route and re-fetch data
    this.$eventHub.$on('SHOW-SNACK-BAR', (data: any) => {
      this.text = data.text;
      this.color = data.color;
      this.snackbar = true;
    });
  },
});
