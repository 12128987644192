import Vue from 'vue';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
// Uncomment only if needed, to not pull unnecessary code into the final app bundle
// import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(utc);
dayjs.extend(customParseFormat);

function toLocalTime(value: string) {
  return dayjs(value).utc(true).local();
}

Vue.filter('formatDate', (value: string, format: string) => (!value ? '' : toLocalTime(value).format(format)));
// Vue.filter('fromNow', (value: string) => (!value ? '' : toLocalTime(value).fromNow()));
