import Vue from 'vue';
import VueRouter from 'vue-router';

import { homeRoutes } from '@/modules/home/routes';
import { authRoutes } from '@/modules/auth/routes';
import { usersRoutes } from '@/modules/users/routes';
import { companiesRoutes } from '@/modules/companies/routes';
import { questionnairesRoutes } from '@/modules/questionnaires/routes';
import { supportResourcesRoutes } from '@/modules/support-resources/routes';
import { houseFloorsRoutes } from '@/modules/house-floors/routes';
import { contentManagementRoutes } from '@/modules/content-management/routes';
import { eventsRoutes } from '@/modules/events/routes';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...homeRoutes,
    ...authRoutes,
    ...usersRoutes,
    ...companiesRoutes,
    ...questionnairesRoutes,
    ...supportResourcesRoutes,
    ...houseFloorsRoutes,
    ...contentManagementRoutes,
    ...eventsRoutes,
  ],
});

router.beforeEach((to, from, next) => {
  const allowAnonymous = to.matched.some(record => record.meta.allowAnonymous);
  const msal = router.app.$msal;
  const isAuthenticated = msal.isAuthenticated();

  // For duplicate redirects
  // SEE: https://stackoverflow.com/a/59431264/413785

  if (!to.name) {
    next('/');
    return;
  }

  if (!allowAnonymous) {
    if (!isAuthenticated) {
      // If a non-anonymous route and user not authenticated, redirect to default anonymous page
      next('/');
      return;
    }
    // If non-anonymous route and user authenticated, don't do anything
  }

  // go back to top on page changes
  window.scrollTo(0, 0);

  next();
});

export default router;
