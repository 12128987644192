export const usersRoutes = [
  {
    path: '/admin/users',
    name: 'user-management',
    meta: { layout: 'dashboard' },
    component: () => import(/* webpackChunkName: "users" */ './UsersGridView.vue'),
  },
  {
    path: '/users/:userId',
    name: 'dashboard-user-profile',
    meta: { layout: 'dashboard' },
    component: () => import(/* webpackChunkName: "users" */ './UserProfileView.vue'),
  },
  {
    path: '/users/:userId/edit',
    name: 'dashboard-edit-user-profile',
    meta: { layout: 'dashboard' },
    component: () => import(/* webpackChunkName: "partner-profiles" */ './EditUserProfileView.vue'),
  },
];
