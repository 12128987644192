





import Vue from 'vue';

export default Vue.extend({
  name: 'VersionChipCpt',
  props: {
    version: {
      type: Number,
      required: true,
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
});
