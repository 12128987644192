/* tslint:disable */
/* eslint-disable */
/**
 * IDA Diagnostics Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface AssignQuestionnaireToCompanyUserFormCommand
 */
export class AssignQuestionnaireToCompanyUserFormCommand {
  /**
   * Company Id.
   * @type {number}
   * @memberof AssignQuestionnaireToCompanyUserFormCommand
   */
  questionnaireVersionId: number = undefined as any;
  /**
   * Company user Id list.
   * @type {Array<number>}
   * @memberof AssignQuestionnaireToCompanyUserFormCommand
   */
  companyUserIds: Array<number> = undefined as any;

  constructor(data?: Partial<AssignQuestionnaireToCompanyUserFormCommand>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface DownloadFinalReportModel
 */
export class DownloadFinalReportModel {
  /**
   *
   * @type {string}
   * @memberof DownloadFinalReportModel
   */
  fileName: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof DownloadFinalReportModel
   */
  url: string = undefined as any;

  constructor(data?: Partial<DownloadFinalReportModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface FilterCompaniesModel
 */
export class FilterCompaniesModel {
  /**
   * Partner Id.
   * @type {number}
   * @memberof FilterCompaniesModel
   */
  id: number = undefined as any;
  /**
   * Partner name.
   * @type {string}
   * @memberof FilterCompaniesModel
   */
  name: string = undefined as any;
  /**
   * Company user count.
   * @type {number}
   * @memberof FilterCompaniesModel
   */
  userCount: number = undefined as any;
  /**
   * A count of all questionnaires submitted by users in this company.
   * @type {number}
   * @memberof FilterCompaniesModel
   */
  questionnaireSubmissionCount: number = undefined as any;
  /**
   * A count of questionnaires that have the final report generated
   * @type {number}
   * @memberof FilterCompaniesModel
   */
  completedQuestionnaireCount: number = undefined as any;
  /**
   * Created at.
   * @type {string}
   * @memberof FilterCompaniesModel
   */
  createdAt: string = undefined as any;
  /**
   * Company region
   * @type {string}
   * @memberof FilterCompaniesModel
   */
  regionName: string = undefined as any;
  /**
   * Parent Company name
   * @type {string}
   * @memberof FilterCompaniesModel
   */
  parentCompanyName: string = undefined as any;
  /**
   * Level 1 sectors.
   * @type {string}
   * @memberof FilterCompaniesModel
   */
  topLvlSectors: string = undefined as any;
  /**
   * Division
   * @type {string}
   * @memberof FilterCompaniesModel
   */
  division: string = undefined as any;

  constructor(data?: Partial<FilterCompaniesModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface FilterCompaniesModelGridCollection
 */
export class FilterCompaniesModelGridCollection {
  /**
   *
   * @type {Array<FilterCompaniesModel>}
   * @memberof FilterCompaniesModelGridCollection
   */
  items?: Array<FilterCompaniesModel> | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterCompaniesModelGridCollection
   */
  currentPage: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterCompaniesModelGridCollection
   */
  pageSize: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterCompaniesModelGridCollection
   */
  pageCount: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterCompaniesModelGridCollection
   */
  totalItemCount: number = undefined as any;

  constructor(data?: Partial<FilterCompaniesModelGridCollection>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface FilterCompaniesQuery
 */
export class FilterCompaniesQuery {
  /**
   *
   * @type {number}
   * @memberof FilterCompaniesQuery
   */
  regionId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterCompaniesQuery
   */
  parentCompanyId?: number | null = undefined as any;
  /**
   * Lvl1 sector id.
   * @type {number}
   * @memberof FilterCompaniesQuery
   */
  sectorId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterCompaniesQuery
   */
  divisionId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterCompaniesQuery
   */
  questionnaireId?: number | null = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof FilterCompaniesQuery
   */
  isCompleted?: boolean | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterCompaniesQuery
   */
  page?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterCompaniesQuery
   */
  pageSize?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof FilterCompaniesQuery
   */
  sortBy: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof FilterCompaniesQuery
   */
  sortOrder: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof FilterCompaniesQuery
   */
  includeDeleted?: boolean | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterCompaniesQuery
   */
  userId?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof FilterCompaniesQuery
   */
  search: string = undefined as any;

  constructor(data?: Partial<FilterCompaniesQuery>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface FilterContentPlaceholderModel
 */
export class FilterContentPlaceholderModel {
  /**
   * Content Placeholder Id.
   * @type {number}
   * @memberof FilterContentPlaceholderModel
   */
  id: number = undefined as any;
  /**
   * Questionnaire type Id.
   * @type {number}
   * @memberof FilterContentPlaceholderModel
   */
  questionnaireTypeId: number = undefined as any;
  /**
   * Questionnaire type
   * @type {string}
   * @memberof FilterContentPlaceholderModel
   */
  questionnaireType: string = undefined as any;
  /**
   * Key
   * @type {string}
   * @memberof FilterContentPlaceholderModel
   */
  key: string = undefined as any;
  /**
   * Value
   * @type {string}
   * @memberof FilterContentPlaceholderModel
   */
  value: string = undefined as any;
  /**
   * Is image
   * @type {boolean}
   * @memberof FilterContentPlaceholderModel
   */
  isImage: boolean = undefined as any;

  constructor(data?: Partial<FilterContentPlaceholderModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface FilterContentPlaceholderModelGridCollection
 */
export class FilterContentPlaceholderModelGridCollection {
  /**
   *
   * @type {Array<FilterContentPlaceholderModel>}
   * @memberof FilterContentPlaceholderModelGridCollection
   */
  items?: Array<FilterContentPlaceholderModel> | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterContentPlaceholderModelGridCollection
   */
  currentPage: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterContentPlaceholderModelGridCollection
   */
  pageSize: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterContentPlaceholderModelGridCollection
   */
  pageCount: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterContentPlaceholderModelGridCollection
   */
  totalItemCount: number = undefined as any;

  constructor(data?: Partial<FilterContentPlaceholderModelGridCollection>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface FilterContentPlaceholderQuery
 */
export class FilterContentPlaceholderQuery {
  /**
   *
   * @type {number}
   * @memberof FilterContentPlaceholderQuery
   */
  questionnaireTypeId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterContentPlaceholderQuery
   */
  page?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterContentPlaceholderQuery
   */
  pageSize?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof FilterContentPlaceholderQuery
   */
  sortBy: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof FilterContentPlaceholderQuery
   */
  sortOrder: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof FilterContentPlaceholderQuery
   */
  includeDeleted?: boolean | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterContentPlaceholderQuery
   */
  userId?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof FilterContentPlaceholderQuery
   */
  search: string = undefined as any;

  constructor(data?: Partial<FilterContentPlaceholderQuery>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface FilterQuestionOptionLevelModel
 */
export class FilterQuestionOptionLevelModel {
  /**
   * Content Placeholder Id.
   * @type {number}
   * @memberof FilterQuestionOptionLevelModel
   */
  id: number = undefined as any;
  /**
   * Questionnaire type Id.
   * @type {number}
   * @memberof FilterQuestionOptionLevelModel
   */
  questionnaireTypeId: number = undefined as any;
  /**
   * Questionnaire type
   * @type {string}
   * @memberof FilterQuestionOptionLevelModel
   */
  questionnaireType: string = undefined as any;
  /**
   * Name
   * @type {string}
   * @memberof FilterQuestionOptionLevelModel
   */
  levelName: string = undefined as any;
  /**
   * Description
   * @type {string}
   * @memberof FilterQuestionOptionLevelModel
   */
  levelDescriptionName: string = undefined as any;
  /**
   * Long Description
   * @type {string}
   * @memberof FilterQuestionOptionLevelModel
   */
  longDescription: string = undefined as any;
  /**
   * Is deleted
   * @type {boolean}
   * @memberof FilterQuestionOptionLevelModel
   */
  isDeleted: boolean = undefined as any;

  constructor(data?: Partial<FilterQuestionOptionLevelModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface FilterQuestionOptionLevelModelGridCollection
 */
export class FilterQuestionOptionLevelModelGridCollection {
  /**
   *
   * @type {Array<FilterQuestionOptionLevelModel>}
   * @memberof FilterQuestionOptionLevelModelGridCollection
   */
  items?: Array<FilterQuestionOptionLevelModel> | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterQuestionOptionLevelModelGridCollection
   */
  currentPage: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterQuestionOptionLevelModelGridCollection
   */
  pageSize: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterQuestionOptionLevelModelGridCollection
   */
  pageCount: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterQuestionOptionLevelModelGridCollection
   */
  totalItemCount: number = undefined as any;

  constructor(data?: Partial<FilterQuestionOptionLevelModelGridCollection>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface FilterQuestionOptionLevelQuery
 */
export class FilterQuestionOptionLevelQuery {
  /**
   *
   * @type {number}
   * @memberof FilterQuestionOptionLevelQuery
   */
  questionnaireTypeId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterQuestionOptionLevelQuery
   */
  page?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterQuestionOptionLevelQuery
   */
  pageSize?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof FilterQuestionOptionLevelQuery
   */
  sortBy: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof FilterQuestionOptionLevelQuery
   */
  sortOrder: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof FilterQuestionOptionLevelQuery
   */
  includeDeleted?: boolean | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterQuestionOptionLevelQuery
   */
  userId?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof FilterQuestionOptionLevelQuery
   */
  search: string = undefined as any;

  constructor(data?: Partial<FilterQuestionOptionLevelQuery>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface FilterQuestionnairesModel
 */
export class FilterQuestionnairesModel {
  /**
   * Questionnaire version Id.
   * @type {number}
   * @memberof FilterQuestionnairesModel
   */
  id: number = undefined as any;
  /**
   * Questionnaire version Title.
   * @type {string}
   * @memberof FilterQuestionnairesModel
   */
  title: string = undefined as any;
  /**
   * Questionnaire version Submission status id.
   * @type {number}
   * @memberof FilterQuestionnairesModel
   */
  questionnairePublishStatusId: number = undefined as any;
  /**
   * Questionnaire version Submission status.
   * @type {string}
   * @memberof FilterQuestionnairesModel
   */
  questionnairePublishStatus: string = undefined as any;
  /**
   * Questionnaire version version.
   * @type {number}
   * @memberof FilterQuestionnairesModel
   */
  version: number = undefined as any;
  /**
   * Questionnaire version amount of groups.
   * @type {number}
   * @memberof FilterQuestionnairesModel
   */
  sections: number = undefined as any;
  /**
   * Questionnaire version amount of questions
   * @type {number}
   * @memberof FilterQuestionnairesModel
   */
  questions: number = undefined as any;
  /**
   * Questionnaire code
   * @type {string}
   * @memberof FilterQuestionnairesModel
   */
  questionnaireCode: string = undefined as any;
  /**
   * Questionnaire type id
   * @type {number}
   * @memberof FilterQuestionnairesModel
   */
  questionnaireTypeId: number = undefined as any;
  /**
   * Questionnaire type
   * @type {string}
   * @memberof FilterQuestionnairesModel
   */
  questionnaireType: string = undefined as any;
  /**
   * Questionnaire id
   * @type {number}
   * @memberof FilterQuestionnairesModel
   */
  questionnaireId: number = undefined as any;

  constructor(data?: Partial<FilterQuestionnairesModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface FilterQuestionnairesModelGridCollection
 */
export class FilterQuestionnairesModelGridCollection {
  /**
   *
   * @type {Array<FilterQuestionnairesModel>}
   * @memberof FilterQuestionnairesModelGridCollection
   */
  items?: Array<FilterQuestionnairesModel> | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterQuestionnairesModelGridCollection
   */
  currentPage: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterQuestionnairesModelGridCollection
   */
  pageSize: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterQuestionnairesModelGridCollection
   */
  pageCount: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterQuestionnairesModelGridCollection
   */
  totalItemCount: number = undefined as any;

  constructor(data?: Partial<FilterQuestionnairesModelGridCollection>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface FilterQuestionnairesQuery
 */
export class FilterQuestionnairesQuery {
  /**
   *
   * @type {number}
   * @memberof FilterQuestionnairesQuery
   */
  page?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterQuestionnairesQuery
   */
  pageSize?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof FilterQuestionnairesQuery
   */
  sortBy: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof FilterQuestionnairesQuery
   */
  sortOrder: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof FilterQuestionnairesQuery
   */
  includeDeleted?: boolean | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterQuestionnairesQuery
   */
  userId?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof FilterQuestionnairesQuery
   */
  search: string = undefined as any;

  constructor(data?: Partial<FilterQuestionnairesQuery>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface FilterSupportResourcesModel
 */
export class FilterSupportResourcesModel {
  /**
   * Support Resource Id
   * @type {number}
   * @memberof FilterSupportResourcesModel
   */
  id: number = undefined as any;
  /**
   * Title
   * @type {string}
   * @memberof FilterSupportResourcesModel
   */
  title: string = undefined as any;
  /**
   * Description
   * @type {string}
   * @memberof FilterSupportResourcesModel
   */
  description: string = undefined as any;
  /**
   * Url
   * @type {string}
   * @memberof FilterSupportResourcesModel
   */
  url: string = undefined as any;
  /**
   * Updated by user
   * @type {string}
   * @memberof FilterSupportResourcesModel
   */
  updatedBy: string = undefined as any;
  /**
   * Updated at datetime
   * @type {string}
   * @memberof FilterSupportResourcesModel
   */
  updatedAt: string = undefined as any;

  constructor(data?: Partial<FilterSupportResourcesModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface FilterSupportResourcesModelGridCollection
 */
export class FilterSupportResourcesModelGridCollection {
  /**
   *
   * @type {Array<FilterSupportResourcesModel>}
   * @memberof FilterSupportResourcesModelGridCollection
   */
  items?: Array<FilterSupportResourcesModel> | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterSupportResourcesModelGridCollection
   */
  currentPage: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterSupportResourcesModelGridCollection
   */
  pageSize: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterSupportResourcesModelGridCollection
   */
  pageCount: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterSupportResourcesModelGridCollection
   */
  totalItemCount: number = undefined as any;

  constructor(data?: Partial<FilterSupportResourcesModelGridCollection>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface FilterSupportResourcesQuery
 */
export class FilterSupportResourcesQuery {
  /**
   *
   * @type {number}
   * @memberof FilterSupportResourcesQuery
   */
  page?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterSupportResourcesQuery
   */
  pageSize?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof FilterSupportResourcesQuery
   */
  sortBy: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof FilterSupportResourcesQuery
   */
  sortOrder: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof FilterSupportResourcesQuery
   */
  includeDeleted?: boolean | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterSupportResourcesQuery
   */
  userId?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof FilterSupportResourcesQuery
   */
  search: string = undefined as any;

  constructor(data?: Partial<FilterSupportResourcesQuery>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface FilterUsersModel
 */
export class FilterUsersModel {
  /**
   * User Id.
   * @type {number}
   * @memberof FilterUsersModel
   */
  userId: number = undefined as any;
  /**
   * UserName.
   * @type {string}
   * @memberof FilterUsersModel
   */
  userName: string = undefined as any;
  /**
   * First name.
   * @type {string}
   * @memberof FilterUsersModel
   */
  firstName: string = undefined as any;
  /**
   * Last name.
   * @type {string}
   * @memberof FilterUsersModel
   */
  lastName: string = undefined as any;
  /**
   * User email.
   * @type {string}
   * @memberof FilterUsersModel
   */
  email: string = undefined as any;
  /**
   * Company id the user belongs to (for non-admin users).
   * @type {number}
   * @memberof FilterUsersModel
   */
  companyId?: number | null = undefined as any;
  /**
   * Company the user belongs to (for non-admin users).
   * @type {string}
   * @memberof FilterUsersModel
   */
  companyName: string = undefined as any;
  /**
   * Date of user creation (registration date).
   * @type {string}
   * @memberof FilterUsersModel
   */
  createdAt: string = undefined as any;
  /**
   * Date of last user activity (login or page refresh).
   * @type {string}
   * @memberof FilterUsersModel
   */
  lastActive: string = undefined as any;
  /**
   * Is user locked out?
   * @type {boolean}
   * @memberof FilterUsersModel
   */
  isLockedOut: boolean = undefined as any;
  /**
   *
   * @type {UserRoleModel}
   * @memberof FilterUsersModel
   */
  role: UserRoleModel = undefined as any;

  constructor(data?: Partial<FilterUsersModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface FilterUsersModelGridCollection
 */
export class FilterUsersModelGridCollection {
  /**
   *
   * @type {Array<FilterUsersModel>}
   * @memberof FilterUsersModelGridCollection
   */
  items?: Array<FilterUsersModel> | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterUsersModelGridCollection
   */
  currentPage: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterUsersModelGridCollection
   */
  pageSize: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterUsersModelGridCollection
   */
  pageCount: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterUsersModelGridCollection
   */
  totalItemCount: number = undefined as any;

  constructor(data?: Partial<FilterUsersModelGridCollection>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface FilterUsersQuery
 */
export class FilterUsersQuery {
  /**
   *
   * @type {number}
   * @memberof FilterUsersQuery
   */
  companyId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterUsersQuery
   */
  page?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterUsersQuery
   */
  pageSize?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof FilterUsersQuery
   */
  sortBy: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof FilterUsersQuery
   */
  sortOrder: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof FilterUsersQuery
   */
  includeDeleted?: boolean | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof FilterUsersQuery
   */
  userId?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof FilterUsersQuery
   */
  search: string = undefined as any;

  constructor(data?: Partial<FilterUsersQuery>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetAddressFromEircodeModel
 */
export class GetAddressFromEircodeModel {
  /**
   *
   * @type {string}
   * @memberof GetAddressFromEircodeModel
   */
  eircode: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GetAddressFromEircodeModel
   */
  address: string = undefined as any;

  constructor(data?: Partial<GetAddressFromEircodeModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetAdminDashboardInvitedCompany
 */
export class GetAdminDashboardInvitedCompany {
  /**
   * Company Id
   * @type {number}
   * @memberof GetAdminDashboardInvitedCompany
   */
  id: number = undefined as any;
  /**
   * Company name
   * @type {string}
   * @memberof GetAdminDashboardInvitedCompany
   */
  name: string = undefined as any;
  /**
   * Company region
   * @type {string}
   * @memberof GetAdminDashboardInvitedCompany
   */
  region: string = undefined as any;
  /**
   * Total number of users
   * @type {number}
   * @memberof GetAdminDashboardInvitedCompany
   */
  numberOfUsers: number = undefined as any;
  /**
   * Total number of users who have accepted
   * @type {number}
   * @memberof GetAdminDashboardInvitedCompany
   */
  numberOfUsersAccepted: number = undefined as any;
  /**
   * Percentage of users who have accepted
   * @type {number}
   * @memberof GetAdminDashboardInvitedCompany
   */
  percentageUsersAccepted: number = undefined as any;

  constructor(data?: Partial<GetAdminDashboardInvitedCompany>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetAdminDashboardModel
 */
export class GetAdminDashboardModel {
  /**
   *
   * @type {Array<GetAdminDashboardInvitedCompany>}
   * @memberof GetAdminDashboardModel
   */
  invitedCompanies?: Array<GetAdminDashboardInvitedCompany> | null = undefined as any;
  /**
   *
   * @type {Array<GetAdminDashboardStartedQuestionnaire>}
   * @memberof GetAdminDashboardModel
   */
  startedQuestionnaires?: Array<GetAdminDashboardStartedQuestionnaire> | null = undefined as any;

  constructor(data?: Partial<GetAdminDashboardModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetAdminDashboardStartedQuestionnaire
 */
export class GetAdminDashboardStartedQuestionnaire {
  /**
   * Questionnaire  Id
   * @type {number}
   * @memberof GetAdminDashboardStartedQuestionnaire
   */
  id: number = undefined as any;
  /**
   * Questionnaire title
   * @type {string}
   * @memberof GetAdminDashboardStartedQuestionnaire
   */
  title: string = undefined as any;
  /**
   * Company name
   * @type {string}
   * @memberof GetAdminDashboardStartedQuestionnaire
   */
  companyName: string = undefined as any;
  /**
   * User name
   * @type {string}
   * @memberof GetAdminDashboardStartedQuestionnaire
   */
  userName: string = undefined as any;
  /**
   * Questionnaire version amount of questions
   * @type {number}
   * @memberof GetAdminDashboardStartedQuestionnaire
   */
  questions: number = undefined as any;
  /**
   * Questionnaire version amount of answered questions
   * @type {number}
   * @memberof GetAdminDashboardStartedQuestionnaire
   */
  answeredQuestions: number = undefined as any;
  /**
   * Percentage of answered questions
   * @type {number}
   * @memberof GetAdminDashboardStartedQuestionnaire
   */
  percentageAnsweredQuestions: number = undefined as any;

  constructor(data?: Partial<GetAdminDashboardStartedQuestionnaire>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetAllSupportResourceQuestionCategoryCommandModel
 */
export class GetAllSupportResourceQuestionCategoryCommandModel {
  /**
   * Support Resource Question Category Id
   * @type {number}
   * @memberof GetAllSupportResourceQuestionCategoryCommandModel
   */
  id: number = undefined as any;
  /**
   * Support Resource Title
   * @type {string}
   * @memberof GetAllSupportResourceQuestionCategoryCommandModel
   */
  title: string = undefined as any;
  /**
   * Support Resource Url
   * @type {string}
   * @memberof GetAllSupportResourceQuestionCategoryCommandModel
   */
  url: string = undefined as any;

  constructor(data?: Partial<GetAllSupportResourceQuestionCategoryCommandModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetAmalgamatedInnovationScorecardReportModel
 */
export class GetAmalgamatedInnovationScorecardReportModel {
  /**
   *
   * @type {string}
   * @memberof GetAmalgamatedInnovationScorecardReportModel
   */
  pdfFile: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GetAmalgamatedInnovationScorecardReportModel
   */
  fileName: string = undefined as any;

  constructor(data?: Partial<GetAmalgamatedInnovationScorecardReportModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetAmalgamatedInnovationScorecardReportQuery
 */
export class GetAmalgamatedInnovationScorecardReportQuery {
  /**
   * Questionnaires Id.
   * @type {number}
   * @memberof GetAmalgamatedInnovationScorecardReportQuery
   */
  questionnaireId: number = undefined as any;
  /**
   * Companies id
   * @type {Array<number>}
   * @memberof GetAmalgamatedInnovationScorecardReportQuery
   */
  companyIds?: Array<number> | null = undefined as any;

  constructor(data?: Partial<GetAmalgamatedInnovationScorecardReportQuery>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetAssignQuestionnaireToCompanyUserFormModel
 */
export class GetAssignQuestionnaireToCompanyUserFormModel {
  /**
   *
   * @type {Array<SimpleLookup>}
   * @memberof GetAssignQuestionnaireToCompanyUserFormModel
   */
  publishedQuestionnaires?: Array<SimpleLookup> | null = undefined as any;
  /**
   *
   * @type {Array<GetAssignQuestionnaireToCompanyUserFormUserModel>}
   * @memberof GetAssignQuestionnaireToCompanyUserFormModel
   */
  companyUsers?: Array<GetAssignQuestionnaireToCompanyUserFormUserModel> | null = undefined as any;

  constructor(data?: Partial<GetAssignQuestionnaireToCompanyUserFormModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetAssignQuestionnaireToCompanyUserFormUserModel
 */
export class GetAssignQuestionnaireToCompanyUserFormUserModel {
  /**
   *
   * @type {number}
   * @memberof GetAssignQuestionnaireToCompanyUserFormUserModel
   */
  id: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GetAssignQuestionnaireToCompanyUserFormUserModel
   */
  name: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GetAssignQuestionnaireToCompanyUserFormUserModel
   */
  email: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GetAssignQuestionnaireToCompanyUserFormUserModel
   */
  _function: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GetAssignQuestionnaireToCompanyUserFormUserModel
   */
  jobTitle: string = undefined as any;

  constructor(data?: Partial<GetAssignQuestionnaireToCompanyUserFormUserModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetCategoriesInGroupModel
 */
export class GetCategoriesInGroupModel {
  /**
   *
   * @type {Array<QuestionCategoryModel>}
   * @memberof GetCategoriesInGroupModel
   */
  categories?: Array<QuestionCategoryModel> | null = undefined as any;

  constructor(data?: Partial<GetCategoriesInGroupModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetCompanyAnswersInQuestionnaireModel
 */
export class GetCompanyAnswersInQuestionnaireModel {
  /**
   *
   * @type {Array<QuestionnaireVersionCompanyUserQuestionAnswerModel>}
   * @memberof GetCompanyAnswersInQuestionnaireModel
   */
  answers?: Array<QuestionnaireVersionCompanyUserQuestionAnswerModel> | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GetCompanyAnswersInQuestionnaireModel
   */
  companyName: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GetCompanyAnswersInQuestionnaireModel
   */
  userEmail: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GetCompanyAnswersInQuestionnaireModel
   */
  userName: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof GetCompanyAnswersInQuestionnaireModel
   */
  questionnaireSubmissionStatusId: number = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof GetCompanyAnswersInQuestionnaireModel
   */
  hasAgreedTerms: boolean = undefined as any;

  constructor(data?: Partial<GetCompanyAnswersInQuestionnaireModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetCompanyEventsCompanyEventModel
 */
export class GetCompanyEventsCompanyEventModel {
  /**
   *
   * @type {number}
   * @memberof GetCompanyEventsCompanyEventModel
   */
  companyEventId: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof GetCompanyEventsCompanyEventModel
   */
  eventTypeId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GetCompanyEventsCompanyEventModel
   */
  eventType: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GetCompanyEventsCompanyEventModel
   */
  eventDate: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GetCompanyEventsCompanyEventModel
   */
  additionalNotes: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof GetCompanyEventsCompanyEventModel
   */
  isIncomplete: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof GetCompanyEventsCompanyEventModel
   */
  isOnRoadmap: boolean = undefined as any;
  /**
   *
   * @type {number}
   * @memberof GetCompanyEventsCompanyEventModel
   */
  eventMilestoneId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GetCompanyEventsCompanyEventModel
   */
  eventMilestone: string = undefined as any;

  constructor(data?: Partial<GetCompanyEventsCompanyEventModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetCompanyEventsLookupsModel
 */
export class GetCompanyEventsLookupsModel {
  /**
   *
   * @type {Array<SimpleLookup>}
   * @memberof GetCompanyEventsLookupsModel
   */
  eventTypes?: Array<SimpleLookup> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookup>}
   * @memberof GetCompanyEventsLookupsModel
   */
  eventMilestone?: Array<SimpleLookup> | null = undefined as any;

  constructor(data?: Partial<GetCompanyEventsLookupsModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetCompanyEventsLookupsQuery
 */
export class GetCompanyEventsLookupsQuery {
  /**
   *
   * @type {number}
   * @memberof GetCompanyEventsLookupsQuery
   */
  eventMilestoneId?: number | null = undefined as any;

  constructor(data?: Partial<GetCompanyEventsLookupsQuery>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetCompanyEventsModel
 */
export class GetCompanyEventsModel {
  /**
   *
   * @type {Array<GetCompanyEventsCompanyEventModel>}
   * @memberof GetCompanyEventsModel
   */
  companyEvents?: Array<GetCompanyEventsCompanyEventModel> | null = undefined as any;

  constructor(data?: Partial<GetCompanyEventsModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetCompanyEventsQuery
 */
export class GetCompanyEventsQuery {
  /**
   *
   * @type {number}
   * @memberof GetCompanyEventsQuery
   */
  companyId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GetCompanyEventsQuery
   */
  search: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof GetCompanyEventsQuery
   */
  eventTypeId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof GetCompanyEventsQuery
   */
  eventMilestoneId?: number | null = undefined as any;

  constructor(data?: Partial<GetCompanyEventsQuery>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetCompanyFinalReportFinalReportModel
 */
export class GetCompanyFinalReportFinalReportModel {
  /**
   *
   * @type {number}
   * @memberof GetCompanyFinalReportFinalReportModel
   */
  id: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GetCompanyFinalReportFinalReportModel
   */
  issuedAt: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GetCompanyFinalReportFinalReportModel
   */
  issuedBy: string = undefined as any;
  /**
   *
   * @type {Array<GetCompanyFinalReportFinalReportUserModel>}
   * @memberof GetCompanyFinalReportFinalReportModel
   */
  users?: Array<GetCompanyFinalReportFinalReportUserModel> | null = undefined as any;

  constructor(data?: Partial<GetCompanyFinalReportFinalReportModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetCompanyFinalReportFinalReportUserModel
 */
export class GetCompanyFinalReportFinalReportUserModel {
  /**
   *
   * @type {number}
   * @memberof GetCompanyFinalReportFinalReportUserModel
   */
  userId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GetCompanyFinalReportFinalReportUserModel
   */
  userName: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof GetCompanyFinalReportFinalReportUserModel
   */
  isKeyContact: boolean = undefined as any;

  constructor(data?: Partial<GetCompanyFinalReportFinalReportUserModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetCompanyFinalReportModel
 */
export class GetCompanyFinalReportModel {
  /**
   *
   * @type {string}
   * @memberof GetCompanyFinalReportModel
   */
  companyName: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GetCompanyFinalReportModel
   */
  questionnaireName: string = undefined as any;
  /**
   *
   * @type {Array<GetCompanyFinalReportFinalReportModel>}
   * @memberof GetCompanyFinalReportModel
   */
  finalReports?: Array<GetCompanyFinalReportFinalReportModel> | null = undefined as any;

  constructor(data?: Partial<GetCompanyFinalReportModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetCompanyGroupsInQuestionnaireModel
 */
export class GetCompanyGroupsInQuestionnaireModel {
  /**
   *
   * @type {Array<QuestionGroupModel>}
   * @memberof GetCompanyGroupsInQuestionnaireModel
   */
  groups?: Array<QuestionGroupModel> | null = undefined as any;

  constructor(data?: Partial<GetCompanyGroupsInQuestionnaireModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetCompanyQuestionnairesModel
 */
export class GetCompanyQuestionnairesModel {
  /**
   * QuestionnaireVersion CompanyUser Id
   * @type {number}
   * @memberof GetCompanyQuestionnairesModel
   */
  questionnaireVersionCompanyUserId: number = undefined as any;
  /**
   * Questionnaire version Id.
   * @type {number}
   * @memberof GetCompanyQuestionnairesModel
   */
  id: number = undefined as any;
  /**
   * Questionnaire version Title.
   * @type {string}
   * @memberof GetCompanyQuestionnairesModel
   */
  title: string = undefined as any;
  /**
   * Questionnaire version Submission status id.
   * @type {number}
   * @memberof GetCompanyQuestionnairesModel
   */
  questionnaireSubmissionStatusId: number = undefined as any;
  /**
   * Questionnaire version Submission status.
   * @type {string}
   * @memberof GetCompanyQuestionnairesModel
   */
  questionnaireSubmissionStatus: string = undefined as any;
  /**
   * Questionnaire code
   * @type {string}
   * @memberof GetCompanyQuestionnairesModel
   */
  questionnaireCode: string = undefined as any;
  /**
   * Questionnaire id
   * @type {number}
   * @memberof GetCompanyQuestionnairesModel
   */
  questionnaireId: number = undefined as any;
  /**
   * User ID
   * @type {number}
   * @memberof GetCompanyQuestionnairesModel
   */
  userId: number = undefined as any;
  /**
   * Version Number
   * @type {number}
   * @memberof GetCompanyQuestionnairesModel
   */
  versionNumber: number = undefined as any;

  constructor(data?: Partial<GetCompanyQuestionnairesModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetCompanyQuestionnairesModelGridCollection
 */
export class GetCompanyQuestionnairesModelGridCollection {
  /**
   *
   * @type {Array<GetCompanyQuestionnairesModel>}
   * @memberof GetCompanyQuestionnairesModelGridCollection
   */
  items?: Array<GetCompanyQuestionnairesModel> | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof GetCompanyQuestionnairesModelGridCollection
   */
  currentPage: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof GetCompanyQuestionnairesModelGridCollection
   */
  pageSize: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof GetCompanyQuestionnairesModelGridCollection
   */
  pageCount: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof GetCompanyQuestionnairesModelGridCollection
   */
  totalItemCount: number = undefined as any;

  constructor(data?: Partial<GetCompanyQuestionnairesModelGridCollection>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetCompanyQuestionnairesQuery
 */
export class GetCompanyQuestionnairesQuery {
  /**
   *
   * @type {number}
   * @memberof GetCompanyQuestionnairesQuery
   */
  submissionStatusId: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof GetCompanyQuestionnairesQuery
   */
  page?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof GetCompanyQuestionnairesQuery
   */
  pageSize?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GetCompanyQuestionnairesQuery
   */
  sortBy: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GetCompanyQuestionnairesQuery
   */
  sortOrder: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof GetCompanyQuestionnairesQuery
   */
  includeDeleted?: boolean | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof GetCompanyQuestionnairesQuery
   */
  userId?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GetCompanyQuestionnairesQuery
   */
  search: string = undefined as any;

  constructor(data?: Partial<GetCompanyQuestionnairesQuery>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetCompanySaveFormLookupsModel
 */
export class GetCompanySaveFormLookupsModel {
  /**
   *
   * @type {Array<SimpleLookup>}
   * @memberof GetCompanySaveFormLookupsModel
   */
  regions?: Array<SimpleLookup> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookup>}
   * @memberof GetCompanySaveFormLookupsModel
   */
  parentCompanies?: Array<SimpleLookup> | null = undefined as any;
  /**
   *
   * @type {Array<SectorLookup>}
   * @memberof GetCompanySaveFormLookupsModel
   */
  sectors?: Array<SectorLookup> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookup>}
   * @memberof GetCompanySaveFormLookupsModel
   */
  ttiUsers?: Array<SimpleLookup> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookup>}
   * @memberof GetCompanySaveFormLookupsModel
   */
  divisions?: Array<SimpleLookup> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookup>}
   * @memberof GetCompanySaveFormLookupsModel
   */
  cdcSegmentations?: Array<SimpleLookup> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookup>}
   * @memberof GetCompanySaveFormLookupsModel
   */
  territoryHqs?: Array<SimpleLookup> | null = undefined as any;

  constructor(data?: Partial<GetCompanySaveFormLookupsModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetCompanyViewAvailableQuestionnaire
 */
export class GetCompanyViewAvailableQuestionnaire {
  /**
   * Questionnaire version Id.
   * @type {number}
   * @memberof GetCompanyViewAvailableQuestionnaire
   */
  id: number = undefined as any;
  /**
   * Questionnaire version Title.
   * @type {string}
   * @memberof GetCompanyViewAvailableQuestionnaire
   */
  title: string = undefined as any;
  /**
   * Questionnaire version version.
   * @type {number}
   * @memberof GetCompanyViewAvailableQuestionnaire
   */
  version: number = undefined as any;
  /**
   * Questionnaire version amount of groups.
   * @type {number}
   * @memberof GetCompanyViewAvailableQuestionnaire
   */
  sections: number = undefined as any;
  /**
   * Questionnaire version amount of questions
   * @type {number}
   * @memberof GetCompanyViewAvailableQuestionnaire
   */
  questions: number = undefined as any;
  /**
   * Users invited to questionnaire
   * @type {Array<GetCompanyViewUserModel>}
   * @memberof GetCompanyViewAvailableQuestionnaire
   */
  users?: Array<GetCompanyViewUserModel> | null = undefined as any;
  /**
   * Total number of users
   * @type {number}
   * @memberof GetCompanyViewAvailableQuestionnaire
   */
  numberOfUsers: number = undefined as any;
  /**
   * Total number of users who have started the questionnaire
   * @type {number}
   * @memberof GetCompanyViewAvailableQuestionnaire
   */
  numberOfUsersStarted: number = undefined as any;
  /**
   * Total number of users who have Submitted the questionnaire
   * @type {number}
   * @memberof GetCompanyViewAvailableQuestionnaire
   */
  numberOfUsersSubmitted: number = undefined as any;
  /**
   * Has any final report
   * @type {boolean}
   * @memberof GetCompanyViewAvailableQuestionnaire
   */
  hasFinalReports: boolean = undefined as any;

  constructor(data?: Partial<GetCompanyViewAvailableQuestionnaire>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetCompanyViewModel
 */
export class GetCompanyViewModel {
  /**
   *
   * @type {number}
   * @memberof GetCompanyViewModel
   */
  id: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GetCompanyViewModel
   */
  name: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GetCompanyViewModel
   */
  address: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof GetCompanyViewModel
   */
  numberUsers: number = undefined as any;
  /**
   *
   * @type {Array<GetCompanyViewAvailableQuestionnaire>}
   * @memberof GetCompanyViewModel
   */
  availableQuestionnaires?: Array<GetCompanyViewAvailableQuestionnaire> | null = undefined as any;
  /**
   *
   * @type {Array<GetCompanyViewQuestionnaireUserAnswer>}
   * @memberof GetCompanyViewModel
   */
  submittedQuestionnaires?: Array<GetCompanyViewQuestionnaireUserAnswer> | null = undefined as any;

  constructor(data?: Partial<GetCompanyViewModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetCompanyViewQuestionnaireUserAnswer
 */
export class GetCompanyViewQuestionnaireUserAnswer {
  /**
   * Questionnaire version Id.
   * @type {number}
   * @memberof GetCompanyViewQuestionnaireUserAnswer
   */
  id: number = undefined as any;
  /**
   * Questionnaire Version Company User Id
   * @type {number}
   * @memberof GetCompanyViewQuestionnaireUserAnswer
   */
  questionnaireVersionCompanyUserId: number = undefined as any;
  /**
   * Questionnaire version Title.
   * @type {string}
   * @memberof GetCompanyViewQuestionnaireUserAnswer
   */
  title: string = undefined as any;
  /**
   * Questionnaire version version.
   * @type {number}
   * @memberof GetCompanyViewQuestionnaireUserAnswer
   */
  version: number = undefined as any;
  /**
   * Submitted By User
   * @type {string}
   * @memberof GetCompanyViewQuestionnaireUserAnswer
   */
  submittedBy: string = undefined as any;
  /**
   * The ID of the user who submitted the questionnaire
   * @type {number}
   * @memberof GetCompanyViewQuestionnaireUserAnswer
   */
  submittedByUserId: number = undefined as any;
  /**
   * The ID for vuetify data table
   * @type {string}
   * @memberof GetCompanyViewQuestionnaireUserAnswer
   */
  rowId: string = undefined as any;
  /**
   * Submitted At
   * @type {string}
   * @memberof GetCompanyViewQuestionnaireUserAnswer
   */
  submittedAt: string = undefined as any;

  constructor(data?: Partial<GetCompanyViewQuestionnaireUserAnswer>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetCompanyViewUserModel
 */
export class GetCompanyViewUserModel {
  /**
   *
   * @type {number}
   * @memberof GetCompanyViewUserModel
   */
  userId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GetCompanyViewUserModel
   */
  name: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GetCompanyViewUserModel
   */
  email: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof GetCompanyViewUserModel
   */
  submissionStatusId: number = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof GetCompanyViewUserModel
   */
  isDeleted: boolean = undefined as any;
  /**
   *
   * @type {number}
   * @memberof GetCompanyViewUserModel
   */
  questionnaireVersionCompanyUserId: number = undefined as any;

  constructor(data?: Partial<GetCompanyViewUserModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetEventMilestonesEventMilestoneModel
 */
export class GetEventMilestonesEventMilestoneModel {
  /**
   *
   * @type {number}
   * @memberof GetEventMilestonesEventMilestoneModel
   */
  sortOrder: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof GetEventMilestonesEventMilestoneModel
   */
  id: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GetEventMilestonesEventMilestoneModel
   */
  description: string = undefined as any;

  constructor(data?: Partial<GetEventMilestonesEventMilestoneModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetEventMilestonesModel
 */
export class GetEventMilestonesModel {
  /**
   *
   * @type {Array<GetEventMilestonesEventMilestoneModel>}
   * @memberof GetEventMilestonesModel
   */
  eventMilestones?: Array<GetEventMilestonesEventMilestoneModel> | null = undefined as any;

  constructor(data?: Partial<GetEventMilestonesModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetEventMilestonesQuery
 */
export class GetEventMilestonesQuery {
  /**
   *
   * @type {string}
   * @memberof GetEventMilestonesQuery
   */
  search: string = undefined as any;

  constructor(data?: Partial<GetEventMilestonesQuery>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetEventTypeFormLookupsModel
 */
export class GetEventTypeFormLookupsModel {
  /**
   *
   * @type {Array<SimpleLookup>}
   * @memberof GetEventTypeFormLookupsModel
   */
  eventMilestones?: Array<SimpleLookup> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookup>}
   * @memberof GetEventTypeFormLookupsModel
   */
  questionnaireTypes?: Array<SimpleLookup> | null = undefined as any;

  constructor(data?: Partial<GetEventTypeFormLookupsModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetEventTypeLookupsModel
 */
export class GetEventTypeLookupsModel {
  /**
   *
   * @type {Array<SimpleLookup>}
   * @memberof GetEventTypeLookupsModel
   */
  eventTypes?: Array<SimpleLookup> | null = undefined as any;

  constructor(data?: Partial<GetEventTypeLookupsModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetEventTypesEventTypeModel
 */
export class GetEventTypesEventTypeModel {
  /**
   *
   * @type {number}
   * @memberof GetEventTypesEventTypeModel
   */
  eventMilestoneId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GetEventTypesEventTypeModel
   */
  eventMilestone: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof GetEventTypesEventTypeModel
   */
  sortOrder: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof GetEventTypesEventTypeModel
   */
  finalReportQuestionnaireTypeId?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GetEventTypesEventTypeModel
   */
  finalReportQuestionnaireType: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof GetEventTypesEventTypeModel
   */
  id: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GetEventTypesEventTypeModel
   */
  description: string = undefined as any;

  constructor(data?: Partial<GetEventTypesEventTypeModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetEventTypesModel
 */
export class GetEventTypesModel {
  /**
   *
   * @type {Array<GetEventTypesEventTypeModel>}
   * @memberof GetEventTypesModel
   */
  eventTypes?: Array<GetEventTypesEventTypeModel> | null = undefined as any;

  constructor(data?: Partial<GetEventTypesModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetEventTypesQuery
 */
export class GetEventTypesQuery {
  /**
   *
   * @type {string}
   * @memberof GetEventTypesQuery
   */
  search: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof GetEventTypesQuery
   */
  eventMilestoneId?: number | null = undefined as any;

  constructor(data?: Partial<GetEventTypesQuery>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetExportQuestionnaireVersionDataModel
 */
export class GetExportQuestionnaireVersionDataModel {
  /**
   *
   * @type {number}
   * @memberof GetExportQuestionnaireVersionDataModel
   */
  id: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GetExportQuestionnaireVersionDataModel
   */
  title: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof GetExportQuestionnaireVersionDataModel
   */
  questionnaireTypeId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GetExportQuestionnaireVersionDataModel
   */
  questionnaireType: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GetExportQuestionnaireVersionDataModel
   */
  code: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GetExportQuestionnaireVersionDataModel
   */
  description: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GetExportQuestionnaireVersionDataModel
   */
  introduction: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof GetExportQuestionnaireVersionDataModel
   */
  versionNumber: number = undefined as any;
  /**
   *
   * @type {Array<QuestionGroupModel>}
   * @memberof GetExportQuestionnaireVersionDataModel
   */
  groups?: Array<QuestionGroupModel> | null = undefined as any;

  constructor(data?: Partial<GetExportQuestionnaireVersionDataModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetFilterCompaniesLookupsModel
 */
export class GetFilterCompaniesLookupsModel {
  /**
   * Parent Companies.
   * @type {Array<SimpleLookup>}
   * @memberof GetFilterCompaniesLookupsModel
   */
  parentCompanies?: Array<SimpleLookup> | null = undefined as any;
  /**
   * Regions.
   * @type {Array<SimpleLookup>}
   * @memberof GetFilterCompaniesLookupsModel
   */
  regions?: Array<SimpleLookup> | null = undefined as any;
  /**
   * Sectors.
   * @type {Array<SimpleLookup>}
   * @memberof GetFilterCompaniesLookupsModel
   */
  sectors?: Array<SimpleLookup> | null = undefined as any;
  /**
   * Questionnaire versions
   * @type {Array<SimpleLookup>}
   * @memberof GetFilterCompaniesLookupsModel
   */
  questionnaires?: Array<SimpleLookup> | null = undefined as any;
  /**
   * Divisions
   * @type {Array<SimpleLookup>}
   * @memberof GetFilterCompaniesLookupsModel
   */
  divisions?: Array<SimpleLookup> | null = undefined as any;

  constructor(data?: Partial<GetFilterCompaniesLookupsModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetFilterContentPlaceholderLookupsModel
 */
export class GetFilterContentPlaceholderLookupsModel {
  /**
   * Questionnaire Types
   * @type {Array<SimpleLookup>}
   * @memberof GetFilterContentPlaceholderLookupsModel
   */
  questionnaireTypes?: Array<SimpleLookup> | null = undefined as any;

  constructor(data?: Partial<GetFilterContentPlaceholderLookupsModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetFilterQuestionOptionLevelLookupsModel
 */
export class GetFilterQuestionOptionLevelLookupsModel {
  /**
   * Questionnaire Types
   * @type {Array<SimpleLookup>}
   * @memberof GetFilterQuestionOptionLevelLookupsModel
   */
  questionnaireTypes?: Array<SimpleLookup> | null = undefined as any;

  constructor(data?: Partial<GetFilterQuestionOptionLevelLookupsModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetGroupsInQuestionnaireModel
 */
export class GetGroupsInQuestionnaireModel {
  /**
   *
   * @type {Array<QuestionGroupModel>}
   * @memberof GetGroupsInQuestionnaireModel
   */
  groups?: Array<QuestionGroupModel> | null = undefined as any;

  constructor(data?: Partial<GetGroupsInQuestionnaireModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetHouseFloorsByTypeQuestionnaireTypeModel
 */
export class GetHouseFloorsByTypeQuestionnaireTypeModel {
  /**
   *
   * @type {number}
   * @memberof GetHouseFloorsByTypeQuestionnaireTypeModel
   */
  id: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GetHouseFloorsByTypeQuestionnaireTypeModel
   */
  name: string = undefined as any;
  /**
   *
   * @type {Array<GetHouseFloorsByTypeScorecardGroupElementModel>}
   * @memberof GetHouseFloorsByTypeQuestionnaireTypeModel
   */
  floors?: Array<GetHouseFloorsByTypeScorecardGroupElementModel> | null = undefined as any;

  constructor(data?: Partial<GetHouseFloorsByTypeQuestionnaireTypeModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetHouseFloorsByTypeScorecardGroupElementModel
 */
export class GetHouseFloorsByTypeScorecardGroupElementModel {
  /**
   *
   * @type {number}
   * @memberof GetHouseFloorsByTypeScorecardGroupElementModel
   */
  id: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof GetHouseFloorsByTypeScorecardGroupElementModel
   */
  questionnaireTypeId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GetHouseFloorsByTypeScorecardGroupElementModel
   */
  name: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GetHouseFloorsByTypeScorecardGroupElementModel
   */
  description: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GetHouseFloorsByTypeScorecardGroupElementModel
   */
  introduction: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof GetHouseFloorsByTypeScorecardGroupElementModel
   */
  sortOrder: number = undefined as any;
  /**
   *
   * @type {Array<GetHouseFloorsByTypeScorecardKeyElementModel>}
   * @memberof GetHouseFloorsByTypeScorecardGroupElementModel
   */
  rooms?: Array<GetHouseFloorsByTypeScorecardKeyElementModel> | null = undefined as any;

  constructor(data?: Partial<GetHouseFloorsByTypeScorecardGroupElementModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetHouseFloorsByTypeScorecardKeyElementModel
 */
export class GetHouseFloorsByTypeScorecardKeyElementModel {
  /**
   *
   * @type {number}
   * @memberof GetHouseFloorsByTypeScorecardKeyElementModel
   */
  id: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GetHouseFloorsByTypeScorecardKeyElementModel
   */
  name: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GetHouseFloorsByTypeScorecardKeyElementModel
   */
  description: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof GetHouseFloorsByTypeScorecardKeyElementModel
   */
  sortOrder: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof GetHouseFloorsByTypeScorecardKeyElementModel
   */
  scorecardGroupElementId: number = undefined as any;

  constructor(data?: Partial<GetHouseFloorsByTypeScorecardKeyElementModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetInnovationScorecardReportModel
 */
export class GetInnovationScorecardReportModel {
  /**
   *
   * @type {string}
   * @memberof GetInnovationScorecardReportModel
   */
  pdfFile: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GetInnovationScorecardReportModel
   */
  fileName: string = undefined as any;

  constructor(data?: Partial<GetInnovationScorecardReportModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetInnovationScorecardReportQuery
 */
export class GetInnovationScorecardReportQuery {
  /**
   * Questionnaires Id.
   * @type {number}
   * @memberof GetInnovationScorecardReportQuery
   */
  questionnaireId: number = undefined as any;
  /**
   * User Id.
   * @type {Array<number>}
   * @memberof GetInnovationScorecardReportQuery
   */
  userIds?: Array<number> | null = undefined as any;
  /**
   * UserId of key contact to highlight in variance report.
   * @type {number}
   * @memberof GetInnovationScorecardReportQuery
   */
  keyContactId: number = undefined as any;
  /**
   * Is this the company final report
   * @type {boolean}
   * @memberof GetInnovationScorecardReportQuery
   */
  isFinalReport: boolean = undefined as any;
  /**
   * Include roadmap in the report
   * @type {boolean}
   * @memberof GetInnovationScorecardReportQuery
   */
  includeRoadmap: boolean = undefined as any;

  constructor(data?: Partial<GetInnovationScorecardReportQuery>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetInviteUserFormLookupsModel
 */
export class GetInviteUserFormLookupsModel {
  /**
   *
   * @type {Array<SimpleLookup>}
   * @memberof GetInviteUserFormLookupsModel
   */
  userFunctions?: Array<SimpleLookup> | null = undefined as any;

  constructor(data?: Partial<GetInviteUserFormLookupsModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetQuestionSaveFormLookupsModel
 */
export class GetQuestionSaveFormLookupsModel {
  /**
   *
   * @type {SaveQuestionFormCommand}
   * @memberof GetQuestionSaveFormLookupsModel
   */
  question: SaveQuestionFormCommand = undefined as any;
  /**
   *
   * @type {Array<SimpleLookup>}
   * @memberof GetQuestionSaveFormLookupsModel
   */
  questionOptionLevels?: Array<SimpleLookup> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookup>}
   * @memberof GetQuestionSaveFormLookupsModel
   */
  questionTypes?: Array<SimpleLookup> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookup>}
   * @memberof GetQuestionSaveFormLookupsModel
   */
  scorecardKeyElements?: Array<SimpleLookup> | null = undefined as any;

  constructor(data?: Partial<GetQuestionSaveFormLookupsModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetQuestionSaveFormLookupsQuery
 */
export class GetQuestionSaveFormLookupsQuery {
  /**
   *
   * @type {number}
   * @memberof GetQuestionSaveFormLookupsQuery
   */
  questionnaireTypeId: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof GetQuestionSaveFormLookupsQuery
   */
  questionId?: number | null = undefined as any;

  constructor(data?: Partial<GetQuestionSaveFormLookupsQuery>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetQuestionnaireUserProgressGroupProgressModel
 */
export class GetQuestionnaireUserProgressGroupProgressModel {
  /**
   *
   * @type {number}
   * @memberof GetQuestionnaireUserProgressGroupProgressModel
   */
  groupId: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof GetQuestionnaireUserProgressGroupProgressModel
   */
  totalQuestions: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof GetQuestionnaireUserProgressGroupProgressModel
   */
  totalQuestionsAnswered: number = undefined as any;

  constructor(data?: Partial<GetQuestionnaireUserProgressGroupProgressModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetQuestionnaireUserProgressModel
 */
export class GetQuestionnaireUserProgressModel {
  /**
   *
   * @type {number}
   * @memberof GetQuestionnaireUserProgressModel
   */
  questionnaireVersionCompanyUserId: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof GetQuestionnaireUserProgressModel
   */
  totalQuestions: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof GetQuestionnaireUserProgressModel
   */
  totalQuestionsAnswered: number = undefined as any;
  /**
   *
   * @type {Array<GetQuestionnaireUserProgressGroupProgressModel>}
   * @memberof GetQuestionnaireUserProgressModel
   */
  groupProgress?: Array<GetQuestionnaireUserProgressGroupProgressModel> | null = undefined as any;

  constructor(data?: Partial<GetQuestionnaireUserProgressModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetQuestionsInParentModel
 */
export class GetQuestionsInParentModel {
  /**
   *
   * @type {Array<QuestionModel>}
   * @memberof GetQuestionsInParentModel
   */
  questions?: Array<QuestionModel> | null = undefined as any;

  constructor(data?: Partial<GetQuestionsInParentModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetQuestionsInParentQuery
 */
export class GetQuestionsInParentQuery {
  /**
   * Question Sub Category Id.
   * @type {number}
   * @memberof GetQuestionsInParentQuery
   */
  questionSubCategoryId?: number | null = undefined as any;
  /**
   * Question Category Id.
   * @type {number}
   * @memberof GetQuestionsInParentQuery
   */
  questionCategoryId?: number | null = undefined as any;

  constructor(data?: Partial<GetQuestionsInParentQuery>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetRoadmapImageModel
 */
export class GetRoadmapImageModel {
  /**
   *
   * @type {string}
   * @memberof GetRoadmapImageModel
   */
  imageData: string = undefined as any;

  constructor(data?: Partial<GetRoadmapImageModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetSaveQuestionnaireLookupsModel
 */
export class GetSaveQuestionnaireLookupsModel {
  /**
   *
   * @type {Array<SimpleLookup>}
   * @memberof GetSaveQuestionnaireLookupsModel
   */
  questionnaireTypes?: Array<SimpleLookup> | null = undefined as any;

  constructor(data?: Partial<GetSaveQuestionnaireLookupsModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetSubCategoriesInCategoryModel
 */
export class GetSubCategoriesInCategoryModel {
  /**
   *
   * @type {Array<QuestionSubCategoryModel>}
   * @memberof GetSubCategoriesInCategoryModel
   */
  subCategories?: Array<QuestionSubCategoryModel> | null = undefined as any;

  constructor(data?: Partial<GetSubCategoriesInCategoryModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetUserProfileModel
 */
export class GetUserProfileModel {
  /**
   * User Id.
   * @type {number}
   * @memberof GetUserProfileModel
   */
  id: number = undefined as any;
  /**
   * UserName.
   * @type {string}
   * @memberof GetUserProfileModel
   */
  userName: string = undefined as any;
  /**
   * First name.
   * @type {string}
   * @memberof GetUserProfileModel
   */
  firstName: string = undefined as any;
  /**
   * Last name.
   * @type {string}
   * @memberof GetUserProfileModel
   */
  lastName: string = undefined as any;
  /**
   * User email.
   * @type {string}
   * @memberof GetUserProfileModel
   */
  email: string = undefined as any;
  /**
   * Is user IDA admin?
   * @type {boolean}
   * @memberof GetUserProfileModel
   */
  isAdmin: boolean = undefined as any;
  /**
   * User roles on the system.
   * @type {Array<GetUserProfileModelRoleModel>}
   * @memberof GetUserProfileModel
   */
  roles?: Array<GetUserProfileModelRoleModel> | null = undefined as any;
  /**
   * Date of user creation (registration date).
   * @type {string}
   * @memberof GetUserProfileModel
   */
  createdAt: string = undefined as any;
  /**
   * Shows if the user is deleted or not
   * @type {boolean}
   * @memberof GetUserProfileModel
   */
  isDeleted: boolean = undefined as any;
  /**
   * Has user accepted invitation (only for company users)
   * @type {boolean}
   * @memberof GetUserProfileModel
   */
  hasAccepted: boolean = undefined as any;
  /**
   * Date user has accepted invitation
   * @type {string}
   * @memberof GetUserProfileModel
   */
  acceptedAt: string = undefined as any;
  /**
   *
   * @type {GetUserProfileModelCompanyModel}
   * @memberof GetUserProfileModel
   */
  company: GetUserProfileModelCompanyModel = undefined as any;
  /**
   *
   * @type {GetUserProfileModelCompanyUserModel}
   * @memberof GetUserProfileModel
   */
  companyUser: GetUserProfileModelCompanyUserModel = undefined as any;

  constructor(data?: Partial<GetUserProfileModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetUserProfileModelCompanyModel
 */
export class GetUserProfileModelCompanyModel {
  /**
   * Company id.
   * @type {number}
   * @memberof GetUserProfileModelCompanyModel
   */
  companyId: number = undefined as any;
  /**
   * Company name.
   * @type {string}
   * @memberof GetUserProfileModelCompanyModel
   */
  companyName: string = undefined as any;
  /**
   * Can see roadmap.
   * @type {boolean}
   * @memberof GetUserProfileModelCompanyModel
   */
  canSeeRoadmap: boolean = undefined as any;

  constructor(data?: Partial<GetUserProfileModelCompanyModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetUserProfileModelCompanyUserModel
 */
export class GetUserProfileModelCompanyUserModel {
  /**
   *
   * @type {string}
   * @memberof GetUserProfileModelCompanyUserModel
   */
  jobTitle: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof GetUserProfileModelCompanyUserModel
   */
  userFunctionId?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GetUserProfileModelCompanyUserModel
   */
  userFunctionName: string = undefined as any;

  constructor(data?: Partial<GetUserProfileModelCompanyUserModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetUserProfileModelRoleModel
 */
export class GetUserProfileModelRoleModel {
  /**
   * Role Id
   * @type {number}
   * @memberof GetUserProfileModelRoleModel
   */
  roleId: number = undefined as any;
  /**
   * Role Display name
   * @type {string}
   * @memberof GetUserProfileModelRoleModel
   */
  displayName: string = undefined as any;

  constructor(data?: Partial<GetUserProfileModelRoleModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetUsersInCompanyDialogCompanyUserModel
 */
export class GetUsersInCompanyDialogCompanyUserModel {
  /**
   *
   * @type {number}
   * @memberof GetUsersInCompanyDialogCompanyUserModel
   */
  userId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GetUsersInCompanyDialogCompanyUserModel
   */
  name: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GetUsersInCompanyDialogCompanyUserModel
   */
  email: string = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof GetUsersInCompanyDialogCompanyUserModel
   */
  hasAccepted: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof GetUsersInCompanyDialogCompanyUserModel
   */
  isDeleted: boolean = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GetUsersInCompanyDialogCompanyUserModel
   */
  invitedAt: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GetUsersInCompanyDialogCompanyUserModel
   */
  acceptedAt: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GetUsersInCompanyDialogCompanyUserModel
   */
  jobTitle: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof GetUsersInCompanyDialogCompanyUserModel
   */
  userFunctionId?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GetUsersInCompanyDialogCompanyUserModel
   */
  userFunction: string = undefined as any;

  constructor(data?: Partial<GetUsersInCompanyDialogCompanyUserModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetUsersInCompanyDialogModel
 */
export class GetUsersInCompanyDialogModel {
  /**
   *
   * @type {number}
   * @memberof GetUsersInCompanyDialogModel
   */
  companyId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GetUsersInCompanyDialogModel
   */
  companyName: string = undefined as any;
  /**
   *
   * @type {Array<GetUsersInCompanyDialogCompanyUserModel>}
   * @memberof GetUsersInCompanyDialogModel
   */
  users?: Array<GetUsersInCompanyDialogCompanyUserModel> | null = undefined as any;

  constructor(data?: Partial<GetUsersInCompanyDialogModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface GetUsersInCompanyDialogQuery
 */
export class GetUsersInCompanyDialogQuery {
  /**
   *
   * @type {number}
   * @memberof GetUsersInCompanyDialogQuery
   */
  companyId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof GetUsersInCompanyDialogQuery
   */
  search: string = undefined as any;

  constructor(data?: Partial<GetUsersInCompanyDialogQuery>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface InviteAdminUserFormCommand
 */
export class InviteAdminUserFormCommand {
  /**
   * User first name.
   * @type {string}
   * @memberof InviteAdminUserFormCommand
   */
  firstname: string = undefined as any;
  /**
   * User last name.
   * @type {string}
   * @memberof InviteAdminUserFormCommand
   */
  lastname: string = undefined as any;
  /**
   * User email address.
   * @type {string}
   * @memberof InviteAdminUserFormCommand
   */
  email: string = undefined as any;

  constructor(data?: Partial<InviteAdminUserFormCommand>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface InviteUserFormCommand
 */
export class InviteUserFormCommand {
  /**
   * Company Id.
   * @type {number}
   * @memberof InviteUserFormCommand
   */
  companyId: number = undefined as any;
  /**
   * User first name.
   * @type {string}
   * @memberof InviteUserFormCommand
   */
  firstname: string = undefined as any;
  /**
   * User last name.
   * @type {string}
   * @memberof InviteUserFormCommand
   */
  lastname: string = undefined as any;
  /**
   * User email address.
   * @type {string}
   * @memberof InviteUserFormCommand
   */
  email: string = undefined as any;
  /**
   * Job title
   * @type {string}
   * @memberof InviteUserFormCommand
   */
  jobTitle: string = undefined as any;
  /**
   * User Function Id.
   * @type {number}
   * @memberof InviteUserFormCommand
   */
  userFunctionId?: number | null = undefined as any;

  constructor(data?: Partial<InviteUserFormCommand>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface ModelState
 */
export class ModelState {
  /**
   *
   * @type {boolean}
   * @memberof ModelState
   */
  isValid: boolean = undefined as any;
  /**
   *
   * @type {Array<string>}
   * @memberof ModelState
   */
  validationSummary?: Array<string> | null = undefined as any;
  /**
   *
   * @type {{ [key: string]: Array<string>; }}
   * @memberof ModelState
   */
  errors?: { [key: string]: Array<string> } | null = undefined as any;

  constructor(data?: Partial<ModelState>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface QuestionCategoryModel
 */
export class QuestionCategoryModel {
  /**
   *
   * @type {Array<QuestionModel>}
   * @memberof QuestionCategoryModel
   */
  questions?: Array<QuestionModel> | null = undefined as any;
  /**
   *
   * @type {Array<QuestionSubCategoryModel>}
   * @memberof QuestionCategoryModel
   */
  subCategories?: Array<QuestionSubCategoryModel> | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof QuestionCategoryModel
   */
  order: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof QuestionCategoryModel
   */
  id: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof QuestionCategoryModel
   */
  title: string = undefined as any;

  constructor(data?: Partial<QuestionCategoryModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface QuestionGroupModel
 */
export class QuestionGroupModel {
  /**
   *
   * @type {Array<QuestionCategoryModel>}
   * @memberof QuestionGroupModel
   */
  categories?: Array<QuestionCategoryModel> | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof QuestionGroupModel
   */
  numberOfQuestions: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof QuestionGroupModel
   */
  description: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof QuestionGroupModel
   */
  order: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof QuestionGroupModel
   */
  id: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof QuestionGroupModel
   */
  title: string = undefined as any;

  constructor(data?: Partial<QuestionGroupModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface QuestionModel
 */
export class QuestionModel {
  /**
   *
   * @type {string}
   * @memberof QuestionModel
   */
  question: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof QuestionModel
   */
  description: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof QuestionModel
   */
  number: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof QuestionModel
   */
  questionTypeId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof QuestionModel
   */
  questionType: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof QuestionModel
   */
  scorecardElementKeyId?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof QuestionModel
   */
  scorecardElementKey: string = undefined as any;
  /**
   *
   * @type {Array<QuestionOptionModel>}
   * @memberof QuestionModel
   */
  options?: Array<QuestionOptionModel> | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof QuestionModel
   */
  order: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof QuestionModel
   */
  id: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof QuestionModel
   */
  title: string = undefined as any;

  constructor(data?: Partial<QuestionModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface QuestionOptionModel
 */
export class QuestionOptionModel {
  /**
   *
   * @type {string}
   * @memberof QuestionOptionModel
   */
  recommendations: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof QuestionOptionModel
   */
  description: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof QuestionOptionModel
   */
  questionOptionLevelId?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof QuestionOptionModel
   */
  questionOptionLevel: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof QuestionOptionModel
   */
  order: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof QuestionOptionModel
   */
  id: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof QuestionOptionModel
   */
  title: string = undefined as any;

  constructor(data?: Partial<QuestionOptionModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface QuestionSubCategoryModel
 */
export class QuestionSubCategoryModel {
  /**
   *
   * @type {Array<QuestionModel>}
   * @memberof QuestionSubCategoryModel
   */
  questions?: Array<QuestionModel> | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof QuestionSubCategoryModel
   */
  order: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof QuestionSubCategoryModel
   */
  id: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof QuestionSubCategoryModel
   */
  title: string = undefined as any;

  constructor(data?: Partial<QuestionSubCategoryModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface QuestionnaireVersionCompanyUserQuestionAnswerModel
 */
export class QuestionnaireVersionCompanyUserQuestionAnswerModel {
  /**
   *
   * @type {number}
   * @memberof QuestionnaireVersionCompanyUserQuestionAnswerModel
   */
  id: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof QuestionnaireVersionCompanyUserQuestionAnswerModel
   */
  questionnaireVersionCompanyUserAnswerId: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof QuestionnaireVersionCompanyUserQuestionAnswerModel
   */
  questionId: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof QuestionnaireVersionCompanyUserQuestionAnswerModel
   */
  questionOptionId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof QuestionnaireVersionCompanyUserQuestionAnswerModel
   */
  questionOptionTargetId?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof QuestionnaireVersionCompanyUserQuestionAnswerModel
   */
  freeTextAnswer: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof QuestionnaireVersionCompanyUserQuestionAnswerModel
   */
  gridOptionId?: number | null = undefined as any;

  constructor(data?: Partial<QuestionnaireVersionCompanyUserQuestionAnswerModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface QuestionnaireVersionModel
 */
export class QuestionnaireVersionModel {
  /**
   *
   * @type {number}
   * @memberof QuestionnaireVersionModel
   */
  questionnaireId: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof QuestionnaireVersionModel
   */
  questionnaireTypeId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof QuestionnaireVersionModel
   */
  questionnaireType: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof QuestionnaireVersionModel
   */
  code: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof QuestionnaireVersionModel
   */
  description: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof QuestionnaireVersionModel
   */
  introduction: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof QuestionnaireVersionModel
   */
  houseChartImage: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof QuestionnaireVersionModel
   */
  versionNumber: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof QuestionnaireVersionModel
   */
  questionnairePublishStatusId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof QuestionnaireVersionModel
   */
  questionnairePublishStatus: string = undefined as any;
  /**
   *
   * @type {QuestionGroupModel}
   * @memberof QuestionnaireVersionModel
   */
  group: QuestionGroupModel = undefined as any;
  /**
   *
   * @type {number}
   * @memberof QuestionnaireVersionModel
   */
  id: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof QuestionnaireVersionModel
   */
  title: string = undefined as any;

  constructor(data?: Partial<QuestionnaireVersionModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface SaveCompanyEventCommand
 */
export class SaveCompanyEventCommand {
  /**
   *
   * @type {number}
   * @memberof SaveCompanyEventCommand
   */
  companyEventId?: number | null = undefined as any;
  /**
   * Company Id.
   * @type {number}
   * @memberof SaveCompanyEventCommand
   */
  companyId: number = undefined as any;
  /**
   * Event type id
   * @type {number}
   * @memberof SaveCompanyEventCommand
   */
  eventMilestoneId: number = undefined as any;
  /**
   * Event type id
   * @type {number}
   * @memberof SaveCompanyEventCommand
   */
  eventTypeId: number = undefined as any;
  /**
   * Event Date
   * @type {string}
   * @memberof SaveCompanyEventCommand
   */
  eventDate: string = undefined as any;
  /**
   * Additional Notes
   * @type {string}
   * @memberof SaveCompanyEventCommand
   */
  additionalNotes: string = undefined as any;
  /**
   * Is incomplete
   * @type {boolean}
   * @memberof SaveCompanyEventCommand
   */
  isIncomplete: boolean = undefined as any;
  /**
   * Is on roadmap
   * @type {boolean}
   * @memberof SaveCompanyEventCommand
   */
  isOnRoadmap: boolean = undefined as any;

  constructor(data?: Partial<SaveCompanyEventCommand>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface SaveCompanyFormCommand
 */
export class SaveCompanyFormCommand {
  /**
   * Company Id.
   * @type {number}
   * @memberof SaveCompanyFormCommand
   */
  companyId?: number | null = undefined as any;
  /**
   * Company name.
   * @type {string}
   * @memberof SaveCompanyFormCommand
   */
  companyName: string = undefined as any;
  /**
   * Company eircode.
   * @type {string}
   * @memberof SaveCompanyFormCommand
   */
  eircode: string = undefined as any;
  /**
   * Company address.
   * @type {string}
   * @memberof SaveCompanyFormCommand
   */
  address: string = undefined as any;
  /**
   * Company client/CIS id.
   * @type {string}
   * @memberof SaveCompanyFormCommand
   */
  clientId: string = undefined as any;
  /**
   * Company region.
   * @type {number}
   * @memberof SaveCompanyFormCommand
   */
  regionId: number = undefined as any;
  /**
   * Tti Responsible user id
   * @type {number}
   * @memberof SaveCompanyFormCommand
   */
  ttiResponsibleUserId: number = undefined as any;
  /**
   * Division Id
   * @type {number}
   * @memberof SaveCompanyFormCommand
   */
  divisionId: number = undefined as any;
  /**
   * Project Executive
   * @type {string}
   * @memberof SaveCompanyFormCommand
   */
  projectExecutive: string = undefined as any;
  /**
   * Parent company id.
   * @type {number}
   * @memberof SaveCompanyFormCommand
   */
  parentCompanyId?: number | null = undefined as any;
  /**
   * CDC Segmentation id
   * @type {number}
   * @memberof SaveCompanyFormCommand
   */
  cdcSegmentationId?: number | null = undefined as any;
  /**
   * Territory Hq Id
   * @type {number}
   * @memberof SaveCompanyFormCommand
   */
  territoryHqId?: number | null = undefined as any;
  /**
   * New parent company.
   * @type {string}
   * @memberof SaveCompanyFormCommand
   */
  newParentCompany: string = undefined as any;
  /**
   * Sectors Lvl 1
   * @type {Array<number>}
   * @memberof SaveCompanyFormCommand
   */
  sectorsLvl1?: Array<number> | null = undefined as any;
  /**
   * Sectors Lvl 2
   * @type {Array<number>}
   * @memberof SaveCompanyFormCommand
   */
  sectorsLvl2?: Array<number> | null = undefined as any;
  /**
   * Sectors Lvl 3
   * @type {Array<number>}
   * @memberof SaveCompanyFormCommand
   */
  sectorsLvl3?: Array<number> | null = undefined as any;
  /**
   * Sectors Lvl 4
   * @type {Array<number>}
   * @memberof SaveCompanyFormCommand
   */
  sectorsLvl4?: Array<number> | null = undefined as any;
  /**
   * Can See Roadmap
   * @type {boolean}
   * @memberof SaveCompanyFormCommand
   */
  canSeeRoadmap: boolean = undefined as any;

  constructor(data?: Partial<SaveCompanyFormCommand>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface SaveContentPlaceholderFormCommand
 */
export class SaveContentPlaceholderFormCommand {
  /**
   * Content Management Id.
   * @type {number}
   * @memberof SaveContentPlaceholderFormCommand
   */
  id: number = undefined as any;
  /**
   * Content key
   * @type {string}
   * @memberof SaveContentPlaceholderFormCommand
   */
  key: string = undefined as any;
  /**
   * Content value
   * @type {string}
   * @memberof SaveContentPlaceholderFormCommand
   */
  value: string = undefined as any;
  /**
   * Questionnaire Type
   * @type {string}
   * @memberof SaveContentPlaceholderFormCommand
   */
  questionnaireType: string = undefined as any;
  /**
   * is image
   * @type {boolean}
   * @memberof SaveContentPlaceholderFormCommand
   */
  isImage: boolean = undefined as any;

  constructor(data?: Partial<SaveContentPlaceholderFormCommand>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface SaveEventMilestoneCommand
 */
export class SaveEventMilestoneCommand {
  /**
   *
   * @type {number}
   * @memberof SaveEventMilestoneCommand
   */
  eventMilestoneId?: number | null = undefined as any;
  /**
   * Description
   * @type {string}
   * @memberof SaveEventMilestoneCommand
   */
  description: string = undefined as any;
  /**
   * Sort Order
   * @type {number}
   * @memberof SaveEventMilestoneCommand
   */
  sortOrder: number = undefined as any;

  constructor(data?: Partial<SaveEventMilestoneCommand>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface SaveEventTypeCommand
 */
export class SaveEventTypeCommand {
  /**
   *
   * @type {number}
   * @memberof SaveEventTypeCommand
   */
  eventTypeId?: number | null = undefined as any;
  /**
   * Event Milestone id
   * @type {number}
   * @memberof SaveEventTypeCommand
   */
  eventMilestoneId: number = undefined as any;
  /**
   * Description
   * @type {string}
   * @memberof SaveEventTypeCommand
   */
  description: string = undefined as any;
  /**
   * Sort Order
   * @type {number}
   * @memberof SaveEventTypeCommand
   */
  sortOrder: number = undefined as any;
  /**
   * Fina lReport Questionnaire Type Id
   * @type {number}
   * @memberof SaveEventTypeCommand
   */
  finalReportQuestionnaireTypeId?: number | null = undefined as any;

  constructor(data?: Partial<SaveEventTypeCommand>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface SaveFloorFormCommand
 */
export class SaveFloorFormCommand {
  /**
   *
   * @type {number}
   * @memberof SaveFloorFormCommand
   */
  id?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof SaveFloorFormCommand
   */
  questionnaireTypeId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof SaveFloorFormCommand
   */
  name: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof SaveFloorFormCommand
   */
  description: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof SaveFloorFormCommand
   */
  introduction: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof SaveFloorFormCommand
   */
  sortOrder: number = undefined as any;

  constructor(data?: Partial<SaveFloorFormCommand>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface SaveQuestionAnswersAnswerCommand
 */
export class SaveQuestionAnswersAnswerCommand {
  /**
   *
   * @type {number}
   * @memberof SaveQuestionAnswersAnswerCommand
   */
  id?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof SaveQuestionAnswersAnswerCommand
   */
  questionnaireVersionCompanyUserId: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof SaveQuestionAnswersAnswerCommand
   */
  questionId: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof SaveQuestionAnswersAnswerCommand
   */
  questionOptionId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof SaveQuestionAnswersAnswerCommand
   */
  questionOptionTargetId?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof SaveQuestionAnswersAnswerCommand
   */
  freeTextAnswer: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof SaveQuestionAnswersAnswerCommand
   */
  gridOptionId?: number | null = undefined as any;
  /**
   *
   * @type {Array<number>}
   * @memberof SaveQuestionAnswersAnswerCommand
   */
  multipleChoiceOptionIds?: Array<number> | null = undefined as any;

  constructor(data?: Partial<SaveQuestionAnswersAnswerCommand>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface SaveQuestionAnswersCommand
 */
export class SaveQuestionAnswersCommand {
  /**
   *
   * @type {Array<SaveQuestionAnswersAnswerCommand>}
   * @memberof SaveQuestionAnswersCommand
   */
  userAnswers?: Array<SaveQuestionAnswersAnswerCommand> | null = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof SaveQuestionAnswersCommand
   */
  isQuestionnaireSubmission: boolean = undefined as any;
  /**
   *
   * @type {number}
   * @memberof SaveQuestionAnswersCommand
   */
  questionnaireVersCompanyUserId: number = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof SaveQuestionAnswersCommand
   */
  hasAgreedTerms: boolean = undefined as any;
  /**
   *
   * @type {number}
   * @memberof SaveQuestionAnswersCommand
   */
  questionGroupId: number = undefined as any;

  constructor(data?: Partial<SaveQuestionAnswersCommand>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface SaveQuestionCategoryFormCommand
 */
export class SaveQuestionCategoryFormCommand {
  /**
   * Question group id
   * @type {number}
   * @memberof SaveQuestionCategoryFormCommand
   */
  id?: number | null = undefined as any;
  /**
   * Questionnaire id
   * @type {number}
   * @memberof SaveQuestionCategoryFormCommand
   */
  questionGroupId: number = undefined as any;
  /**
   * Group title
   * @type {string}
   * @memberof SaveQuestionCategoryFormCommand
   */
  title: string = undefined as any;
  /**
   * Group order
   * @type {number}
   * @memberof SaveQuestionCategoryFormCommand
   */
  order?: number | null = undefined as any;

  constructor(data?: Partial<SaveQuestionCategoryFormCommand>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface SaveQuestionFormCommand
 */
export class SaveQuestionFormCommand {
  /**
   * Question id
   * @type {number}
   * @memberof SaveQuestionFormCommand
   */
  id?: number | null = undefined as any;
  /**
   * Question category id
   * @type {number}
   * @memberof SaveQuestionFormCommand
   */
  questionCategoryId?: number | null = undefined as any;
  /**
   * Question category id
   * @type {number}
   * @memberof SaveQuestionFormCommand
   */
  questionSubCategoryId?: number | null = undefined as any;
  /**
   * Scorecard element key id
   * @type {number}
   * @memberof SaveQuestionFormCommand
   */
  scorecardKeyElementId?: number | null = undefined as any;
  /**
   * Group order
   * @type {number}
   * @memberof SaveQuestionFormCommand
   */
  order?: number | null = undefined as any;
  /**
   * Question
   * @type {string}
   * @memberof SaveQuestionFormCommand
   */
  question: string = undefined as any;
  /**
   * Question long Description
   * @type {string}
   * @memberof SaveQuestionFormCommand
   */
  description: string = undefined as any;
  /**
   * Question type id
   * @type {number}
   * @memberof SaveQuestionFormCommand
   */
  questionTypeId: number = undefined as any;
  /**
   * Question options list
   * @type {Array<SaveQuestionFormOptionCommand>}
   * @memberof SaveQuestionFormCommand
   */
  questionOptions?: Array<SaveQuestionFormOptionCommand> | null = undefined as any;

  constructor(data?: Partial<SaveQuestionFormCommand>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface SaveQuestionFormOptionCommand
 */
export class SaveQuestionFormOptionCommand {
  /**
   * Question Option id
   * @type {number}
   * @memberof SaveQuestionFormOptionCommand
   */
  id: number = undefined as any;
  /**
   * Question Option Level id
   * @type {number}
   * @memberof SaveQuestionFormOptionCommand
   */
  questionOptionLevelId?: number | null = undefined as any;
  /**
   * Question Recommendations
   * @type {string}
   * @memberof SaveQuestionFormOptionCommand
   */
  recommendations: string = undefined as any;
  /**
   * Question option Description
   * @type {string}
   * @memberof SaveQuestionFormOptionCommand
   */
  description: string = undefined as any;
  /**
   * Group order
   * @type {number}
   * @memberof SaveQuestionFormOptionCommand
   */
  order: number = undefined as any;

  constructor(data?: Partial<SaveQuestionFormOptionCommand>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface SaveQuestionGroupFormCommand
 */
export class SaveQuestionGroupFormCommand {
  /**
   * Question group id
   * @type {number}
   * @memberof SaveQuestionGroupFormCommand
   */
  id?: number | null = undefined as any;
  /**
   * Questionnaire id
   * @type {number}
   * @memberof SaveQuestionGroupFormCommand
   */
  questionnaireVersionId: number = undefined as any;
  /**
   * Group title
   * @type {string}
   * @memberof SaveQuestionGroupFormCommand
   */
  title: string = undefined as any;
  /**
   * Group Description
   * @type {string}
   * @memberof SaveQuestionGroupFormCommand
   */
  description: string = undefined as any;
  /**
   * Question group order
   * @type {number}
   * @memberof SaveQuestionGroupFormCommand
   */
  order?: number | null = undefined as any;

  constructor(data?: Partial<SaveQuestionGroupFormCommand>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface SaveQuestionOptionLevelDescriptionFormCommand
 */
export class SaveQuestionOptionLevelDescriptionFormCommand {
  /**
   * Content Management Id.
   * @type {number}
   * @memberof SaveQuestionOptionLevelDescriptionFormCommand
   */
  id: number = undefined as any;
  /**
   * Name
   * @type {string}
   * @memberof SaveQuestionOptionLevelDescriptionFormCommand
   */
  levelName: string = undefined as any;
  /**
   * Description
   * @type {string}
   * @memberof SaveQuestionOptionLevelDescriptionFormCommand
   */
  levelDescriptionName: string = undefined as any;
  /**
   * Long Description
   * @type {string}
   * @memberof SaveQuestionOptionLevelDescriptionFormCommand
   */
  longDescription: string = undefined as any;

  constructor(data?: Partial<SaveQuestionOptionLevelDescriptionFormCommand>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface SaveQuestionSubCategoryFormCommand
 */
export class SaveQuestionSubCategoryFormCommand {
  /**
   * Question group id
   * @type {number}
   * @memberof SaveQuestionSubCategoryFormCommand
   */
  id?: number | null = undefined as any;
  /**
   * Questionnaire id
   * @type {number}
   * @memberof SaveQuestionSubCategoryFormCommand
   */
  questionCategoryId: number = undefined as any;
  /**
   * Group title
   * @type {string}
   * @memberof SaveQuestionSubCategoryFormCommand
   */
  title: string = undefined as any;
  /**
   * Group order
   * @type {number}
   * @memberof SaveQuestionSubCategoryFormCommand
   */
  order?: number | null = undefined as any;

  constructor(data?: Partial<SaveQuestionSubCategoryFormCommand>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface SaveQuestionnaireFormCommand
 */
export class SaveQuestionnaireFormCommand {
  /**
   * Questionnaire id
   * @type {number}
   * @memberof SaveQuestionnaireFormCommand
   */
  id?: number | null = undefined as any;
  /**
   * Questionnaire title
   * @type {string}
   * @memberof SaveQuestionnaireFormCommand
   */
  title: string = undefined as any;
  /**
   * Questionnaire Type
   * @type {number}
   * @memberof SaveQuestionnaireFormCommand
   */
  questionnaireTypeId: number = undefined as any;
  /**
   * Questionnaire Description
   * @type {string}
   * @memberof SaveQuestionnaireFormCommand
   */
  description: string = undefined as any;
  /**
   * Questionnaire Code
   * @type {string}
   * @memberof SaveQuestionnaireFormCommand
   */
  code: string = undefined as any;
  /**
   * Introduction
   * @type {string}
   * @memberof SaveQuestionnaireFormCommand
   */
  introduction: string = undefined as any;

  constructor(data?: Partial<SaveQuestionnaireFormCommand>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface SaveRoomFormCommand
 */
export class SaveRoomFormCommand {
  /**
   *
   * @type {number}
   * @memberof SaveRoomFormCommand
   */
  id?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof SaveRoomFormCommand
   */
  scorecardGroupElementId: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof SaveRoomFormCommand
   */
  name: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof SaveRoomFormCommand
   */
  description: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof SaveRoomFormCommand
   */
  sortOrder: number = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof SaveRoomFormCommand
   */
  isHousePart: boolean = undefined as any;

  constructor(data?: Partial<SaveRoomFormCommand>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface SaveSupportResourceFormCommand
 */
export class SaveSupportResourceFormCommand {
  /**
   * Support Resource Id
   * @type {number}
   * @memberof SaveSupportResourceFormCommand
   */
  id?: number | null = undefined as any;
  /**
   * Title
   * @type {string}
   * @memberof SaveSupportResourceFormCommand
   */
  title: string = undefined as any;
  /**
   * Description
   * @type {string}
   * @memberof SaveSupportResourceFormCommand
   */
  description: string = undefined as any;
  /**
   * URL
   * @type {string}
   * @memberof SaveSupportResourceFormCommand
   */
  url: string = undefined as any;

  constructor(data?: Partial<SaveSupportResourceFormCommand>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface SaveSupportResourceQuestionCategoryCommand
 */
export class SaveSupportResourceQuestionCategoryCommand {
  /**
   *
   * @type {number}
   * @memberof SaveSupportResourceQuestionCategoryCommand
   */
  supportResourceId: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof SaveSupportResourceQuestionCategoryCommand
   */
  questionCategoryId: number = undefined as any;

  constructor(data?: Partial<SaveSupportResourceQuestionCategoryCommand>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface SectorLookup
 */
export class SectorLookup {
  /**
   * Sector Lookup Id (SectorLevel*LookupEntity.Id, and NOT ServiceProvider.Id, and not ServiceProviderSectorLvl*Lookup.id!!!).
   * @type {number}
   * @memberof SectorLookup
   */
  id: number = undefined as any;
  /**
   * Parent Sector Lookup Ids in sequential order (SectorLevel*LookupEntity.Id, and NOT ServiceProvider.Id, and not ServiceProviderSectorLvl*Lookup.id!!!).  For example a Lvl4 lookup with parentIds = [3, 4, 7] would mean parents are Lvl1-Id3, Lvl2-Id4, Lvl3-Id7.  This is for easier reverse traversing of the tree structure.
   * @type {Array<number>}
   * @memberof SectorLookup
   */
  parentIds?: Array<number> | null = undefined as any;
  /**
   * Parent Sector Lookup Id (SectorLevel*LookupEntity.Id, and NOT ServiceProvider.Id, and not ServiceProviderSectorLvl*Lookup.id!!!).
   * @type {number}
   * @memberof SectorLookup
   */
  immediateParentId?: number | null = undefined as any;
  /**
   * Sector level (1 - 4).
   * @type {number}
   * @memberof SectorLookup
   */
  level: number = undefined as any;
  /**
   * Uniq Id among all sectors and sub-sectors. This is needed for Vuetify treeview - it doesn\'t allow  nested items to have the same Id as parent. For example if sectorLvl1 object has a PK with an Id=1 and  sectorLvl object has an Id=1, this will cause issues in Vuetify.
   * @type {string}
   * @memberof SectorLookup
   */
  uniqId: string = undefined as any;
  /**
   * Sector name.
   * @type {string}
   * @memberof SectorLookup
   */
  name: string = undefined as any;
  /**
   * Sub-sectors.
   * @type {Array<SectorLookup>}
   * @memberof SectorLookup
   */
  subSectors?: Array<SectorLookup> | null = undefined as any;

  constructor(data?: Partial<SectorLookup>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface SimpleLookup
 */
export class SimpleLookup {
  /**
   *
   * @type {number}
   * @memberof SimpleLookup
   */
  id: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof SimpleLookup
   */
  description: string = undefined as any;

  constructor(data?: Partial<SimpleLookup>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface UpdateQuestionFormCommand
 */
export class UpdateQuestionFormCommand {
  /**
   * Question id
   * @type {number}
   * @memberof UpdateQuestionFormCommand
   */
  id?: number | null = undefined as any;
  /**
   * Question category id
   * @type {number}
   * @memberof UpdateQuestionFormCommand
   */
  questionCategoryId?: number | null = undefined as any;
  /**
   * Question category id
   * @type {number}
   * @memberof UpdateQuestionFormCommand
   */
  questionSubCategoryId?: number | null = undefined as any;
  /**
   * Scorecard element key id
   * @type {number}
   * @memberof UpdateQuestionFormCommand
   */
  scorecardKeyElementId?: number | null = undefined as any;
  /**
   * Group order
   * @type {number}
   * @memberof UpdateQuestionFormCommand
   */
  order?: number | null = undefined as any;
  /**
   * Question
   * @type {string}
   * @memberof UpdateQuestionFormCommand
   */
  question: string = undefined as any;
  /**
   * Question long Description
   * @type {string}
   * @memberof UpdateQuestionFormCommand
   */
  description: string = undefined as any;
  /**
   * Question type id
   * @type {number}
   * @memberof UpdateQuestionFormCommand
   */
  questionTypeId: number = undefined as any;
  /**
   * Question options list
   * @type {Array<SaveQuestionFormOptionCommand>}
   * @memberof UpdateQuestionFormCommand
   */
  questionOptions?: Array<SaveQuestionFormOptionCommand> | null = undefined as any;

  constructor(data?: Partial<UpdateQuestionFormCommand>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface UpdateUserProfileCommand
 */
export class UpdateUserProfileCommand {
  /**
   * User Id to update.
   * @type {number}
   * @memberof UpdateUserProfileCommand
   */
  userId: number = undefined as any;
  /**
   * POC first name.
   * @type {string}
   * @memberof UpdateUserProfileCommand
   */
  firstName: string = undefined as any;
  /**
   * POC last name.
   * @type {string}
   * @memberof UpdateUserProfileCommand
   */
  lastName: string = undefined as any;
  /**
   * POC email.
   * @type {string}
   * @memberof UpdateUserProfileCommand
   */
  email: string = undefined as any;
  /**
   * Is full site admin?
   * @type {boolean}
   * @memberof UpdateUserProfileCommand
   */
  isAdmin: boolean = undefined as any;
  /**
   * Has user accepted invitation (only for company users)
   * @type {boolean}
   * @memberof UpdateUserProfileCommand
   */
  hasAccepted: boolean = undefined as any;
  /**
   * Has user been deleted
   * @type {boolean}
   * @memberof UpdateUserProfileCommand
   */
  isDeleted: boolean = undefined as any;
  /**
   * Job title
   * @type {string}
   * @memberof UpdateUserProfileCommand
   */
  jobTitle: string = undefined as any;
  /**
   * Optional user function dropdown
   * @type {number}
   * @memberof UpdateUserProfileCommand
   */
  userFunctionId?: number | null = undefined as any;
  /**
   * User Company id
   * @type {number}
   * @memberof UpdateUserProfileCommand
   */
  companyId?: number | null = undefined as any;

  constructor(data?: Partial<UpdateUserProfileCommand>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface UserRoleModel
 */
export class UserRoleModel {
  /**
   * Is user a full portal admin?
   * @type {boolean}
   * @memberof UserRoleModel
   */
  isPortalAdmin: boolean = undefined as any;
  /**
   * Company id of the company the user belongs to.
   * @type {number}
   * @memberof UserRoleModel
   */
  belongsToCompanyId?: number | null = undefined as any;
  /**
   * A single role to display about this user in the UI.
   * @type {string}
   * @memberof UserRoleModel
   */
  displayName: string = undefined as any;

  constructor(data?: Partial<UserRoleModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 * CompaniesApi - axios parameter creator
 * @export
 */
export const CompaniesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Assign Questionnaire To Company
     * @param {AssignQuestionnaireToCompanyUserFormCommand} [assignQuestionnaireToCompanyUserFormCommand] Command to perform task.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    assignQuestionnaireToCompanyUserForm: async (
      assignQuestionnaireToCompanyUserFormCommand?: AssignQuestionnaireToCompanyUserFormCommand,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/companies/assign/questionnaire`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        assignQuestionnaireToCompanyUserFormCommand,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete Company Event
     * @param {number} companyEventId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCompanyEvent: async (companyEventId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'companyEventId' is not null or undefined
      assertParamExists('deleteCompanyEvent', 'companyEventId', companyEventId);
      const localVarPath = `/api/v1/companies/event/delete/{companyEventId}`.replace(
        `{${'companyEventId'}}`,
        encodeURIComponent(String(companyEventId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Download final report
     * @param {number} reportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadFinalReport: async (reportId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'reportId' is not null or undefined
      assertParamExists('downloadFinalReport', 'reportId', reportId);
      const localVarPath = `/api/v1/companies/final/report/download/{reportId}`.replace(
        `{${'reportId'}}`,
        encodeURIComponent(String(reportId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Filters companies.
     * @param {FilterCompaniesQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterCompanies: async (query?: FilterCompaniesQuery, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/companies/filter`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Gets address from Eircode
     * @param {string} eircode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAddressFromEircode: async (eircode: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'eircode' is not null or undefined
      assertParamExists('getAddressFromEircode', 'eircode', eircode);
      const localVarPath = `/api/v1/companies/{eircode}/address`.replace(
        `{${'eircode'}}`,
        encodeURIComponent(String(eircode)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Gets Assign Questionnaire model
     * @param {number} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAssignQuestionnaireToCompanyUserForm: async (companyId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists('getAssignQuestionnaireToCompanyUserForm', 'companyId', companyId);
      const localVarPath = `/api/v1/companies/{companyId}/assign/questionnaire`.replace(
        `{${'companyId'}}`,
        encodeURIComponent(String(companyId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Company Event Command
     * @param {number} companyEventId Company Event Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCompanyEventCommand: async (companyEventId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'companyEventId' is not null or undefined
      assertParamExists('getCompanyEventCommand', 'companyEventId', companyEventId);
      const localVarPath = `/api/v1/companies/event/command/{companyEventId}`.replace(
        `{${'companyEventId'}}`,
        encodeURIComponent(String(companyEventId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Company Events
     * @param {GetCompanyEventsQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCompanyEvents: async (query?: GetCompanyEventsQuery, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/companies/events`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Company Events Lookups
     * @param {GetCompanyEventsLookupsQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCompanyEventsLookups: async (query?: GetCompanyEventsLookupsQuery, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/companies/events/lookups`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get company final reports by questionnaire id
     * @param {number} companyId
     * @param {number} questionnaireId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCompanyFinalReports: async (
      companyId: number,
      questionnaireId: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists('getCompanyFinalReports', 'companyId', companyId);
      // verify required parameter 'questionnaireId' is not null or undefined
      assertParamExists('getCompanyFinalReports', 'questionnaireId', questionnaireId);
      const localVarPath = `/api/v1/companies/final/reports/{companyId}/{questionnaireId}`
        .replace(`{${'companyId'}}`, encodeURIComponent(String(companyId)))
        .replace(`{${'questionnaireId'}}`, encodeURIComponent(String(questionnaireId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Company Events
     * @param {number} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCompanyRoadmap: async (companyId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists('getCompanyRoadmap', 'companyId', companyId);
      const localVarPath = `/api/v1/companies/roadmap/{companyId}`.replace(
        `{${'companyId'}}`,
        encodeURIComponent(String(companyId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Gets company overview form data.
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCompanySaveDialogForm: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getCompanySaveDialogForm', 'id', id);
      const localVarPath = `/api/v1/companies/save/form/dialog/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Gets save form lookups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCompanySaveFormLookups: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/companies/save/form/lookups`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Gets company view
     * @param {number} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCompanyView: async (companyId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists('getCompanyView', 'companyId', companyId);
      const localVarPath = `/api/v1/companies/{companyId}/view`.replace(
        `{${'companyId'}}`,
        encodeURIComponent(String(companyId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Gets lookups for company filtering
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFilterCompaniesLookups: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/companies/lookups`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Gets invite user form lookups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInviteUserFormLookups: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/companies/invite/user/form/lookups`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Filters companies.
     * @param {GetUsersInCompanyDialogQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUsersInCompanyDialog: async (query?: GetUsersInCompanyDialogQuery, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/companies/users/company`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Updates company overview.
     * @param {InviteUserFormCommand} [inviteUserFormCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inviteUserForm: async (inviteUserFormCommand?: InviteUserFormCommand, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/companies/invite/user`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(inviteUserFormCommand, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Remove User From Questionnaire
     * @param {number} questionnaireVersionCompanyUserId Item to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeUserFromQuestionnaire: async (
      questionnaireVersionCompanyUserId: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'questionnaireVersionCompanyUserId' is not null or undefined
      assertParamExists(
        'removeUserFromQuestionnaire',
        'questionnaireVersionCompanyUserId',
        questionnaireVersionCompanyUserId,
      );
      const localVarPath = `/api/v1/companies/remove/{questionnaireVersionCompanyUserId}`.replace(
        `{${'questionnaireVersionCompanyUserId'}}`,
        encodeURIComponent(String(questionnaireVersionCompanyUserId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Save Company Event
     * @param {SaveCompanyEventCommand} [saveCompanyEventCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveCompanyEvent: async (
      saveCompanyEventCommand?: SaveCompanyEventCommand,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/companies/event/save`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        saveCompanyEventCommand,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Updates company overview.
     * @param {SaveCompanyFormCommand} [saveCompanyFormCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveCompanyForm: async (
      saveCompanyFormCommand?: SaveCompanyFormCommand,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/companies/save`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        saveCompanyFormCommand,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Resend invitation email
     * @param {number} id User Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendInvitationEmail: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('sendInvitationEmail', 'id', id);
      const localVarPath = `/api/v1/companies/send/invitation/email/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CompaniesApi - functional programming interface
 * @export
 */
export const CompaniesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CompaniesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Assign Questionnaire To Company
     * @param {AssignQuestionnaireToCompanyUserFormCommand} [assignQuestionnaireToCompanyUserFormCommand] Command to perform task.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async assignQuestionnaireToCompanyUserForm(
      assignQuestionnaireToCompanyUserFormCommand?: AssignQuestionnaireToCompanyUserFormCommand,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.assignQuestionnaireToCompanyUserForm(
        assignQuestionnaireToCompanyUserFormCommand,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Delete Company Event
     * @param {number} companyEventId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteCompanyEvent(
      companyEventId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCompanyEvent(companyEventId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Download final report
     * @param {number} reportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async downloadFinalReport(
      reportId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DownloadFinalReportModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.downloadFinalReport(reportId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Filters companies.
     * @param {FilterCompaniesQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async filterCompanies(
      query?: FilterCompaniesQuery,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FilterCompaniesModelGridCollection>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.filterCompanies(query, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Gets address from Eircode
     * @param {string} eircode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAddressFromEircode(
      eircode: string,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAddressFromEircodeModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAddressFromEircode(eircode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Gets Assign Questionnaire model
     * @param {number} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAssignQuestionnaireToCompanyUserForm(
      companyId: number,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAssignQuestionnaireToCompanyUserFormModel>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAssignQuestionnaireToCompanyUserForm(
        companyId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get Company Event Command
     * @param {number} companyEventId Company Event Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCompanyEventCommand(
      companyEventId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveCompanyEventCommand>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyEventCommand(companyEventId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get Company Events
     * @param {GetCompanyEventsQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCompanyEvents(
      query?: GetCompanyEventsQuery,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCompanyEventsModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyEvents(query, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get Company Events Lookups
     * @param {GetCompanyEventsLookupsQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCompanyEventsLookups(
      query?: GetCompanyEventsLookupsQuery,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCompanyEventsLookupsModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyEventsLookups(query, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get company final reports by questionnaire id
     * @param {number} companyId
     * @param {number} questionnaireId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCompanyFinalReports(
      companyId: number,
      questionnaireId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCompanyFinalReportModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyFinalReports(
        companyId,
        questionnaireId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get Company Events
     * @param {number} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCompanyRoadmap(
      companyId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRoadmapImageModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyRoadmap(companyId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Gets company overview form data.
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCompanySaveDialogForm(
      id: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveCompanyFormCommand>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanySaveDialogForm(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Gets save form lookups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCompanySaveFormLookups(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCompanySaveFormLookupsModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanySaveFormLookups(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Gets company view
     * @param {number} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCompanyView(
      companyId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCompanyViewModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyView(companyId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Gets lookups for company filtering
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFilterCompaniesLookups(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFilterCompaniesLookupsModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFilterCompaniesLookups(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Gets invite user form lookups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInviteUserFormLookups(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetInviteUserFormLookupsModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getInviteUserFormLookups(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Filters companies.
     * @param {GetUsersInCompanyDialogQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUsersInCompanyDialog(
      query?: GetUsersInCompanyDialogQuery,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUsersInCompanyDialogModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUsersInCompanyDialog(query, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Updates company overview.
     * @param {InviteUserFormCommand} [inviteUserFormCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async inviteUserForm(
      inviteUserFormCommand?: InviteUserFormCommand,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.inviteUserForm(inviteUserFormCommand, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Remove User From Questionnaire
     * @param {number} questionnaireVersionCompanyUserId Item to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async removeUserFromQuestionnaire(
      questionnaireVersionCompanyUserId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.removeUserFromQuestionnaire(
        questionnaireVersionCompanyUserId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Save Company Event
     * @param {SaveCompanyEventCommand} [saveCompanyEventCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saveCompanyEvent(
      saveCompanyEventCommand?: SaveCompanyEventCommand,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saveCompanyEvent(saveCompanyEventCommand, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Updates company overview.
     * @param {SaveCompanyFormCommand} [saveCompanyFormCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saveCompanyForm(
      saveCompanyFormCommand?: SaveCompanyFormCommand,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saveCompanyForm(saveCompanyFormCommand, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Resend invitation email
     * @param {number} id User Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sendInvitationEmail(
      id: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.sendInvitationEmail(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * CompaniesApi - factory interface
 * @export
 */
export const CompaniesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = CompaniesApiFp(configuration);
  return {
    /**
     *
     * @summary Assign Questionnaire To Company
     * @param {AssignQuestionnaireToCompanyUserFormCommand} [assignQuestionnaireToCompanyUserFormCommand] Command to perform task.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    assignQuestionnaireToCompanyUserForm(
      assignQuestionnaireToCompanyUserFormCommand?: AssignQuestionnaireToCompanyUserFormCommand,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .assignQuestionnaireToCompanyUserForm(assignQuestionnaireToCompanyUserFormCommand, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete Company Event
     * @param {number} companyEventId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCompanyEvent(companyEventId: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteCompanyEvent(companyEventId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Download final report
     * @param {number} reportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadFinalReport(reportId: number, options?: any): AxiosPromise<DownloadFinalReportModel> {
      return localVarFp.downloadFinalReport(reportId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Filters companies.
     * @param {FilterCompaniesQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterCompanies(query?: FilterCompaniesQuery, options?: any): AxiosPromise<FilterCompaniesModelGridCollection> {
      return localVarFp.filterCompanies(query, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Gets address from Eircode
     * @param {string} eircode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAddressFromEircode(eircode: string, options?: any): AxiosPromise<GetAddressFromEircodeModel> {
      return localVarFp.getAddressFromEircode(eircode, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Gets Assign Questionnaire model
     * @param {number} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAssignQuestionnaireToCompanyUserForm(
      companyId: number,
      options?: any,
    ): AxiosPromise<GetAssignQuestionnaireToCompanyUserFormModel> {
      return localVarFp
        .getAssignQuestionnaireToCompanyUserForm(companyId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Company Event Command
     * @param {number} companyEventId Company Event Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCompanyEventCommand(companyEventId: number, options?: any): AxiosPromise<SaveCompanyEventCommand> {
      return localVarFp.getCompanyEventCommand(companyEventId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Company Events
     * @param {GetCompanyEventsQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCompanyEvents(query?: GetCompanyEventsQuery, options?: any): AxiosPromise<GetCompanyEventsModel> {
      return localVarFp.getCompanyEvents(query, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Company Events Lookups
     * @param {GetCompanyEventsLookupsQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCompanyEventsLookups(
      query?: GetCompanyEventsLookupsQuery,
      options?: any,
    ): AxiosPromise<GetCompanyEventsLookupsModel> {
      return localVarFp.getCompanyEventsLookups(query, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get company final reports by questionnaire id
     * @param {number} companyId
     * @param {number} questionnaireId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCompanyFinalReports(
      companyId: number,
      questionnaireId: number,
      options?: any,
    ): AxiosPromise<GetCompanyFinalReportModel> {
      return localVarFp
        .getCompanyFinalReports(companyId, questionnaireId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Company Events
     * @param {number} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCompanyRoadmap(companyId: number, options?: any): AxiosPromise<GetRoadmapImageModel> {
      return localVarFp.getCompanyRoadmap(companyId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Gets company overview form data.
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCompanySaveDialogForm(id: number, options?: any): AxiosPromise<SaveCompanyFormCommand> {
      return localVarFp.getCompanySaveDialogForm(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Gets save form lookups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCompanySaveFormLookups(options?: any): AxiosPromise<GetCompanySaveFormLookupsModel> {
      return localVarFp.getCompanySaveFormLookups(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Gets company view
     * @param {number} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCompanyView(companyId: number, options?: any): AxiosPromise<GetCompanyViewModel> {
      return localVarFp.getCompanyView(companyId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Gets lookups for company filtering
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFilterCompaniesLookups(options?: any): AxiosPromise<GetFilterCompaniesLookupsModel> {
      return localVarFp.getFilterCompaniesLookups(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Gets invite user form lookups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInviteUserFormLookups(options?: any): AxiosPromise<GetInviteUserFormLookupsModel> {
      return localVarFp.getInviteUserFormLookups(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Filters companies.
     * @param {GetUsersInCompanyDialogQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUsersInCompanyDialog(
      query?: GetUsersInCompanyDialogQuery,
      options?: any,
    ): AxiosPromise<GetUsersInCompanyDialogModel> {
      return localVarFp.getUsersInCompanyDialog(query, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Updates company overview.
     * @param {InviteUserFormCommand} [inviteUserFormCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inviteUserForm(inviteUserFormCommand?: InviteUserFormCommand, options?: any): AxiosPromise<void> {
      return localVarFp.inviteUserForm(inviteUserFormCommand, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Remove User From Questionnaire
     * @param {number} questionnaireVersionCompanyUserId Item to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeUserFromQuestionnaire(questionnaireVersionCompanyUserId: number, options?: any): AxiosPromise<void> {
      return localVarFp
        .removeUserFromQuestionnaire(questionnaireVersionCompanyUserId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Save Company Event
     * @param {SaveCompanyEventCommand} [saveCompanyEventCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveCompanyEvent(saveCompanyEventCommand?: SaveCompanyEventCommand, options?: any): AxiosPromise<void> {
      return localVarFp.saveCompanyEvent(saveCompanyEventCommand, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Updates company overview.
     * @param {SaveCompanyFormCommand} [saveCompanyFormCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveCompanyForm(saveCompanyFormCommand?: SaveCompanyFormCommand, options?: any): AxiosPromise<void> {
      return localVarFp.saveCompanyForm(saveCompanyFormCommand, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Resend invitation email
     * @param {number} id User Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendInvitationEmail(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.sendInvitationEmail(id, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * CompaniesApi - interface
 * @export
 * @interface CompaniesApi
 */
export interface CompaniesApiInterface {
  /**
   *
   * @summary Assign Questionnaire To Company
   * @param {AssignQuestionnaireToCompanyUserFormCommand} [assignQuestionnaireToCompanyUserFormCommand] Command to perform task.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApiInterface
   */
  assignQuestionnaireToCompanyUserForm(
    assignQuestionnaireToCompanyUserFormCommand?: AssignQuestionnaireToCompanyUserFormCommand,
    options?: any,
  ): AxiosPromise<void>;

  /**
   *
   * @summary Delete Company Event
   * @param {number} companyEventId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApiInterface
   */
  deleteCompanyEvent(companyEventId: number, options?: any): AxiosPromise<void>;

  /**
   *
   * @summary Download final report
   * @param {number} reportId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApiInterface
   */
  downloadFinalReport(reportId: number, options?: any): AxiosPromise<DownloadFinalReportModel>;

  /**
   *
   * @summary Filters companies.
   * @param {FilterCompaniesQuery} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApiInterface
   */
  filterCompanies(query?: FilterCompaniesQuery, options?: any): AxiosPromise<FilterCompaniesModelGridCollection>;

  /**
   *
   * @summary Gets address from Eircode
   * @param {string} eircode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApiInterface
   */
  getAddressFromEircode(eircode: string, options?: any): AxiosPromise<GetAddressFromEircodeModel>;

  /**
   *
   * @summary Gets Assign Questionnaire model
   * @param {number} companyId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApiInterface
   */
  getAssignQuestionnaireToCompanyUserForm(
    companyId: number,
    options?: any,
  ): AxiosPromise<GetAssignQuestionnaireToCompanyUserFormModel>;

  /**
   *
   * @summary Get Company Event Command
   * @param {number} companyEventId Company Event Id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApiInterface
   */
  getCompanyEventCommand(companyEventId: number, options?: any): AxiosPromise<SaveCompanyEventCommand>;

  /**
   *
   * @summary Get Company Events
   * @param {GetCompanyEventsQuery} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApiInterface
   */
  getCompanyEvents(query?: GetCompanyEventsQuery, options?: any): AxiosPromise<GetCompanyEventsModel>;

  /**
   *
   * @summary Get Company Events Lookups
   * @param {GetCompanyEventsLookupsQuery} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApiInterface
   */
  getCompanyEventsLookups(
    query?: GetCompanyEventsLookupsQuery,
    options?: any,
  ): AxiosPromise<GetCompanyEventsLookupsModel>;

  /**
   *
   * @summary Get company final reports by questionnaire id
   * @param {number} companyId
   * @param {number} questionnaireId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApiInterface
   */
  getCompanyFinalReports(
    companyId: number,
    questionnaireId: number,
    options?: any,
  ): AxiosPromise<GetCompanyFinalReportModel>;

  /**
   *
   * @summary Get Company Events
   * @param {number} companyId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApiInterface
   */
  getCompanyRoadmap(companyId: number, options?: any): AxiosPromise<GetRoadmapImageModel>;

  /**
   *
   * @summary Gets company overview form data.
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApiInterface
   */
  getCompanySaveDialogForm(id: number, options?: any): AxiosPromise<SaveCompanyFormCommand>;

  /**
   *
   * @summary Gets save form lookups
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApiInterface
   */
  getCompanySaveFormLookups(options?: any): AxiosPromise<GetCompanySaveFormLookupsModel>;

  /**
   *
   * @summary Gets company view
   * @param {number} companyId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApiInterface
   */
  getCompanyView(companyId: number, options?: any): AxiosPromise<GetCompanyViewModel>;

  /**
   *
   * @summary Gets lookups for company filtering
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApiInterface
   */
  getFilterCompaniesLookups(options?: any): AxiosPromise<GetFilterCompaniesLookupsModel>;

  /**
   *
   * @summary Gets invite user form lookups
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApiInterface
   */
  getInviteUserFormLookups(options?: any): AxiosPromise<GetInviteUserFormLookupsModel>;

  /**
   *
   * @summary Filters companies.
   * @param {GetUsersInCompanyDialogQuery} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApiInterface
   */
  getUsersInCompanyDialog(
    query?: GetUsersInCompanyDialogQuery,
    options?: any,
  ): AxiosPromise<GetUsersInCompanyDialogModel>;

  /**
   *
   * @summary Updates company overview.
   * @param {InviteUserFormCommand} [inviteUserFormCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApiInterface
   */
  inviteUserForm(inviteUserFormCommand?: InviteUserFormCommand, options?: any): AxiosPromise<void>;

  /**
   *
   * @summary Remove User From Questionnaire
   * @param {number} questionnaireVersionCompanyUserId Item to be deleted
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApiInterface
   */
  removeUserFromQuestionnaire(questionnaireVersionCompanyUserId: number, options?: any): AxiosPromise<void>;

  /**
   *
   * @summary Save Company Event
   * @param {SaveCompanyEventCommand} [saveCompanyEventCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApiInterface
   */
  saveCompanyEvent(saveCompanyEventCommand?: SaveCompanyEventCommand, options?: any): AxiosPromise<void>;

  /**
   *
   * @summary Updates company overview.
   * @param {SaveCompanyFormCommand} [saveCompanyFormCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApiInterface
   */
  saveCompanyForm(saveCompanyFormCommand?: SaveCompanyFormCommand, options?: any): AxiosPromise<void>;

  /**
   *
   * @summary Resend invitation email
   * @param {number} id User Id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApiInterface
   */
  sendInvitationEmail(id: number, options?: any): AxiosPromise<void>;
}

/**
 * CompaniesApi - object-oriented interface
 * @export
 * @class CompaniesApi
 * @extends {BaseAPI}
 */
export class CompaniesApi extends BaseAPI implements CompaniesApiInterface {
  /**
   *
   * @summary Assign Questionnaire To Company
   * @param {AssignQuestionnaireToCompanyUserFormCommand} [assignQuestionnaireToCompanyUserFormCommand] Command to perform task.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public assignQuestionnaireToCompanyUserForm(
    assignQuestionnaireToCompanyUserFormCommand?: AssignQuestionnaireToCompanyUserFormCommand,
    options?: any,
  ) {
    return CompaniesApiFp(this.configuration)
      .assignQuestionnaireToCompanyUserForm(assignQuestionnaireToCompanyUserFormCommand, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete Company Event
   * @param {number} companyEventId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public deleteCompanyEvent(companyEventId: number, options?: any) {
    return CompaniesApiFp(this.configuration)
      .deleteCompanyEvent(companyEventId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Download final report
   * @param {number} reportId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public downloadFinalReport(reportId: number, options?: any) {
    return CompaniesApiFp(this.configuration)
      .downloadFinalReport(reportId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Filters companies.
   * @param {FilterCompaniesQuery} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public filterCompanies(query?: FilterCompaniesQuery, options?: any) {
    return CompaniesApiFp(this.configuration)
      .filterCompanies(query, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Gets address from Eircode
   * @param {string} eircode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public getAddressFromEircode(eircode: string, options?: any) {
    return CompaniesApiFp(this.configuration)
      .getAddressFromEircode(eircode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Gets Assign Questionnaire model
   * @param {number} companyId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public getAssignQuestionnaireToCompanyUserForm(companyId: number, options?: any) {
    return CompaniesApiFp(this.configuration)
      .getAssignQuestionnaireToCompanyUserForm(companyId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Company Event Command
   * @param {number} companyEventId Company Event Id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public getCompanyEventCommand(companyEventId: number, options?: any) {
    return CompaniesApiFp(this.configuration)
      .getCompanyEventCommand(companyEventId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Company Events
   * @param {GetCompanyEventsQuery} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public getCompanyEvents(query?: GetCompanyEventsQuery, options?: any) {
    return CompaniesApiFp(this.configuration)
      .getCompanyEvents(query, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Company Events Lookups
   * @param {GetCompanyEventsLookupsQuery} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public getCompanyEventsLookups(query?: GetCompanyEventsLookupsQuery, options?: any) {
    return CompaniesApiFp(this.configuration)
      .getCompanyEventsLookups(query, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get company final reports by questionnaire id
   * @param {number} companyId
   * @param {number} questionnaireId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public getCompanyFinalReports(companyId: number, questionnaireId: number, options?: any) {
    return CompaniesApiFp(this.configuration)
      .getCompanyFinalReports(companyId, questionnaireId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Company Events
   * @param {number} companyId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public getCompanyRoadmap(companyId: number, options?: any) {
    return CompaniesApiFp(this.configuration)
      .getCompanyRoadmap(companyId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Gets company overview form data.
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public getCompanySaveDialogForm(id: number, options?: any) {
    return CompaniesApiFp(this.configuration)
      .getCompanySaveDialogForm(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Gets save form lookups
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public getCompanySaveFormLookups(options?: any) {
    return CompaniesApiFp(this.configuration)
      .getCompanySaveFormLookups(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Gets company view
   * @param {number} companyId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public getCompanyView(companyId: number, options?: any) {
    return CompaniesApiFp(this.configuration)
      .getCompanyView(companyId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Gets lookups for company filtering
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public getFilterCompaniesLookups(options?: any) {
    return CompaniesApiFp(this.configuration)
      .getFilterCompaniesLookups(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Gets invite user form lookups
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public getInviteUserFormLookups(options?: any) {
    return CompaniesApiFp(this.configuration)
      .getInviteUserFormLookups(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Filters companies.
   * @param {GetUsersInCompanyDialogQuery} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public getUsersInCompanyDialog(query?: GetUsersInCompanyDialogQuery, options?: any) {
    return CompaniesApiFp(this.configuration)
      .getUsersInCompanyDialog(query, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Updates company overview.
   * @param {InviteUserFormCommand} [inviteUserFormCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public inviteUserForm(inviteUserFormCommand?: InviteUserFormCommand, options?: any) {
    return CompaniesApiFp(this.configuration)
      .inviteUserForm(inviteUserFormCommand, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Remove User From Questionnaire
   * @param {number} questionnaireVersionCompanyUserId Item to be deleted
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public removeUserFromQuestionnaire(questionnaireVersionCompanyUserId: number, options?: any) {
    return CompaniesApiFp(this.configuration)
      .removeUserFromQuestionnaire(questionnaireVersionCompanyUserId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Save Company Event
   * @param {SaveCompanyEventCommand} [saveCompanyEventCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public saveCompanyEvent(saveCompanyEventCommand?: SaveCompanyEventCommand, options?: any) {
    return CompaniesApiFp(this.configuration)
      .saveCompanyEvent(saveCompanyEventCommand, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Updates company overview.
   * @param {SaveCompanyFormCommand} [saveCompanyFormCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public saveCompanyForm(saveCompanyFormCommand?: SaveCompanyFormCommand, options?: any) {
    return CompaniesApiFp(this.configuration)
      .saveCompanyForm(saveCompanyFormCommand, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Resend invitation email
   * @param {number} id User Id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public sendInvitationEmail(id: number, options?: any) {
    return CompaniesApiFp(this.configuration)
      .sendInvitationEmail(id, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * ContentManagementApi - axios parameter creator
 * @export
 */
export const ContentManagementApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Filters Content.
     * @param {FilterContentPlaceholderQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterContentManagement: async (query?: FilterContentPlaceholderQuery, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/content/management/filter`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Filters Option level.
     * @param {FilterQuestionOptionLevelQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterQuestionOptionLevel: async (
      query?: FilterQuestionOptionLevelQuery,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/content/management/option/level/filter`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get lookups for filter screen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFilterContentManagementLookups: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/content/management/filter/lookups`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get lookups for filter screen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFilterQuestionOptionLevelLookups: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/content/management/option/level/filter/lookups`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary get command to update content
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSaveContentManagementCommand: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getSaveContentManagementCommand', 'id', id);
      const localVarPath = `/api/v1/content/management/command/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary get command to update content
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSaveQuestionOptionLevelDescriptionCommand: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getSaveQuestionOptionLevelDescriptionCommand', 'id', id);
      const localVarPath = `/api/v1/content/management/option/level/command/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Updates content
     * @param {SaveContentPlaceholderFormCommand} [saveContentPlaceholderFormCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveContentManagementForm: async (
      saveContentPlaceholderFormCommand?: SaveContentPlaceholderFormCommand,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/content/management/save`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        saveContentPlaceholderFormCommand,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Updates content
     * @param {SaveQuestionOptionLevelDescriptionFormCommand} [saveQuestionOptionLevelDescriptionFormCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveQuestionOptionLevelDescriptionForm: async (
      saveQuestionOptionLevelDescriptionFormCommand?: SaveQuestionOptionLevelDescriptionFormCommand,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/content/management/option/level/save`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        saveQuestionOptionLevelDescriptionFormCommand,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ContentManagementApi - functional programming interface
 * @export
 */
export const ContentManagementApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ContentManagementApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Filters Content.
     * @param {FilterContentPlaceholderQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async filterContentManagement(
      query?: FilterContentPlaceholderQuery,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FilterContentPlaceholderModelGridCollection>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.filterContentManagement(query, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Filters Option level.
     * @param {FilterQuestionOptionLevelQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async filterQuestionOptionLevel(
      query?: FilterQuestionOptionLevelQuery,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FilterQuestionOptionLevelModelGridCollection>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.filterQuestionOptionLevel(query, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get lookups for filter screen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFilterContentManagementLookups(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFilterContentPlaceholderLookupsModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFilterContentManagementLookups(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get lookups for filter screen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFilterQuestionOptionLevelLookups(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFilterQuestionOptionLevelLookupsModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFilterQuestionOptionLevelLookups(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary get command to update content
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSaveContentManagementCommand(
      id: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveContentPlaceholderFormCommand>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSaveContentManagementCommand(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary get command to update content
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSaveQuestionOptionLevelDescriptionCommand(
      id: number,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveQuestionOptionLevelDescriptionFormCommand>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSaveQuestionOptionLevelDescriptionCommand(
        id,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Updates content
     * @param {SaveContentPlaceholderFormCommand} [saveContentPlaceholderFormCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saveContentManagementForm(
      saveContentPlaceholderFormCommand?: SaveContentPlaceholderFormCommand,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saveContentManagementForm(
        saveContentPlaceholderFormCommand,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Updates content
     * @param {SaveQuestionOptionLevelDescriptionFormCommand} [saveQuestionOptionLevelDescriptionFormCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saveQuestionOptionLevelDescriptionForm(
      saveQuestionOptionLevelDescriptionFormCommand?: SaveQuestionOptionLevelDescriptionFormCommand,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saveQuestionOptionLevelDescriptionForm(
        saveQuestionOptionLevelDescriptionFormCommand,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ContentManagementApi - factory interface
 * @export
 */
export const ContentManagementApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ContentManagementApiFp(configuration);
  return {
    /**
     *
     * @summary Filters Content.
     * @param {FilterContentPlaceholderQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterContentManagement(
      query?: FilterContentPlaceholderQuery,
      options?: any,
    ): AxiosPromise<FilterContentPlaceholderModelGridCollection> {
      return localVarFp.filterContentManagement(query, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Filters Option level.
     * @param {FilterQuestionOptionLevelQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterQuestionOptionLevel(
      query?: FilterQuestionOptionLevelQuery,
      options?: any,
    ): AxiosPromise<FilterQuestionOptionLevelModelGridCollection> {
      return localVarFp.filterQuestionOptionLevel(query, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get lookups for filter screen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFilterContentManagementLookups(options?: any): AxiosPromise<GetFilterContentPlaceholderLookupsModel> {
      return localVarFp.getFilterContentManagementLookups(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get lookups for filter screen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFilterQuestionOptionLevelLookups(options?: any): AxiosPromise<GetFilterQuestionOptionLevelLookupsModel> {
      return localVarFp.getFilterQuestionOptionLevelLookups(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary get command to update content
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSaveContentManagementCommand(id: number, options?: any): AxiosPromise<SaveContentPlaceholderFormCommand> {
      return localVarFp.getSaveContentManagementCommand(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary get command to update content
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSaveQuestionOptionLevelDescriptionCommand(
      id: number,
      options?: any,
    ): AxiosPromise<SaveQuestionOptionLevelDescriptionFormCommand> {
      return localVarFp
        .getSaveQuestionOptionLevelDescriptionCommand(id, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Updates content
     * @param {SaveContentPlaceholderFormCommand} [saveContentPlaceholderFormCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveContentManagementForm(
      saveContentPlaceholderFormCommand?: SaveContentPlaceholderFormCommand,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .saveContentManagementForm(saveContentPlaceholderFormCommand, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Updates content
     * @param {SaveQuestionOptionLevelDescriptionFormCommand} [saveQuestionOptionLevelDescriptionFormCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveQuestionOptionLevelDescriptionForm(
      saveQuestionOptionLevelDescriptionFormCommand?: SaveQuestionOptionLevelDescriptionFormCommand,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .saveQuestionOptionLevelDescriptionForm(saveQuestionOptionLevelDescriptionFormCommand, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * ContentManagementApi - interface
 * @export
 * @interface ContentManagementApi
 */
export interface ContentManagementApiInterface {
  /**
   *
   * @summary Filters Content.
   * @param {FilterContentPlaceholderQuery} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContentManagementApiInterface
   */
  filterContentManagement(
    query?: FilterContentPlaceholderQuery,
    options?: any,
  ): AxiosPromise<FilterContentPlaceholderModelGridCollection>;

  /**
   *
   * @summary Filters Option level.
   * @param {FilterQuestionOptionLevelQuery} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContentManagementApiInterface
   */
  filterQuestionOptionLevel(
    query?: FilterQuestionOptionLevelQuery,
    options?: any,
  ): AxiosPromise<FilterQuestionOptionLevelModelGridCollection>;

  /**
   *
   * @summary Get lookups for filter screen
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContentManagementApiInterface
   */
  getFilterContentManagementLookups(options?: any): AxiosPromise<GetFilterContentPlaceholderLookupsModel>;

  /**
   *
   * @summary Get lookups for filter screen
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContentManagementApiInterface
   */
  getFilterQuestionOptionLevelLookups(options?: any): AxiosPromise<GetFilterQuestionOptionLevelLookupsModel>;

  /**
   *
   * @summary get command to update content
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContentManagementApiInterface
   */
  getSaveContentManagementCommand(id: number, options?: any): AxiosPromise<SaveContentPlaceholderFormCommand>;

  /**
   *
   * @summary get command to update content
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContentManagementApiInterface
   */
  getSaveQuestionOptionLevelDescriptionCommand(
    id: number,
    options?: any,
  ): AxiosPromise<SaveQuestionOptionLevelDescriptionFormCommand>;

  /**
   *
   * @summary Updates content
   * @param {SaveContentPlaceholderFormCommand} [saveContentPlaceholderFormCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContentManagementApiInterface
   */
  saveContentManagementForm(
    saveContentPlaceholderFormCommand?: SaveContentPlaceholderFormCommand,
    options?: any,
  ): AxiosPromise<void>;

  /**
   *
   * @summary Updates content
   * @param {SaveQuestionOptionLevelDescriptionFormCommand} [saveQuestionOptionLevelDescriptionFormCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContentManagementApiInterface
   */
  saveQuestionOptionLevelDescriptionForm(
    saveQuestionOptionLevelDescriptionFormCommand?: SaveQuestionOptionLevelDescriptionFormCommand,
    options?: any,
  ): AxiosPromise<void>;
}

/**
 * ContentManagementApi - object-oriented interface
 * @export
 * @class ContentManagementApi
 * @extends {BaseAPI}
 */
export class ContentManagementApi extends BaseAPI implements ContentManagementApiInterface {
  /**
   *
   * @summary Filters Content.
   * @param {FilterContentPlaceholderQuery} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContentManagementApi
   */
  public filterContentManagement(query?: FilterContentPlaceholderQuery, options?: any) {
    return ContentManagementApiFp(this.configuration)
      .filterContentManagement(query, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Filters Option level.
   * @param {FilterQuestionOptionLevelQuery} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContentManagementApi
   */
  public filterQuestionOptionLevel(query?: FilterQuestionOptionLevelQuery, options?: any) {
    return ContentManagementApiFp(this.configuration)
      .filterQuestionOptionLevel(query, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get lookups for filter screen
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContentManagementApi
   */
  public getFilterContentManagementLookups(options?: any) {
    return ContentManagementApiFp(this.configuration)
      .getFilterContentManagementLookups(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get lookups for filter screen
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContentManagementApi
   */
  public getFilterQuestionOptionLevelLookups(options?: any) {
    return ContentManagementApiFp(this.configuration)
      .getFilterQuestionOptionLevelLookups(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary get command to update content
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContentManagementApi
   */
  public getSaveContentManagementCommand(id: number, options?: any) {
    return ContentManagementApiFp(this.configuration)
      .getSaveContentManagementCommand(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary get command to update content
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContentManagementApi
   */
  public getSaveQuestionOptionLevelDescriptionCommand(id: number, options?: any) {
    return ContentManagementApiFp(this.configuration)
      .getSaveQuestionOptionLevelDescriptionCommand(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Updates content
   * @param {SaveContentPlaceholderFormCommand} [saveContentPlaceholderFormCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContentManagementApi
   */
  public saveContentManagementForm(
    saveContentPlaceholderFormCommand?: SaveContentPlaceholderFormCommand,
    options?: any,
  ) {
    return ContentManagementApiFp(this.configuration)
      .saveContentManagementForm(saveContentPlaceholderFormCommand, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Updates content
   * @param {SaveQuestionOptionLevelDescriptionFormCommand} [saveQuestionOptionLevelDescriptionFormCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContentManagementApi
   */
  public saveQuestionOptionLevelDescriptionForm(
    saveQuestionOptionLevelDescriptionFormCommand?: SaveQuestionOptionLevelDescriptionFormCommand,
    options?: any,
  ) {
    return ContentManagementApiFp(this.configuration)
      .saveQuestionOptionLevelDescriptionForm(saveQuestionOptionLevelDescriptionFormCommand, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * EventsApi - axios parameter creator
 * @export
 */
export const EventsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Delete Event Milestone
     * @param {number} eventMilestoneId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteEventMilestone: async (eventMilestoneId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'eventMilestoneId' is not null or undefined
      assertParamExists('deleteEventMilestone', 'eventMilestoneId', eventMilestoneId);
      const localVarPath = `/api/v1/events/event/milestone/delete/{eventMilestoneId}`.replace(
        `{${'eventMilestoneId'}}`,
        encodeURIComponent(String(eventMilestoneId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete Event Type
     * @param {number} eventTypeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteEventType: async (eventTypeId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'eventTypeId' is not null or undefined
      assertParamExists('deleteEventType', 'eventTypeId', eventTypeId);
      const localVarPath = `/api/v1/events/event/type/delete/{eventTypeId}`.replace(
        `{${'eventTypeId'}}`,
        encodeURIComponent(String(eventTypeId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Event Milestone Command
     * @param {number} eventMilestoneId Event Milestone Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEventMilestoneCommand: async (eventMilestoneId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'eventMilestoneId' is not null or undefined
      assertParamExists('getEventMilestoneCommand', 'eventMilestoneId', eventMilestoneId);
      const localVarPath = `/api/v1/events/event/milestone/command/{eventMilestoneId}`.replace(
        `{${'eventMilestoneId'}}`,
        encodeURIComponent(String(eventMilestoneId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Event Milestones
     * @param {GetEventMilestonesQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEventMilestones: async (query?: GetEventMilestonesQuery, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/events/event/milestones`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Event Type Command
     * @param {number} eventTypeId Event Type Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEventTypeCommand: async (eventTypeId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'eventTypeId' is not null or undefined
      assertParamExists('getEventTypeCommand', 'eventTypeId', eventTypeId);
      const localVarPath = `/api/v1/events/event/type/command/{eventTypeId}`.replace(
        `{${'eventTypeId'}}`,
        encodeURIComponent(String(eventTypeId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Event Milestone Lookups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEventTypeFormLookups: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/events/event/type/form/lookups`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Event Milestone Lookups
     * @param {number} eventMilestoneId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEventTypeLookups: async (eventMilestoneId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'eventMilestoneId' is not null or undefined
      assertParamExists('getEventTypeLookups', 'eventMilestoneId', eventMilestoneId);
      const localVarPath = `/api/v1/events/types/{eventMilestoneId}`.replace(
        `{${'eventMilestoneId'}}`,
        encodeURIComponent(String(eventMilestoneId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Event types
     * @param {GetEventTypesQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEventTypes: async (query?: GetEventTypesQuery, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/events/event/types`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Save Event Milestone
     * @param {SaveEventMilestoneCommand} [saveEventMilestoneCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveEventMilestone: async (
      saveEventMilestoneCommand?: SaveEventMilestoneCommand,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/events/event/milestone/save`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        saveEventMilestoneCommand,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Save Event type
     * @param {SaveEventTypeCommand} [saveEventTypeCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveEventType: async (saveEventTypeCommand?: SaveEventTypeCommand, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/events/event/type/save`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(saveEventTypeCommand, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * EventsApi - functional programming interface
 * @export
 */
export const EventsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = EventsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Delete Event Milestone
     * @param {number} eventMilestoneId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteEventMilestone(
      eventMilestoneId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEventMilestone(eventMilestoneId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Delete Event Type
     * @param {number} eventTypeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteEventType(
      eventTypeId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEventType(eventTypeId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get Event Milestone Command
     * @param {number} eventMilestoneId Event Milestone Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEventMilestoneCommand(
      eventMilestoneId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveEventMilestoneCommand>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getEventMilestoneCommand(eventMilestoneId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get Event Milestones
     * @param {GetEventMilestonesQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEventMilestones(
      query?: GetEventMilestonesQuery,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetEventMilestonesModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getEventMilestones(query, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get Event Type Command
     * @param {number} eventTypeId Event Type Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEventTypeCommand(
      eventTypeId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveEventTypeCommand>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getEventTypeCommand(eventTypeId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get Event Milestone Lookups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEventTypeFormLookups(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetEventTypeFormLookupsModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getEventTypeFormLookups(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get Event Milestone Lookups
     * @param {number} eventMilestoneId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEventTypeLookups(
      eventMilestoneId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetEventTypeLookupsModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getEventTypeLookups(eventMilestoneId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get Event types
     * @param {GetEventTypesQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEventTypes(
      query?: GetEventTypesQuery,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetEventTypesModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getEventTypes(query, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Save Event Milestone
     * @param {SaveEventMilestoneCommand} [saveEventMilestoneCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saveEventMilestone(
      saveEventMilestoneCommand?: SaveEventMilestoneCommand,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saveEventMilestone(saveEventMilestoneCommand, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Save Event type
     * @param {SaveEventTypeCommand} [saveEventTypeCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saveEventType(
      saveEventTypeCommand?: SaveEventTypeCommand,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saveEventType(saveEventTypeCommand, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * EventsApi - factory interface
 * @export
 */
export const EventsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = EventsApiFp(configuration);
  return {
    /**
     *
     * @summary Delete Event Milestone
     * @param {number} eventMilestoneId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteEventMilestone(eventMilestoneId: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteEventMilestone(eventMilestoneId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete Event Type
     * @param {number} eventTypeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteEventType(eventTypeId: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteEventType(eventTypeId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Event Milestone Command
     * @param {number} eventMilestoneId Event Milestone Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEventMilestoneCommand(eventMilestoneId: number, options?: any): AxiosPromise<SaveEventMilestoneCommand> {
      return localVarFp.getEventMilestoneCommand(eventMilestoneId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Event Milestones
     * @param {GetEventMilestonesQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEventMilestones(query?: GetEventMilestonesQuery, options?: any): AxiosPromise<GetEventMilestonesModel> {
      return localVarFp.getEventMilestones(query, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Event Type Command
     * @param {number} eventTypeId Event Type Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEventTypeCommand(eventTypeId: number, options?: any): AxiosPromise<SaveEventTypeCommand> {
      return localVarFp.getEventTypeCommand(eventTypeId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Event Milestone Lookups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEventTypeFormLookups(options?: any): AxiosPromise<GetEventTypeFormLookupsModel> {
      return localVarFp.getEventTypeFormLookups(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Event Milestone Lookups
     * @param {number} eventMilestoneId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEventTypeLookups(eventMilestoneId: number, options?: any): AxiosPromise<GetEventTypeLookupsModel> {
      return localVarFp.getEventTypeLookups(eventMilestoneId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Event types
     * @param {GetEventTypesQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEventTypes(query?: GetEventTypesQuery, options?: any): AxiosPromise<GetEventTypesModel> {
      return localVarFp.getEventTypes(query, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Save Event Milestone
     * @param {SaveEventMilestoneCommand} [saveEventMilestoneCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveEventMilestone(saveEventMilestoneCommand?: SaveEventMilestoneCommand, options?: any): AxiosPromise<void> {
      return localVarFp
        .saveEventMilestone(saveEventMilestoneCommand, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Save Event type
     * @param {SaveEventTypeCommand} [saveEventTypeCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveEventType(saveEventTypeCommand?: SaveEventTypeCommand, options?: any): AxiosPromise<void> {
      return localVarFp.saveEventType(saveEventTypeCommand, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * EventsApi - interface
 * @export
 * @interface EventsApi
 */
export interface EventsApiInterface {
  /**
   *
   * @summary Delete Event Milestone
   * @param {number} eventMilestoneId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventsApiInterface
   */
  deleteEventMilestone(eventMilestoneId: number, options?: any): AxiosPromise<void>;

  /**
   *
   * @summary Delete Event Type
   * @param {number} eventTypeId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventsApiInterface
   */
  deleteEventType(eventTypeId: number, options?: any): AxiosPromise<void>;

  /**
   *
   * @summary Get Event Milestone Command
   * @param {number} eventMilestoneId Event Milestone Id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventsApiInterface
   */
  getEventMilestoneCommand(eventMilestoneId: number, options?: any): AxiosPromise<SaveEventMilestoneCommand>;

  /**
   *
   * @summary Get Event Milestones
   * @param {GetEventMilestonesQuery} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventsApiInterface
   */
  getEventMilestones(query?: GetEventMilestonesQuery, options?: any): AxiosPromise<GetEventMilestonesModel>;

  /**
   *
   * @summary Get Event Type Command
   * @param {number} eventTypeId Event Type Id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventsApiInterface
   */
  getEventTypeCommand(eventTypeId: number, options?: any): AxiosPromise<SaveEventTypeCommand>;

  /**
   *
   * @summary Get Event Milestone Lookups
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventsApiInterface
   */
  getEventTypeFormLookups(options?: any): AxiosPromise<GetEventTypeFormLookupsModel>;

  /**
   *
   * @summary Get Event Milestone Lookups
   * @param {number} eventMilestoneId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventsApiInterface
   */
  getEventTypeLookups(eventMilestoneId: number, options?: any): AxiosPromise<GetEventTypeLookupsModel>;

  /**
   *
   * @summary Get Event types
   * @param {GetEventTypesQuery} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventsApiInterface
   */
  getEventTypes(query?: GetEventTypesQuery, options?: any): AxiosPromise<GetEventTypesModel>;

  /**
   *
   * @summary Save Event Milestone
   * @param {SaveEventMilestoneCommand} [saveEventMilestoneCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventsApiInterface
   */
  saveEventMilestone(saveEventMilestoneCommand?: SaveEventMilestoneCommand, options?: any): AxiosPromise<void>;

  /**
   *
   * @summary Save Event type
   * @param {SaveEventTypeCommand} [saveEventTypeCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventsApiInterface
   */
  saveEventType(saveEventTypeCommand?: SaveEventTypeCommand, options?: any): AxiosPromise<void>;
}

/**
 * EventsApi - object-oriented interface
 * @export
 * @class EventsApi
 * @extends {BaseAPI}
 */
export class EventsApi extends BaseAPI implements EventsApiInterface {
  /**
   *
   * @summary Delete Event Milestone
   * @param {number} eventMilestoneId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventsApi
   */
  public deleteEventMilestone(eventMilestoneId: number, options?: any) {
    return EventsApiFp(this.configuration)
      .deleteEventMilestone(eventMilestoneId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete Event Type
   * @param {number} eventTypeId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventsApi
   */
  public deleteEventType(eventTypeId: number, options?: any) {
    return EventsApiFp(this.configuration)
      .deleteEventType(eventTypeId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Event Milestone Command
   * @param {number} eventMilestoneId Event Milestone Id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventsApi
   */
  public getEventMilestoneCommand(eventMilestoneId: number, options?: any) {
    return EventsApiFp(this.configuration)
      .getEventMilestoneCommand(eventMilestoneId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Event Milestones
   * @param {GetEventMilestonesQuery} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventsApi
   */
  public getEventMilestones(query?: GetEventMilestonesQuery, options?: any) {
    return EventsApiFp(this.configuration)
      .getEventMilestones(query, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Event Type Command
   * @param {number} eventTypeId Event Type Id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventsApi
   */
  public getEventTypeCommand(eventTypeId: number, options?: any) {
    return EventsApiFp(this.configuration)
      .getEventTypeCommand(eventTypeId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Event Milestone Lookups
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventsApi
   */
  public getEventTypeFormLookups(options?: any) {
    return EventsApiFp(this.configuration)
      .getEventTypeFormLookups(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Event Milestone Lookups
   * @param {number} eventMilestoneId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventsApi
   */
  public getEventTypeLookups(eventMilestoneId: number, options?: any) {
    return EventsApiFp(this.configuration)
      .getEventTypeLookups(eventMilestoneId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Event types
   * @param {GetEventTypesQuery} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventsApi
   */
  public getEventTypes(query?: GetEventTypesQuery, options?: any) {
    return EventsApiFp(this.configuration)
      .getEventTypes(query, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Save Event Milestone
   * @param {SaveEventMilestoneCommand} [saveEventMilestoneCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventsApi
   */
  public saveEventMilestone(saveEventMilestoneCommand?: SaveEventMilestoneCommand, options?: any) {
    return EventsApiFp(this.configuration)
      .saveEventMilestone(saveEventMilestoneCommand, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Save Event type
   * @param {SaveEventTypeCommand} [saveEventTypeCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventsApi
   */
  public saveEventType(saveEventTypeCommand?: SaveEventTypeCommand, options?: any) {
    return EventsApiFp(this.configuration)
      .saveEventType(saveEventTypeCommand, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * HomeApi - axios parameter creator
 * @export
 */
export const HomeApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get admin dashboard
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAdminDashboard: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/home/admin/dashboard`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * HomeApi - functional programming interface
 * @export
 */
export const HomeApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = HomeApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get admin dashboard
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAdminDashboard(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAdminDashboardModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminDashboard(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * HomeApi - factory interface
 * @export
 */
export const HomeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = HomeApiFp(configuration);
  return {
    /**
     *
     * @summary Get admin dashboard
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAdminDashboard(options?: any): AxiosPromise<GetAdminDashboardModel> {
      return localVarFp.getAdminDashboard(options).then(request => request(axios, basePath));
    },
  };
};

/**
 * HomeApi - interface
 * @export
 * @interface HomeApi
 */
export interface HomeApiInterface {
  /**
   *
   * @summary Get admin dashboard
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HomeApiInterface
   */
  getAdminDashboard(options?: any): AxiosPromise<GetAdminDashboardModel>;
}

/**
 * HomeApi - object-oriented interface
 * @export
 * @class HomeApi
 * @extends {BaseAPI}
 */
export class HomeApi extends BaseAPI implements HomeApiInterface {
  /**
   *
   * @summary Get admin dashboard
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HomeApi
   */
  public getAdminDashboard(options?: any) {
    return HomeApiFp(this.configuration)
      .getAdminDashboard(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * HouseFloorsApi - axios parameter creator
 * @export
 */
export const HouseFloorsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFloor: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteFloor', 'id', id);
      const localVarPath = `/api/v1/house-floors/delete-floor/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteRoom: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteRoom', 'id', id);
      const localVarPath = `/api/v1/house-floors/delete-room/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllQuestionnaireTypesWithFloorsAndRooms: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/house-floors/all`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSaveFloorCommand: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getSaveFloorCommand', 'id', id);
      const localVarPath = `/api/v1/house-floors/floor/{id}/form`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSaveRoomCommand: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getSaveRoomCommand', 'id', id);
      const localVarPath = `/api/v1/house-floors/room/{id}/form`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {SaveFloorFormCommand} [saveFloorFormCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveFloor: async (saveFloorFormCommand?: SaveFloorFormCommand, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/house-floors/save-floor`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(saveFloorFormCommand, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {SaveRoomFormCommand} [saveRoomFormCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveRoom: async (saveRoomFormCommand?: SaveRoomFormCommand, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/house-floors/save-room`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(saveRoomFormCommand, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * HouseFloorsApi - functional programming interface
 * @export
 */
export const HouseFloorsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = HouseFloorsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteFloor(
      id: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFloor(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteRoom(
      id: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRoom(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllQuestionnaireTypesWithFloorsAndRooms(
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetHouseFloorsByTypeQuestionnaireTypeModel>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllQuestionnaireTypesWithFloorsAndRooms(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSaveFloorCommand(
      id: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveFloorFormCommand>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSaveFloorCommand(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSaveRoomCommand(
      id: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveRoomFormCommand>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSaveRoomCommand(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {SaveFloorFormCommand} [saveFloorFormCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saveFloor(
      saveFloorFormCommand?: SaveFloorFormCommand,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saveFloor(saveFloorFormCommand, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {SaveRoomFormCommand} [saveRoomFormCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saveRoom(
      saveRoomFormCommand?: SaveRoomFormCommand,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saveRoom(saveRoomFormCommand, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * HouseFloorsApi - factory interface
 * @export
 */
export const HouseFloorsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = HouseFloorsApiFp(configuration);
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFloor(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteFloor(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteRoom(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteRoom(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllQuestionnaireTypesWithFloorsAndRooms(
      options?: any,
    ): AxiosPromise<Array<GetHouseFloorsByTypeQuestionnaireTypeModel>> {
      return localVarFp.getAllQuestionnaireTypesWithFloorsAndRooms(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSaveFloorCommand(id: number, options?: any): AxiosPromise<SaveFloorFormCommand> {
      return localVarFp.getSaveFloorCommand(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSaveRoomCommand(id: number, options?: any): AxiosPromise<SaveRoomFormCommand> {
      return localVarFp.getSaveRoomCommand(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {SaveFloorFormCommand} [saveFloorFormCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveFloor(saveFloorFormCommand?: SaveFloorFormCommand, options?: any): AxiosPromise<number> {
      return localVarFp.saveFloor(saveFloorFormCommand, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {SaveRoomFormCommand} [saveRoomFormCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveRoom(saveRoomFormCommand?: SaveRoomFormCommand, options?: any): AxiosPromise<number> {
      return localVarFp.saveRoom(saveRoomFormCommand, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * HouseFloorsApi - interface
 * @export
 * @interface HouseFloorsApi
 */
export interface HouseFloorsApiInterface {
  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HouseFloorsApiInterface
   */
  deleteFloor(id: number, options?: any): AxiosPromise<void>;

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HouseFloorsApiInterface
   */
  deleteRoom(id: number, options?: any): AxiosPromise<void>;

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HouseFloorsApiInterface
   */
  getAllQuestionnaireTypesWithFloorsAndRooms(
    options?: any,
  ): AxiosPromise<Array<GetHouseFloorsByTypeQuestionnaireTypeModel>>;

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HouseFloorsApiInterface
   */
  getSaveFloorCommand(id: number, options?: any): AxiosPromise<SaveFloorFormCommand>;

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HouseFloorsApiInterface
   */
  getSaveRoomCommand(id: number, options?: any): AxiosPromise<SaveRoomFormCommand>;

  /**
   *
   * @param {SaveFloorFormCommand} [saveFloorFormCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HouseFloorsApiInterface
   */
  saveFloor(saveFloorFormCommand?: SaveFloorFormCommand, options?: any): AxiosPromise<number>;

  /**
   *
   * @param {SaveRoomFormCommand} [saveRoomFormCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HouseFloorsApiInterface
   */
  saveRoom(saveRoomFormCommand?: SaveRoomFormCommand, options?: any): AxiosPromise<number>;
}

/**
 * HouseFloorsApi - object-oriented interface
 * @export
 * @class HouseFloorsApi
 * @extends {BaseAPI}
 */
export class HouseFloorsApi extends BaseAPI implements HouseFloorsApiInterface {
  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HouseFloorsApi
   */
  public deleteFloor(id: number, options?: any) {
    return HouseFloorsApiFp(this.configuration)
      .deleteFloor(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HouseFloorsApi
   */
  public deleteRoom(id: number, options?: any) {
    return HouseFloorsApiFp(this.configuration)
      .deleteRoom(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HouseFloorsApi
   */
  public getAllQuestionnaireTypesWithFloorsAndRooms(options?: any) {
    return HouseFloorsApiFp(this.configuration)
      .getAllQuestionnaireTypesWithFloorsAndRooms(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HouseFloorsApi
   */
  public getSaveFloorCommand(id: number, options?: any) {
    return HouseFloorsApiFp(this.configuration)
      .getSaveFloorCommand(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HouseFloorsApi
   */
  public getSaveRoomCommand(id: number, options?: any) {
    return HouseFloorsApiFp(this.configuration)
      .getSaveRoomCommand(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {SaveFloorFormCommand} [saveFloorFormCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HouseFloorsApi
   */
  public saveFloor(saveFloorFormCommand?: SaveFloorFormCommand, options?: any) {
    return HouseFloorsApiFp(this.configuration)
      .saveFloor(saveFloorFormCommand, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {SaveRoomFormCommand} [saveRoomFormCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HouseFloorsApi
   */
  public saveRoom(saveRoomFormCommand?: SaveRoomFormCommand, options?: any) {
    return HouseFloorsApiFp(this.configuration)
      .saveRoom(saveRoomFormCommand, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * QuestionnairesApi - axios parameter creator
 * @export
 */
export const QuestionnairesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create New Questionnaire Version
     * @param {number} id Questionnaire Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createNewQuestionnaireVersion: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('createNewQuestionnaireVersion', 'id', id);
      const localVarPath = `/api/v1/questionnaires/new/version/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete Question
     * @param {number} id Question Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteQuestion: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteQuestion', 'id', id);
      const localVarPath = `/api/v1/questionnaires/question/delete/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete Question category
     * @param {number} id Question category Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteQuestionCategory: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteQuestionCategory', 'id', id);
      const localVarPath = `/api/v1/questionnaires/question/category/delete/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete Question group
     * @param {number} id Question group Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteQuestionGroup: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteQuestionGroup', 'id', id);
      const localVarPath = `/api/v1/questionnaires/question/group/delete/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete Question SubCategory
     * @param {number} id Question SubCategory Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteQuestionSubCategory: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteQuestionSubCategory', 'id', id);
      const localVarPath = `/api/v1/questionnaires/question/subCategory/delete/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete Questionnaire
     * @param {number} id Questionnaire Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteQuestionnaire: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteQuestionnaire', 'id', id);
      const localVarPath = `/api/v1/questionnaires/delete/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Filters questionnaire.
     * @param {FilterQuestionnairesQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterQuestionnaires: async (query?: FilterQuestionnairesQuery, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/questionnaires/filter`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get question categories in group
     * @param {number} groupId Group Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCategoriesInGroup: async (groupId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists('getCategoriesInGroup', 'groupId', groupId);
      const localVarPath = `/api/v1/questionnaires/question/categories/{groupId}`.replace(
        `{${'groupId'}}`,
        encodeURIComponent(String(groupId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get users existing answers
     * @param {number} groupId
     * @param {number} questionnaireVersionCompanyUserId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCompanyAnswersInQuestionnaire: async (
      groupId: number,
      questionnaireVersionCompanyUserId: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists('getCompanyAnswersInQuestionnaire', 'groupId', groupId);
      // verify required parameter 'questionnaireVersionCompanyUserId' is not null or undefined
      assertParamExists(
        'getCompanyAnswersInQuestionnaire',
        'questionnaireVersionCompanyUserId',
        questionnaireVersionCompanyUserId,
      );
      const localVarPath = `/api/v1/questionnaires/{questionnaireVersionCompanyUserId}/user-answer/{groupId}/group`
        .replace(`{${'groupId'}}`, encodeURIComponent(String(groupId)))
        .replace(
          `{${'questionnaireVersionCompanyUserId'}}`,
          encodeURIComponent(String(questionnaireVersionCompanyUserId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get groups belonging to questionnaire
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCompanyGroupsInQuestionnaire: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getCompanyGroupsInQuestionnaire', 'id', id);
      const localVarPath = `/api/v1/questionnaires/user/get/groups/{id}/questionnaire`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Questionnaire Group
     * @param {number} questionnaireVersionId
     * @param {number} groupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCompanyQuestionnaireWithGroup: async (
      questionnaireVersionId: number,
      groupId: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'questionnaireVersionId' is not null or undefined
      assertParamExists('getCompanyQuestionnaireWithGroup', 'questionnaireVersionId', questionnaireVersionId);
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists('getCompanyQuestionnaireWithGroup', 'groupId', groupId);
      const localVarPath = `/api/v1/questionnaires/user/{questionnaireVersionId}/group/{groupId}`
        .replace(`{${'questionnaireVersionId'}}`, encodeURIComponent(String(questionnaireVersionId)))
        .replace(`{${'groupId'}}`, encodeURIComponent(String(groupId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all available questionnaires assigned to company/user
     * @param {GetCompanyQuestionnairesQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCompanyQuestionnaires: async (
      query?: GetCompanyQuestionnairesQuery,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/questionnaires/user/questionnaires`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Save Questionnaire Command
     * @param {number} id Questionnaire Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGroupsInQuestionnaire: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getGroupsInQuestionnaire', 'id', id);
      const localVarPath = `/api/v1/questionnaires/groups/{id}/questionnaire`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get question form lookups
     * @param {GetQuestionSaveFormLookupsQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getQuestionSaveFormLookups: async (
      query?: GetQuestionSaveFormLookupsQuery,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/questionnaires/question/lookups`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get questionnaire group
     * @param {number} questionnaireId Questionnaire Id.
     * @param {number} groupId Group Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getQuestionnaireGroups: async (
      questionnaireId: number,
      groupId: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'questionnaireId' is not null or undefined
      assertParamExists('getQuestionnaireGroups', 'questionnaireId', questionnaireId);
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists('getQuestionnaireGroups', 'groupId', groupId);
      const localVarPath = `/api/v1/questionnaires/{questionnaireId}/group/{groupId}`
        .replace(`{${'questionnaireId'}}`, encodeURIComponent(String(questionnaireId)))
        .replace(`{${'groupId'}}`, encodeURIComponent(String(groupId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get the submission status of a Questionnaire Version a user is answering.
     * @param {number} questionnaireVersCompanyUserId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getQuestionnaireSubmissionStatus: async (
      questionnaireVersCompanyUserId: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'questionnaireVersCompanyUserId' is not null or undefined
      assertParamExists(
        'getQuestionnaireSubmissionStatus',
        'questionnaireVersCompanyUserId',
        questionnaireVersCompanyUserId,
      );
      const localVarPath = `/api/v1/questionnaires/submissionStatus/{questionnaireVersCompanyUserId}`.replace(
        `{${'questionnaireVersCompanyUserId'}}`,
        encodeURIComponent(String(questionnaireVersCompanyUserId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get questions
     * @param {GetQuestionsInParentQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getQuestionsInParent: async (query?: GetQuestionsInParentQuery, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/questionnaires/questions/parent`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Save Questionnaire Command
     * @param {number} id Questionnaire Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSaveQuestionCategoryCommand: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getSaveQuestionCategoryCommand', 'id', id);
      const localVarPath = `/api/v1/questionnaires/save/question/category/command/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Save Questionnaire Command
     * @param {number} id Questionnaire Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSaveQuestionGroupCommand: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getSaveQuestionGroupCommand', 'id', id);
      const localVarPath = `/api/v1/questionnaires/save/question/group/command/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Save Question SubCategory Command
     * @param {number} id Question SubCategory Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSaveQuestionSubCategoryCommand: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getSaveQuestionSubCategoryCommand', 'id', id);
      const localVarPath = `/api/v1/questionnaires/save/question/subCategory/command/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Save Questionnaire Command
     * @param {number} id Questionnaire Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSaveQuestionnaireCommand: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getSaveQuestionnaireCommand', 'id', id);
      const localVarPath = `/api/v1/questionnaires/save/command/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Save Questionnaire lookups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSaveQuestionnaireLookups: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/questionnaires/save/lookups`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get question sub categories in category
     * @param {number} categoryId category Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSubCategoriesInCategory: async (categoryId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'categoryId' is not null or undefined
      assertParamExists('getSubCategoriesInCategory', 'categoryId', categoryId);
      const localVarPath = `/api/v1/questionnaires/question/subCategories/{categoryId}`.replace(
        `{${'categoryId'}}`,
        encodeURIComponent(String(categoryId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns number of questions and number answered for a company user questinnaire
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserAnswersProgress: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getUserAnswersProgress', 'id', id);
      const localVarPath = `/api/v1/questionnaires/userprogress/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Publish Questionnaire
     * @param {number} id Questionnaire Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publishQuestionnaire: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('publishQuestionnaire', 'id', id);
      const localVarPath = `/api/v1/questionnaires/publish/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Re-order questions
     * @param {number} id Question Id.
     * @param {number} order Question new Order.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reorderQuestion: async (id: number, order: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('reorderQuestion', 'id', id);
      // verify required parameter 'order' is not null or undefined
      assertParamExists('reorderQuestion', 'order', order);
      const localVarPath = `/api/v1/questionnaires/reorder/question/{id}/{order}`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'order'}}`, encodeURIComponent(String(order)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Re-order question categories
     * @param {number} id Question Category Id.
     * @param {number} order Question Category new Order.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reorderQuestionCategory: async (id: number, order: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('reorderQuestionCategory', 'id', id);
      // verify required parameter 'order' is not null or undefined
      assertParamExists('reorderQuestionCategory', 'order', order);
      const localVarPath = `/api/v1/questionnaires/reorder/question/category/{id}/{order}`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'order'}}`, encodeURIComponent(String(order)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Re-order question groups
     * @param {number} id Question group Id.
     * @param {number} order Question group new Order.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reorderQuestionGroup: async (id: number, order: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('reorderQuestionGroup', 'id', id);
      // verify required parameter 'order' is not null or undefined
      assertParamExists('reorderQuestionGroup', 'order', order);
      const localVarPath = `/api/v1/questionnaires/reorder/question/group/{id}/{order}`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'order'}}`, encodeURIComponent(String(order)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Re-order question sub-categories
     * @param {number} id Question Sub Category Id.
     * @param {number} order Question Sub Category new Order.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reorderQuestionSubCategory: async (id: number, order: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('reorderQuestionSubCategory', 'id', id);
      // verify required parameter 'order' is not null or undefined
      assertParamExists('reorderQuestionSubCategory', 'order', order);
      const localVarPath = `/api/v1/questionnaires/reorder/question/subCategory/{id}/{order}`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'order'}}`, encodeURIComponent(String(order)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Saves a user\'s answers
     * @param {SaveQuestionAnswersCommand} [saveQuestionAnswersCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveQuestionAnswers: async (
      saveQuestionAnswersCommand?: SaveQuestionAnswersCommand,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/questionnaires/save/question/answer`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        saveQuestionAnswersCommand,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Save questionnaire
     * @param {SaveQuestionCategoryFormCommand} [saveQuestionCategoryFormCommand] Question Group command.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveQuestionCategoryForm: async (
      saveQuestionCategoryFormCommand?: SaveQuestionCategoryFormCommand,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/questionnaires/save/question/category`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        saveQuestionCategoryFormCommand,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Save question
     * @param {SaveQuestionFormCommand} [saveQuestionFormCommand] Question command.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveQuestionForm: async (
      saveQuestionFormCommand?: SaveQuestionFormCommand,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/questionnaires/save/question`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        saveQuestionFormCommand,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Save questionnaire
     * @param {SaveQuestionGroupFormCommand} [saveQuestionGroupFormCommand] Question Group command.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveQuestionGroupForm: async (
      saveQuestionGroupFormCommand?: SaveQuestionGroupFormCommand,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/questionnaires/save/question/group`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        saveQuestionGroupFormCommand,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Save questionnaire
     * @param {SaveQuestionSubCategoryFormCommand} [saveQuestionSubCategoryFormCommand] Question Group command.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveQuestionSubCategoryForm: async (
      saveQuestionSubCategoryFormCommand?: SaveQuestionSubCategoryFormCommand,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/questionnaires/save/question/subCategory`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        saveQuestionSubCategoryFormCommand,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Save questionnaire
     * @param {SaveQuestionnaireFormCommand} [saveQuestionnaireFormCommand] Questionnaire version command.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveQuestionnaireForm: async (
      saveQuestionnaireFormCommand?: SaveQuestionnaireFormCommand,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/questionnaires/save`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        saveQuestionnaireFormCommand,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update question
     * @param {UpdateQuestionFormCommand} [updateQuestionFormCommand] Question command.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateQuestionForm: async (
      updateQuestionFormCommand?: UpdateQuestionFormCommand,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/questionnaires/update/question`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateQuestionFormCommand,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * QuestionnairesApi - functional programming interface
 * @export
 */
export const QuestionnairesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = QuestionnairesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Create New Questionnaire Version
     * @param {number} id Questionnaire Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createNewQuestionnaireVersion(
      id: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createNewQuestionnaireVersion(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Delete Question
     * @param {number} id Question Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteQuestion(
      id: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteQuestion(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Delete Question category
     * @param {number} id Question category Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteQuestionCategory(
      id: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteQuestionCategory(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Delete Question group
     * @param {number} id Question group Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteQuestionGroup(
      id: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteQuestionGroup(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Delete Question SubCategory
     * @param {number} id Question SubCategory Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteQuestionSubCategory(
      id: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteQuestionSubCategory(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Delete Questionnaire
     * @param {number} id Questionnaire Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteQuestionnaire(
      id: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteQuestionnaire(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Filters questionnaire.
     * @param {FilterQuestionnairesQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async filterQuestionnaires(
      query?: FilterQuestionnairesQuery,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FilterQuestionnairesModelGridCollection>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.filterQuestionnaires(query, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get question categories in group
     * @param {number} groupId Group Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCategoriesInGroup(
      groupId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCategoriesInGroupModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCategoriesInGroup(groupId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get users existing answers
     * @param {number} groupId
     * @param {number} questionnaireVersionCompanyUserId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCompanyAnswersInQuestionnaire(
      groupId: number,
      questionnaireVersionCompanyUserId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCompanyAnswersInQuestionnaireModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyAnswersInQuestionnaire(
        groupId,
        questionnaireVersionCompanyUserId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get groups belonging to questionnaire
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCompanyGroupsInQuestionnaire(
      id: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCompanyGroupsInQuestionnaireModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyGroupsInQuestionnaire(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get Questionnaire Group
     * @param {number} questionnaireVersionId
     * @param {number} groupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCompanyQuestionnaireWithGroup(
      questionnaireVersionId: number,
      groupId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionnaireVersionModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyQuestionnaireWithGroup(
        questionnaireVersionId,
        groupId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get all available questionnaires assigned to company/user
     * @param {GetCompanyQuestionnairesQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCompanyQuestionnaires(
      query?: GetCompanyQuestionnairesQuery,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCompanyQuestionnairesModelGridCollection>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyQuestionnaires(query, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get Save Questionnaire Command
     * @param {number} id Questionnaire Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getGroupsInQuestionnaire(
      id: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetGroupsInQuestionnaireModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getGroupsInQuestionnaire(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get question form lookups
     * @param {GetQuestionSaveFormLookupsQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getQuestionSaveFormLookups(
      query?: GetQuestionSaveFormLookupsQuery,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetQuestionSaveFormLookupsModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getQuestionSaveFormLookups(query, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get questionnaire group
     * @param {number} questionnaireId Questionnaire Id.
     * @param {number} groupId Group Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getQuestionnaireGroups(
      questionnaireId: number,
      groupId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionnaireVersionModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getQuestionnaireGroups(
        questionnaireId,
        groupId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get the submission status of a Questionnaire Version a user is answering.
     * @param {number} questionnaireVersCompanyUserId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getQuestionnaireSubmissionStatus(
      questionnaireVersCompanyUserId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getQuestionnaireSubmissionStatus(
        questionnaireVersCompanyUserId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get questions
     * @param {GetQuestionsInParentQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getQuestionsInParent(
      query?: GetQuestionsInParentQuery,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetQuestionsInParentModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getQuestionsInParent(query, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get Save Questionnaire Command
     * @param {number} id Questionnaire Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSaveQuestionCategoryCommand(
      id: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveQuestionCategoryFormCommand>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSaveQuestionCategoryCommand(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get Save Questionnaire Command
     * @param {number} id Questionnaire Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSaveQuestionGroupCommand(
      id: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveQuestionGroupFormCommand>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSaveQuestionGroupCommand(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get Save Question SubCategory Command
     * @param {number} id Question SubCategory Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSaveQuestionSubCategoryCommand(
      id: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveQuestionSubCategoryFormCommand>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSaveQuestionSubCategoryCommand(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get Save Questionnaire Command
     * @param {number} id Questionnaire Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSaveQuestionnaireCommand(
      id: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveQuestionnaireFormCommand>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSaveQuestionnaireCommand(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get Save Questionnaire lookups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSaveQuestionnaireLookups(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSaveQuestionnaireLookupsModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSaveQuestionnaireLookups(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get question sub categories in category
     * @param {number} categoryId category Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSubCategoriesInCategory(
      categoryId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSubCategoriesInCategoryModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSubCategoriesInCategory(categoryId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Returns number of questions and number answered for a company user questinnaire
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUserAnswersProgress(
      id: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetQuestionnaireUserProgressModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUserAnswersProgress(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Publish Questionnaire
     * @param {number} id Questionnaire Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async publishQuestionnaire(
      id: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.publishQuestionnaire(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Re-order questions
     * @param {number} id Question Id.
     * @param {number} order Question new Order.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async reorderQuestion(
      id: number,
      order: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.reorderQuestion(id, order, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Re-order question categories
     * @param {number} id Question Category Id.
     * @param {number} order Question Category new Order.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async reorderQuestionCategory(
      id: number,
      order: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.reorderQuestionCategory(id, order, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Re-order question groups
     * @param {number} id Question group Id.
     * @param {number} order Question group new Order.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async reorderQuestionGroup(
      id: number,
      order: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.reorderQuestionGroup(id, order, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Re-order question sub-categories
     * @param {number} id Question Sub Category Id.
     * @param {number} order Question Sub Category new Order.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async reorderQuestionSubCategory(
      id: number,
      order: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.reorderQuestionSubCategory(id, order, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Saves a user\'s answers
     * @param {SaveQuestionAnswersCommand} [saveQuestionAnswersCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saveQuestionAnswers(
      saveQuestionAnswersCommand?: SaveQuestionAnswersCommand,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saveQuestionAnswers(
        saveQuestionAnswersCommand,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Save questionnaire
     * @param {SaveQuestionCategoryFormCommand} [saveQuestionCategoryFormCommand] Question Group command.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saveQuestionCategoryForm(
      saveQuestionCategoryFormCommand?: SaveQuestionCategoryFormCommand,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saveQuestionCategoryForm(
        saveQuestionCategoryFormCommand,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Save question
     * @param {SaveQuestionFormCommand} [saveQuestionFormCommand] Question command.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saveQuestionForm(
      saveQuestionFormCommand?: SaveQuestionFormCommand,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saveQuestionForm(saveQuestionFormCommand, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Save questionnaire
     * @param {SaveQuestionGroupFormCommand} [saveQuestionGroupFormCommand] Question Group command.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saveQuestionGroupForm(
      saveQuestionGroupFormCommand?: SaveQuestionGroupFormCommand,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saveQuestionGroupForm(
        saveQuestionGroupFormCommand,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Save questionnaire
     * @param {SaveQuestionSubCategoryFormCommand} [saveQuestionSubCategoryFormCommand] Question Group command.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saveQuestionSubCategoryForm(
      saveQuestionSubCategoryFormCommand?: SaveQuestionSubCategoryFormCommand,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saveQuestionSubCategoryForm(
        saveQuestionSubCategoryFormCommand,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Save questionnaire
     * @param {SaveQuestionnaireFormCommand} [saveQuestionnaireFormCommand] Questionnaire version command.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saveQuestionnaireForm(
      saveQuestionnaireFormCommand?: SaveQuestionnaireFormCommand,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saveQuestionnaireForm(
        saveQuestionnaireFormCommand,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update question
     * @param {UpdateQuestionFormCommand} [updateQuestionFormCommand] Question command.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateQuestionForm(
      updateQuestionFormCommand?: UpdateQuestionFormCommand,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateQuestionForm(updateQuestionFormCommand, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * QuestionnairesApi - factory interface
 * @export
 */
export const QuestionnairesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = QuestionnairesApiFp(configuration);
  return {
    /**
     *
     * @summary Create New Questionnaire Version
     * @param {number} id Questionnaire Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createNewQuestionnaireVersion(id: number, options?: any): AxiosPromise<number> {
      return localVarFp.createNewQuestionnaireVersion(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete Question
     * @param {number} id Question Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteQuestion(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteQuestion(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete Question category
     * @param {number} id Question category Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteQuestionCategory(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteQuestionCategory(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete Question group
     * @param {number} id Question group Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteQuestionGroup(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteQuestionGroup(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete Question SubCategory
     * @param {number} id Question SubCategory Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteQuestionSubCategory(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteQuestionSubCategory(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete Questionnaire
     * @param {number} id Questionnaire Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteQuestionnaire(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteQuestionnaire(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Filters questionnaire.
     * @param {FilterQuestionnairesQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterQuestionnaires(
      query?: FilterQuestionnairesQuery,
      options?: any,
    ): AxiosPromise<FilterQuestionnairesModelGridCollection> {
      return localVarFp.filterQuestionnaires(query, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get question categories in group
     * @param {number} groupId Group Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCategoriesInGroup(groupId: number, options?: any): AxiosPromise<GetCategoriesInGroupModel> {
      return localVarFp.getCategoriesInGroup(groupId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get users existing answers
     * @param {number} groupId
     * @param {number} questionnaireVersionCompanyUserId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCompanyAnswersInQuestionnaire(
      groupId: number,
      questionnaireVersionCompanyUserId: number,
      options?: any,
    ): AxiosPromise<GetCompanyAnswersInQuestionnaireModel> {
      return localVarFp
        .getCompanyAnswersInQuestionnaire(groupId, questionnaireVersionCompanyUserId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get groups belonging to questionnaire
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCompanyGroupsInQuestionnaire(id: number, options?: any): AxiosPromise<GetCompanyGroupsInQuestionnaireModel> {
      return localVarFp.getCompanyGroupsInQuestionnaire(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Questionnaire Group
     * @param {number} questionnaireVersionId
     * @param {number} groupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCompanyQuestionnaireWithGroup(
      questionnaireVersionId: number,
      groupId: number,
      options?: any,
    ): AxiosPromise<QuestionnaireVersionModel> {
      return localVarFp
        .getCompanyQuestionnaireWithGroup(questionnaireVersionId, groupId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get all available questionnaires assigned to company/user
     * @param {GetCompanyQuestionnairesQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCompanyQuestionnaires(
      query?: GetCompanyQuestionnairesQuery,
      options?: any,
    ): AxiosPromise<GetCompanyQuestionnairesModelGridCollection> {
      return localVarFp.getCompanyQuestionnaires(query, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Save Questionnaire Command
     * @param {number} id Questionnaire Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGroupsInQuestionnaire(id: number, options?: any): AxiosPromise<GetGroupsInQuestionnaireModel> {
      return localVarFp.getGroupsInQuestionnaire(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get question form lookups
     * @param {GetQuestionSaveFormLookupsQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getQuestionSaveFormLookups(
      query?: GetQuestionSaveFormLookupsQuery,
      options?: any,
    ): AxiosPromise<GetQuestionSaveFormLookupsModel> {
      return localVarFp.getQuestionSaveFormLookups(query, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get questionnaire group
     * @param {number} questionnaireId Questionnaire Id.
     * @param {number} groupId Group Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getQuestionnaireGroups(
      questionnaireId: number,
      groupId: number,
      options?: any,
    ): AxiosPromise<QuestionnaireVersionModel> {
      return localVarFp
        .getQuestionnaireGroups(questionnaireId, groupId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get the submission status of a Questionnaire Version a user is answering.
     * @param {number} questionnaireVersCompanyUserId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getQuestionnaireSubmissionStatus(questionnaireVersCompanyUserId: number, options?: any): AxiosPromise<number> {
      return localVarFp
        .getQuestionnaireSubmissionStatus(questionnaireVersCompanyUserId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get questions
     * @param {GetQuestionsInParentQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getQuestionsInParent(query?: GetQuestionsInParentQuery, options?: any): AxiosPromise<GetQuestionsInParentModel> {
      return localVarFp.getQuestionsInParent(query, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Save Questionnaire Command
     * @param {number} id Questionnaire Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSaveQuestionCategoryCommand(id: number, options?: any): AxiosPromise<SaveQuestionCategoryFormCommand> {
      return localVarFp.getSaveQuestionCategoryCommand(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Save Questionnaire Command
     * @param {number} id Questionnaire Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSaveQuestionGroupCommand(id: number, options?: any): AxiosPromise<SaveQuestionGroupFormCommand> {
      return localVarFp.getSaveQuestionGroupCommand(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Save Question SubCategory Command
     * @param {number} id Question SubCategory Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSaveQuestionSubCategoryCommand(id: number, options?: any): AxiosPromise<SaveQuestionSubCategoryFormCommand> {
      return localVarFp.getSaveQuestionSubCategoryCommand(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Save Questionnaire Command
     * @param {number} id Questionnaire Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSaveQuestionnaireCommand(id: number, options?: any): AxiosPromise<SaveQuestionnaireFormCommand> {
      return localVarFp.getSaveQuestionnaireCommand(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Save Questionnaire lookups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSaveQuestionnaireLookups(options?: any): AxiosPromise<GetSaveQuestionnaireLookupsModel> {
      return localVarFp.getSaveQuestionnaireLookups(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get question sub categories in category
     * @param {number} categoryId category Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSubCategoriesInCategory(categoryId: number, options?: any): AxiosPromise<GetSubCategoriesInCategoryModel> {
      return localVarFp.getSubCategoriesInCategory(categoryId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Returns number of questions and number answered for a company user questinnaire
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserAnswersProgress(id: number, options?: any): AxiosPromise<GetQuestionnaireUserProgressModel> {
      return localVarFp.getUserAnswersProgress(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Publish Questionnaire
     * @param {number} id Questionnaire Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publishQuestionnaire(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.publishQuestionnaire(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Re-order questions
     * @param {number} id Question Id.
     * @param {number} order Question new Order.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reorderQuestion(id: number, order: number, options?: any): AxiosPromise<void> {
      return localVarFp.reorderQuestion(id, order, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Re-order question categories
     * @param {number} id Question Category Id.
     * @param {number} order Question Category new Order.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reorderQuestionCategory(id: number, order: number, options?: any): AxiosPromise<void> {
      return localVarFp.reorderQuestionCategory(id, order, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Re-order question groups
     * @param {number} id Question group Id.
     * @param {number} order Question group new Order.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reorderQuestionGroup(id: number, order: number, options?: any): AxiosPromise<void> {
      return localVarFp.reorderQuestionGroup(id, order, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Re-order question sub-categories
     * @param {number} id Question Sub Category Id.
     * @param {number} order Question Sub Category new Order.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reorderQuestionSubCategory(id: number, order: number, options?: any): AxiosPromise<void> {
      return localVarFp.reorderQuestionSubCategory(id, order, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Saves a user\'s answers
     * @param {SaveQuestionAnswersCommand} [saveQuestionAnswersCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveQuestionAnswers(saveQuestionAnswersCommand?: SaveQuestionAnswersCommand, options?: any): AxiosPromise<number> {
      return localVarFp
        .saveQuestionAnswers(saveQuestionAnswersCommand, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Save questionnaire
     * @param {SaveQuestionCategoryFormCommand} [saveQuestionCategoryFormCommand] Question Group command.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveQuestionCategoryForm(
      saveQuestionCategoryFormCommand?: SaveQuestionCategoryFormCommand,
      options?: any,
    ): AxiosPromise<number> {
      return localVarFp
        .saveQuestionCategoryForm(saveQuestionCategoryFormCommand, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Save question
     * @param {SaveQuestionFormCommand} [saveQuestionFormCommand] Question command.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveQuestionForm(saveQuestionFormCommand?: SaveQuestionFormCommand, options?: any): AxiosPromise<number> {
      return localVarFp.saveQuestionForm(saveQuestionFormCommand, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Save questionnaire
     * @param {SaveQuestionGroupFormCommand} [saveQuestionGroupFormCommand] Question Group command.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveQuestionGroupForm(
      saveQuestionGroupFormCommand?: SaveQuestionGroupFormCommand,
      options?: any,
    ): AxiosPromise<number> {
      return localVarFp
        .saveQuestionGroupForm(saveQuestionGroupFormCommand, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Save questionnaire
     * @param {SaveQuestionSubCategoryFormCommand} [saveQuestionSubCategoryFormCommand] Question Group command.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveQuestionSubCategoryForm(
      saveQuestionSubCategoryFormCommand?: SaveQuestionSubCategoryFormCommand,
      options?: any,
    ): AxiosPromise<number> {
      return localVarFp
        .saveQuestionSubCategoryForm(saveQuestionSubCategoryFormCommand, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Save questionnaire
     * @param {SaveQuestionnaireFormCommand} [saveQuestionnaireFormCommand] Questionnaire version command.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveQuestionnaireForm(
      saveQuestionnaireFormCommand?: SaveQuestionnaireFormCommand,
      options?: any,
    ): AxiosPromise<number> {
      return localVarFp
        .saveQuestionnaireForm(saveQuestionnaireFormCommand, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update question
     * @param {UpdateQuestionFormCommand} [updateQuestionFormCommand] Question command.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateQuestionForm(updateQuestionFormCommand?: UpdateQuestionFormCommand, options?: any): AxiosPromise<number> {
      return localVarFp
        .updateQuestionForm(updateQuestionFormCommand, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * QuestionnairesApi - interface
 * @export
 * @interface QuestionnairesApi
 */
export interface QuestionnairesApiInterface {
  /**
   *
   * @summary Create New Questionnaire Version
   * @param {number} id Questionnaire Id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApiInterface
   */
  createNewQuestionnaireVersion(id: number, options?: any): AxiosPromise<number>;

  /**
   *
   * @summary Delete Question
   * @param {number} id Question Id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApiInterface
   */
  deleteQuestion(id: number, options?: any): AxiosPromise<void>;

  /**
   *
   * @summary Delete Question category
   * @param {number} id Question category Id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApiInterface
   */
  deleteQuestionCategory(id: number, options?: any): AxiosPromise<void>;

  /**
   *
   * @summary Delete Question group
   * @param {number} id Question group Id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApiInterface
   */
  deleteQuestionGroup(id: number, options?: any): AxiosPromise<void>;

  /**
   *
   * @summary Delete Question SubCategory
   * @param {number} id Question SubCategory Id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApiInterface
   */
  deleteQuestionSubCategory(id: number, options?: any): AxiosPromise<void>;

  /**
   *
   * @summary Delete Questionnaire
   * @param {number} id Questionnaire Id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApiInterface
   */
  deleteQuestionnaire(id: number, options?: any): AxiosPromise<void>;

  /**
   *
   * @summary Filters questionnaire.
   * @param {FilterQuestionnairesQuery} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApiInterface
   */
  filterQuestionnaires(
    query?: FilterQuestionnairesQuery,
    options?: any,
  ): AxiosPromise<FilterQuestionnairesModelGridCollection>;

  /**
   *
   * @summary Get question categories in group
   * @param {number} groupId Group Id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApiInterface
   */
  getCategoriesInGroup(groupId: number, options?: any): AxiosPromise<GetCategoriesInGroupModel>;

  /**
   *
   * @summary Get users existing answers
   * @param {number} groupId
   * @param {number} questionnaireVersionCompanyUserId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApiInterface
   */
  getCompanyAnswersInQuestionnaire(
    groupId: number,
    questionnaireVersionCompanyUserId: number,
    options?: any,
  ): AxiosPromise<GetCompanyAnswersInQuestionnaireModel>;

  /**
   *
   * @summary Get groups belonging to questionnaire
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApiInterface
   */
  getCompanyGroupsInQuestionnaire(id: number, options?: any): AxiosPromise<GetCompanyGroupsInQuestionnaireModel>;

  /**
   *
   * @summary Get Questionnaire Group
   * @param {number} questionnaireVersionId
   * @param {number} groupId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApiInterface
   */
  getCompanyQuestionnaireWithGroup(
    questionnaireVersionId: number,
    groupId: number,
    options?: any,
  ): AxiosPromise<QuestionnaireVersionModel>;

  /**
   *
   * @summary Get all available questionnaires assigned to company/user
   * @param {GetCompanyQuestionnairesQuery} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApiInterface
   */
  getCompanyQuestionnaires(
    query?: GetCompanyQuestionnairesQuery,
    options?: any,
  ): AxiosPromise<GetCompanyQuestionnairesModelGridCollection>;

  /**
   *
   * @summary Get Save Questionnaire Command
   * @param {number} id Questionnaire Id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApiInterface
   */
  getGroupsInQuestionnaire(id: number, options?: any): AxiosPromise<GetGroupsInQuestionnaireModel>;

  /**
   *
   * @summary Get question form lookups
   * @param {GetQuestionSaveFormLookupsQuery} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApiInterface
   */
  getQuestionSaveFormLookups(
    query?: GetQuestionSaveFormLookupsQuery,
    options?: any,
  ): AxiosPromise<GetQuestionSaveFormLookupsModel>;

  /**
   *
   * @summary Get questionnaire group
   * @param {number} questionnaireId Questionnaire Id.
   * @param {number} groupId Group Id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApiInterface
   */
  getQuestionnaireGroups(
    questionnaireId: number,
    groupId: number,
    options?: any,
  ): AxiosPromise<QuestionnaireVersionModel>;

  /**
   *
   * @summary Get the submission status of a Questionnaire Version a user is answering.
   * @param {number} questionnaireVersCompanyUserId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApiInterface
   */
  getQuestionnaireSubmissionStatus(questionnaireVersCompanyUserId: number, options?: any): AxiosPromise<number>;

  /**
   *
   * @summary Get questions
   * @param {GetQuestionsInParentQuery} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApiInterface
   */
  getQuestionsInParent(query?: GetQuestionsInParentQuery, options?: any): AxiosPromise<GetQuestionsInParentModel>;

  /**
   *
   * @summary Get Save Questionnaire Command
   * @param {number} id Questionnaire Id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApiInterface
   */
  getSaveQuestionCategoryCommand(id: number, options?: any): AxiosPromise<SaveQuestionCategoryFormCommand>;

  /**
   *
   * @summary Get Save Questionnaire Command
   * @param {number} id Questionnaire Id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApiInterface
   */
  getSaveQuestionGroupCommand(id: number, options?: any): AxiosPromise<SaveQuestionGroupFormCommand>;

  /**
   *
   * @summary Get Save Question SubCategory Command
   * @param {number} id Question SubCategory Id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApiInterface
   */
  getSaveQuestionSubCategoryCommand(id: number, options?: any): AxiosPromise<SaveQuestionSubCategoryFormCommand>;

  /**
   *
   * @summary Get Save Questionnaire Command
   * @param {number} id Questionnaire Id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApiInterface
   */
  getSaveQuestionnaireCommand(id: number, options?: any): AxiosPromise<SaveQuestionnaireFormCommand>;

  /**
   *
   * @summary Get Save Questionnaire lookups
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApiInterface
   */
  getSaveQuestionnaireLookups(options?: any): AxiosPromise<GetSaveQuestionnaireLookupsModel>;

  /**
   *
   * @summary Get question sub categories in category
   * @param {number} categoryId category Id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApiInterface
   */
  getSubCategoriesInCategory(categoryId: number, options?: any): AxiosPromise<GetSubCategoriesInCategoryModel>;

  /**
   *
   * @summary Returns number of questions and number answered for a company user questinnaire
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApiInterface
   */
  getUserAnswersProgress(id: number, options?: any): AxiosPromise<GetQuestionnaireUserProgressModel>;

  /**
   *
   * @summary Publish Questionnaire
   * @param {number} id Questionnaire Id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApiInterface
   */
  publishQuestionnaire(id: number, options?: any): AxiosPromise<void>;

  /**
   *
   * @summary Re-order questions
   * @param {number} id Question Id.
   * @param {number} order Question new Order.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApiInterface
   */
  reorderQuestion(id: number, order: number, options?: any): AxiosPromise<void>;

  /**
   *
   * @summary Re-order question categories
   * @param {number} id Question Category Id.
   * @param {number} order Question Category new Order.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApiInterface
   */
  reorderQuestionCategory(id: number, order: number, options?: any): AxiosPromise<void>;

  /**
   *
   * @summary Re-order question groups
   * @param {number} id Question group Id.
   * @param {number} order Question group new Order.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApiInterface
   */
  reorderQuestionGroup(id: number, order: number, options?: any): AxiosPromise<void>;

  /**
   *
   * @summary Re-order question sub-categories
   * @param {number} id Question Sub Category Id.
   * @param {number} order Question Sub Category new Order.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApiInterface
   */
  reorderQuestionSubCategory(id: number, order: number, options?: any): AxiosPromise<void>;

  /**
   *
   * @summary Saves a user\'s answers
   * @param {SaveQuestionAnswersCommand} [saveQuestionAnswersCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApiInterface
   */
  saveQuestionAnswers(saveQuestionAnswersCommand?: SaveQuestionAnswersCommand, options?: any): AxiosPromise<number>;

  /**
   *
   * @summary Save questionnaire
   * @param {SaveQuestionCategoryFormCommand} [saveQuestionCategoryFormCommand] Question Group command.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApiInterface
   */
  saveQuestionCategoryForm(
    saveQuestionCategoryFormCommand?: SaveQuestionCategoryFormCommand,
    options?: any,
  ): AxiosPromise<number>;

  /**
   *
   * @summary Save question
   * @param {SaveQuestionFormCommand} [saveQuestionFormCommand] Question command.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApiInterface
   */
  saveQuestionForm(saveQuestionFormCommand?: SaveQuestionFormCommand, options?: any): AxiosPromise<number>;

  /**
   *
   * @summary Save questionnaire
   * @param {SaveQuestionGroupFormCommand} [saveQuestionGroupFormCommand] Question Group command.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApiInterface
   */
  saveQuestionGroupForm(
    saveQuestionGroupFormCommand?: SaveQuestionGroupFormCommand,
    options?: any,
  ): AxiosPromise<number>;

  /**
   *
   * @summary Save questionnaire
   * @param {SaveQuestionSubCategoryFormCommand} [saveQuestionSubCategoryFormCommand] Question Group command.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApiInterface
   */
  saveQuestionSubCategoryForm(
    saveQuestionSubCategoryFormCommand?: SaveQuestionSubCategoryFormCommand,
    options?: any,
  ): AxiosPromise<number>;

  /**
   *
   * @summary Save questionnaire
   * @param {SaveQuestionnaireFormCommand} [saveQuestionnaireFormCommand] Questionnaire version command.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApiInterface
   */
  saveQuestionnaireForm(
    saveQuestionnaireFormCommand?: SaveQuestionnaireFormCommand,
    options?: any,
  ): AxiosPromise<number>;

  /**
   *
   * @summary Update question
   * @param {UpdateQuestionFormCommand} [updateQuestionFormCommand] Question command.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApiInterface
   */
  updateQuestionForm(updateQuestionFormCommand?: UpdateQuestionFormCommand, options?: any): AxiosPromise<number>;
}

/**
 * QuestionnairesApi - object-oriented interface
 * @export
 * @class QuestionnairesApi
 * @extends {BaseAPI}
 */
export class QuestionnairesApi extends BaseAPI implements QuestionnairesApiInterface {
  /**
   *
   * @summary Create New Questionnaire Version
   * @param {number} id Questionnaire Id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApi
   */
  public createNewQuestionnaireVersion(id: number, options?: any) {
    return QuestionnairesApiFp(this.configuration)
      .createNewQuestionnaireVersion(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete Question
   * @param {number} id Question Id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApi
   */
  public deleteQuestion(id: number, options?: any) {
    return QuestionnairesApiFp(this.configuration)
      .deleteQuestion(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete Question category
   * @param {number} id Question category Id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApi
   */
  public deleteQuestionCategory(id: number, options?: any) {
    return QuestionnairesApiFp(this.configuration)
      .deleteQuestionCategory(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete Question group
   * @param {number} id Question group Id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApi
   */
  public deleteQuestionGroup(id: number, options?: any) {
    return QuestionnairesApiFp(this.configuration)
      .deleteQuestionGroup(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete Question SubCategory
   * @param {number} id Question SubCategory Id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApi
   */
  public deleteQuestionSubCategory(id: number, options?: any) {
    return QuestionnairesApiFp(this.configuration)
      .deleteQuestionSubCategory(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete Questionnaire
   * @param {number} id Questionnaire Id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApi
   */
  public deleteQuestionnaire(id: number, options?: any) {
    return QuestionnairesApiFp(this.configuration)
      .deleteQuestionnaire(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Filters questionnaire.
   * @param {FilterQuestionnairesQuery} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApi
   */
  public filterQuestionnaires(query?: FilterQuestionnairesQuery, options?: any) {
    return QuestionnairesApiFp(this.configuration)
      .filterQuestionnaires(query, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get question categories in group
   * @param {number} groupId Group Id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApi
   */
  public getCategoriesInGroup(groupId: number, options?: any) {
    return QuestionnairesApiFp(this.configuration)
      .getCategoriesInGroup(groupId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get users existing answers
   * @param {number} groupId
   * @param {number} questionnaireVersionCompanyUserId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApi
   */
  public getCompanyAnswersInQuestionnaire(groupId: number, questionnaireVersionCompanyUserId: number, options?: any) {
    return QuestionnairesApiFp(this.configuration)
      .getCompanyAnswersInQuestionnaire(groupId, questionnaireVersionCompanyUserId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get groups belonging to questionnaire
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApi
   */
  public getCompanyGroupsInQuestionnaire(id: number, options?: any) {
    return QuestionnairesApiFp(this.configuration)
      .getCompanyGroupsInQuestionnaire(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Questionnaire Group
   * @param {number} questionnaireVersionId
   * @param {number} groupId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApi
   */
  public getCompanyQuestionnaireWithGroup(questionnaireVersionId: number, groupId: number, options?: any) {
    return QuestionnairesApiFp(this.configuration)
      .getCompanyQuestionnaireWithGroup(questionnaireVersionId, groupId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get all available questionnaires assigned to company/user
   * @param {GetCompanyQuestionnairesQuery} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApi
   */
  public getCompanyQuestionnaires(query?: GetCompanyQuestionnairesQuery, options?: any) {
    return QuestionnairesApiFp(this.configuration)
      .getCompanyQuestionnaires(query, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Save Questionnaire Command
   * @param {number} id Questionnaire Id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApi
   */
  public getGroupsInQuestionnaire(id: number, options?: any) {
    return QuestionnairesApiFp(this.configuration)
      .getGroupsInQuestionnaire(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get question form lookups
   * @param {GetQuestionSaveFormLookupsQuery} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApi
   */
  public getQuestionSaveFormLookups(query?: GetQuestionSaveFormLookupsQuery, options?: any) {
    return QuestionnairesApiFp(this.configuration)
      .getQuestionSaveFormLookups(query, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get questionnaire group
   * @param {number} questionnaireId Questionnaire Id.
   * @param {number} groupId Group Id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApi
   */
  public getQuestionnaireGroups(questionnaireId: number, groupId: number, options?: any) {
    return QuestionnairesApiFp(this.configuration)
      .getQuestionnaireGroups(questionnaireId, groupId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get the submission status of a Questionnaire Version a user is answering.
   * @param {number} questionnaireVersCompanyUserId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApi
   */
  public getQuestionnaireSubmissionStatus(questionnaireVersCompanyUserId: number, options?: any) {
    return QuestionnairesApiFp(this.configuration)
      .getQuestionnaireSubmissionStatus(questionnaireVersCompanyUserId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get questions
   * @param {GetQuestionsInParentQuery} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApi
   */
  public getQuestionsInParent(query?: GetQuestionsInParentQuery, options?: any) {
    return QuestionnairesApiFp(this.configuration)
      .getQuestionsInParent(query, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Save Questionnaire Command
   * @param {number} id Questionnaire Id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApi
   */
  public getSaveQuestionCategoryCommand(id: number, options?: any) {
    return QuestionnairesApiFp(this.configuration)
      .getSaveQuestionCategoryCommand(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Save Questionnaire Command
   * @param {number} id Questionnaire Id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApi
   */
  public getSaveQuestionGroupCommand(id: number, options?: any) {
    return QuestionnairesApiFp(this.configuration)
      .getSaveQuestionGroupCommand(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Save Question SubCategory Command
   * @param {number} id Question SubCategory Id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApi
   */
  public getSaveQuestionSubCategoryCommand(id: number, options?: any) {
    return QuestionnairesApiFp(this.configuration)
      .getSaveQuestionSubCategoryCommand(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Save Questionnaire Command
   * @param {number} id Questionnaire Id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApi
   */
  public getSaveQuestionnaireCommand(id: number, options?: any) {
    return QuestionnairesApiFp(this.configuration)
      .getSaveQuestionnaireCommand(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Save Questionnaire lookups
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApi
   */
  public getSaveQuestionnaireLookups(options?: any) {
    return QuestionnairesApiFp(this.configuration)
      .getSaveQuestionnaireLookups(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get question sub categories in category
   * @param {number} categoryId category Id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApi
   */
  public getSubCategoriesInCategory(categoryId: number, options?: any) {
    return QuestionnairesApiFp(this.configuration)
      .getSubCategoriesInCategory(categoryId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns number of questions and number answered for a company user questinnaire
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApi
   */
  public getUserAnswersProgress(id: number, options?: any) {
    return QuestionnairesApiFp(this.configuration)
      .getUserAnswersProgress(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Publish Questionnaire
   * @param {number} id Questionnaire Id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApi
   */
  public publishQuestionnaire(id: number, options?: any) {
    return QuestionnairesApiFp(this.configuration)
      .publishQuestionnaire(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Re-order questions
   * @param {number} id Question Id.
   * @param {number} order Question new Order.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApi
   */
  public reorderQuestion(id: number, order: number, options?: any) {
    return QuestionnairesApiFp(this.configuration)
      .reorderQuestion(id, order, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Re-order question categories
   * @param {number} id Question Category Id.
   * @param {number} order Question Category new Order.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApi
   */
  public reorderQuestionCategory(id: number, order: number, options?: any) {
    return QuestionnairesApiFp(this.configuration)
      .reorderQuestionCategory(id, order, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Re-order question groups
   * @param {number} id Question group Id.
   * @param {number} order Question group new Order.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApi
   */
  public reorderQuestionGroup(id: number, order: number, options?: any) {
    return QuestionnairesApiFp(this.configuration)
      .reorderQuestionGroup(id, order, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Re-order question sub-categories
   * @param {number} id Question Sub Category Id.
   * @param {number} order Question Sub Category new Order.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApi
   */
  public reorderQuestionSubCategory(id: number, order: number, options?: any) {
    return QuestionnairesApiFp(this.configuration)
      .reorderQuestionSubCategory(id, order, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Saves a user\'s answers
   * @param {SaveQuestionAnswersCommand} [saveQuestionAnswersCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApi
   */
  public saveQuestionAnswers(saveQuestionAnswersCommand?: SaveQuestionAnswersCommand, options?: any) {
    return QuestionnairesApiFp(this.configuration)
      .saveQuestionAnswers(saveQuestionAnswersCommand, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Save questionnaire
   * @param {SaveQuestionCategoryFormCommand} [saveQuestionCategoryFormCommand] Question Group command.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApi
   */
  public saveQuestionCategoryForm(saveQuestionCategoryFormCommand?: SaveQuestionCategoryFormCommand, options?: any) {
    return QuestionnairesApiFp(this.configuration)
      .saveQuestionCategoryForm(saveQuestionCategoryFormCommand, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Save question
   * @param {SaveQuestionFormCommand} [saveQuestionFormCommand] Question command.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApi
   */
  public saveQuestionForm(saveQuestionFormCommand?: SaveQuestionFormCommand, options?: any) {
    return QuestionnairesApiFp(this.configuration)
      .saveQuestionForm(saveQuestionFormCommand, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Save questionnaire
   * @param {SaveQuestionGroupFormCommand} [saveQuestionGroupFormCommand] Question Group command.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApi
   */
  public saveQuestionGroupForm(saveQuestionGroupFormCommand?: SaveQuestionGroupFormCommand, options?: any) {
    return QuestionnairesApiFp(this.configuration)
      .saveQuestionGroupForm(saveQuestionGroupFormCommand, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Save questionnaire
   * @param {SaveQuestionSubCategoryFormCommand} [saveQuestionSubCategoryFormCommand] Question Group command.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApi
   */
  public saveQuestionSubCategoryForm(
    saveQuestionSubCategoryFormCommand?: SaveQuestionSubCategoryFormCommand,
    options?: any,
  ) {
    return QuestionnairesApiFp(this.configuration)
      .saveQuestionSubCategoryForm(saveQuestionSubCategoryFormCommand, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Save questionnaire
   * @param {SaveQuestionnaireFormCommand} [saveQuestionnaireFormCommand] Questionnaire version command.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApi
   */
  public saveQuestionnaireForm(saveQuestionnaireFormCommand?: SaveQuestionnaireFormCommand, options?: any) {
    return QuestionnairesApiFp(this.configuration)
      .saveQuestionnaireForm(saveQuestionnaireFormCommand, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update question
   * @param {UpdateQuestionFormCommand} [updateQuestionFormCommand] Question command.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionnairesApi
   */
  public updateQuestionForm(updateQuestionFormCommand?: UpdateQuestionFormCommand, options?: any) {
    return QuestionnairesApiFp(this.configuration)
      .updateQuestionForm(updateQuestionFormCommand, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * ReportsApi - axios parameter creator
 * @export
 */
export const ReportsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Generate PDF report file
     * @param {GetAmalgamatedInnovationScorecardReportQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAmalgamatedInnovationScorecardReport: async (
      query?: GetAmalgamatedInnovationScorecardReportQuery,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/reports/innovation/scorecard/amalgamated`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Export .doc file with all questions of questionnaire
     * @param {number} questionnaireVersionId Questionnaire version Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getExportQuestionnaireVersionData: async (
      questionnaireVersionId: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'questionnaireVersionId' is not null or undefined
      assertParamExists('getExportQuestionnaireVersionData', 'questionnaireVersionId', questionnaireVersionId);
      const localVarPath = `/api/v1/reports/export/questionnaire/{questionnaireVersionId}`.replace(
        `{${'questionnaireVersionId'}}`,
        encodeURIComponent(String(questionnaireVersionId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Generate PDF report file
     * @param {GetInnovationScorecardReportQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInnovationScorecardGroupedReport: async (
      query?: GetInnovationScorecardReportQuery,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/reports/innovation/scorecard/grouped`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Generate PDF report file
     * @param {number} questionnaireId Questionnaire Id.
     * @param {number} userId User Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInnovationScorecardReport: async (
      questionnaireId: number,
      userId: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'questionnaireId' is not null or undefined
      assertParamExists('getInnovationScorecardReport', 'questionnaireId', questionnaireId);
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('getInnovationScorecardReport', 'userId', userId);
      const localVarPath = `/api/v1/reports/innovation/scorecard/{questionnaireId}/user/{userId}`
        .replace(`{${'questionnaireId'}}`, encodeURIComponent(String(questionnaireId)))
        .replace(`{${'userId'}}`, encodeURIComponent(String(userId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ReportsApi - functional programming interface
 * @export
 */
export const ReportsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ReportsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Generate PDF report file
     * @param {GetAmalgamatedInnovationScorecardReportQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAmalgamatedInnovationScorecardReport(
      query?: GetAmalgamatedInnovationScorecardReportQuery,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAmalgamatedInnovationScorecardReportModel>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAmalgamatedInnovationScorecardReport(query, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Export .doc file with all questions of questionnaire
     * @param {number} questionnaireVersionId Questionnaire version Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getExportQuestionnaireVersionData(
      questionnaireVersionId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetExportQuestionnaireVersionDataModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getExportQuestionnaireVersionData(
        questionnaireVersionId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Generate PDF report file
     * @param {GetInnovationScorecardReportQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInnovationScorecardGroupedReport(
      query?: GetInnovationScorecardReportQuery,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetInnovationScorecardReportModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getInnovationScorecardGroupedReport(query, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Generate PDF report file
     * @param {number} questionnaireId Questionnaire Id.
     * @param {number} userId User Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInnovationScorecardReport(
      questionnaireId: number,
      userId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetInnovationScorecardReportModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getInnovationScorecardReport(
        questionnaireId,
        userId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ReportsApi - factory interface
 * @export
 */
export const ReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ReportsApiFp(configuration);
  return {
    /**
     *
     * @summary Generate PDF report file
     * @param {GetAmalgamatedInnovationScorecardReportQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAmalgamatedInnovationScorecardReport(
      query?: GetAmalgamatedInnovationScorecardReportQuery,
      options?: any,
    ): AxiosPromise<GetAmalgamatedInnovationScorecardReportModel> {
      return localVarFp
        .getAmalgamatedInnovationScorecardReport(query, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Export .doc file with all questions of questionnaire
     * @param {number} questionnaireVersionId Questionnaire version Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getExportQuestionnaireVersionData(
      questionnaireVersionId: number,
      options?: any,
    ): AxiosPromise<GetExportQuestionnaireVersionDataModel> {
      return localVarFp
        .getExportQuestionnaireVersionData(questionnaireVersionId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Generate PDF report file
     * @param {GetInnovationScorecardReportQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInnovationScorecardGroupedReport(
      query?: GetInnovationScorecardReportQuery,
      options?: any,
    ): AxiosPromise<GetInnovationScorecardReportModel> {
      return localVarFp.getInnovationScorecardGroupedReport(query, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Generate PDF report file
     * @param {number} questionnaireId Questionnaire Id.
     * @param {number} userId User Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInnovationScorecardReport(
      questionnaireId: number,
      userId: number,
      options?: any,
    ): AxiosPromise<GetInnovationScorecardReportModel> {
      return localVarFp
        .getInnovationScorecardReport(questionnaireId, userId, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * ReportsApi - interface
 * @export
 * @interface ReportsApi
 */
export interface ReportsApiInterface {
  /**
   *
   * @summary Generate PDF report file
   * @param {GetAmalgamatedInnovationScorecardReportQuery} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportsApiInterface
   */
  getAmalgamatedInnovationScorecardReport(
    query?: GetAmalgamatedInnovationScorecardReportQuery,
    options?: any,
  ): AxiosPromise<GetAmalgamatedInnovationScorecardReportModel>;

  /**
   *
   * @summary Export .doc file with all questions of questionnaire
   * @param {number} questionnaireVersionId Questionnaire version Id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportsApiInterface
   */
  getExportQuestionnaireVersionData(
    questionnaireVersionId: number,
    options?: any,
  ): AxiosPromise<GetExportQuestionnaireVersionDataModel>;

  /**
   *
   * @summary Generate PDF report file
   * @param {GetInnovationScorecardReportQuery} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportsApiInterface
   */
  getInnovationScorecardGroupedReport(
    query?: GetInnovationScorecardReportQuery,
    options?: any,
  ): AxiosPromise<GetInnovationScorecardReportModel>;

  /**
   *
   * @summary Generate PDF report file
   * @param {number} questionnaireId Questionnaire Id.
   * @param {number} userId User Id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportsApiInterface
   */
  getInnovationScorecardReport(
    questionnaireId: number,
    userId: number,
    options?: any,
  ): AxiosPromise<GetInnovationScorecardReportModel>;
}

/**
 * ReportsApi - object-oriented interface
 * @export
 * @class ReportsApi
 * @extends {BaseAPI}
 */
export class ReportsApi extends BaseAPI implements ReportsApiInterface {
  /**
   *
   * @summary Generate PDF report file
   * @param {GetAmalgamatedInnovationScorecardReportQuery} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportsApi
   */
  public getAmalgamatedInnovationScorecardReport(query?: GetAmalgamatedInnovationScorecardReportQuery, options?: any) {
    return ReportsApiFp(this.configuration)
      .getAmalgamatedInnovationScorecardReport(query, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Export .doc file with all questions of questionnaire
   * @param {number} questionnaireVersionId Questionnaire version Id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportsApi
   */
  public getExportQuestionnaireVersionData(questionnaireVersionId: number, options?: any) {
    return ReportsApiFp(this.configuration)
      .getExportQuestionnaireVersionData(questionnaireVersionId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Generate PDF report file
   * @param {GetInnovationScorecardReportQuery} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportsApi
   */
  public getInnovationScorecardGroupedReport(query?: GetInnovationScorecardReportQuery, options?: any) {
    return ReportsApiFp(this.configuration)
      .getInnovationScorecardGroupedReport(query, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Generate PDF report file
   * @param {number} questionnaireId Questionnaire Id.
   * @param {number} userId User Id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportsApi
   */
  public getInnovationScorecardReport(questionnaireId: number, userId: number, options?: any) {
    return ReportsApiFp(this.configuration)
      .getInnovationScorecardReport(questionnaireId, userId, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * SupportResourcesApi - axios parameter creator
 * @export
 */
export const SupportResourcesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Delete Support Resource
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSupportResource: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteSupportResource', 'id', id);
      const localVarPath = `/delete/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete a Support Resource Question Category
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSupportResourceQuestionCategory: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteSupportResourceQuestionCategory', 'id', id);
      const localVarPath = `/support-resource-question-category/delete/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Filter Support Resources
     * @param {FilterSupportResourcesQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterSupportResources: async (query?: FilterSupportResourcesQuery, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/filter`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all Support Resource Question Category
     * @param {number} questionCategoryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllSupportResourceQuestionCategoryCommand: async (
      questionCategoryId: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'questionCategoryId' is not null or undefined
      assertParamExists('getAllSupportResourceQuestionCategoryCommand', 'questionCategoryId', questionCategoryId);
      const localVarPath = `/support-resource-question-category/all/{questionCategoryId}`.replace(
        `{${'questionCategoryId'}}`,
        encodeURIComponent(String(questionCategoryId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Gets a Support Resource to edit and save
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSaveSupportResourceCommand: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getSaveSupportResourceCommand', 'id', id);
      const localVarPath = `/support-resource/{id}/form`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Save a Support Resource
     * @param {SaveSupportResourceFormCommand} [saveSupportResourceFormCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveSupportResourceForm: async (
      saveSupportResourceFormCommand?: SaveSupportResourceFormCommand,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/save`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        saveSupportResourceFormCommand,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Save a Support Resource Question Category
     * @param {SaveSupportResourceQuestionCategoryCommand} [saveSupportResourceQuestionCategoryCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveSupportResourceQuestionCategory: async (
      saveSupportResourceQuestionCategoryCommand?: SaveSupportResourceQuestionCategoryCommand,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/support-resource-question-category/save`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        saveSupportResourceQuestionCategoryCommand,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SupportResourcesApi - functional programming interface
 * @export
 */
export const SupportResourcesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SupportResourcesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Delete Support Resource
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteSupportResource(
      id: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSupportResource(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Delete a Support Resource Question Category
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteSupportResourceQuestionCategory(
      id: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSupportResourceQuestionCategory(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Filter Support Resources
     * @param {FilterSupportResourcesQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async filterSupportResources(
      query?: FilterSupportResourcesQuery,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FilterSupportResourcesModelGridCollection>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.filterSupportResources(query, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get all Support Resource Question Category
     * @param {number} questionCategoryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllSupportResourceQuestionCategoryCommand(
      questionCategoryId: number,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<GetAllSupportResourceQuestionCategoryCommandModel>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllSupportResourceQuestionCategoryCommand(
        questionCategoryId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Gets a Support Resource to edit and save
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSaveSupportResourceCommand(
      id: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveSupportResourceFormCommand>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSaveSupportResourceCommand(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Save a Support Resource
     * @param {SaveSupportResourceFormCommand} [saveSupportResourceFormCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saveSupportResourceForm(
      saveSupportResourceFormCommand?: SaveSupportResourceFormCommand,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saveSupportResourceForm(
        saveSupportResourceFormCommand,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Save a Support Resource Question Category
     * @param {SaveSupportResourceQuestionCategoryCommand} [saveSupportResourceQuestionCategoryCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saveSupportResourceQuestionCategory(
      saveSupportResourceQuestionCategoryCommand?: SaveSupportResourceQuestionCategoryCommand,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saveSupportResourceQuestionCategory(
        saveSupportResourceQuestionCategoryCommand,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * SupportResourcesApi - factory interface
 * @export
 */
export const SupportResourcesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = SupportResourcesApiFp(configuration);
  return {
    /**
     *
     * @summary Delete Support Resource
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSupportResource(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteSupportResource(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete a Support Resource Question Category
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSupportResourceQuestionCategory(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteSupportResourceQuestionCategory(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Filter Support Resources
     * @param {FilterSupportResourcesQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterSupportResources(
      query?: FilterSupportResourcesQuery,
      options?: any,
    ): AxiosPromise<FilterSupportResourcesModelGridCollection> {
      return localVarFp.filterSupportResources(query, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get all Support Resource Question Category
     * @param {number} questionCategoryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllSupportResourceQuestionCategoryCommand(
      questionCategoryId: number,
      options?: any,
    ): AxiosPromise<Array<GetAllSupportResourceQuestionCategoryCommandModel>> {
      return localVarFp
        .getAllSupportResourceQuestionCategoryCommand(questionCategoryId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Gets a Support Resource to edit and save
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSaveSupportResourceCommand(id: number, options?: any): AxiosPromise<SaveSupportResourceFormCommand> {
      return localVarFp.getSaveSupportResourceCommand(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Save a Support Resource
     * @param {SaveSupportResourceFormCommand} [saveSupportResourceFormCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveSupportResourceForm(
      saveSupportResourceFormCommand?: SaveSupportResourceFormCommand,
      options?: any,
    ): AxiosPromise<number> {
      return localVarFp
        .saveSupportResourceForm(saveSupportResourceFormCommand, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Save a Support Resource Question Category
     * @param {SaveSupportResourceQuestionCategoryCommand} [saveSupportResourceQuestionCategoryCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveSupportResourceQuestionCategory(
      saveSupportResourceQuestionCategoryCommand?: SaveSupportResourceQuestionCategoryCommand,
      options?: any,
    ): AxiosPromise<number> {
      return localVarFp
        .saveSupportResourceQuestionCategory(saveSupportResourceQuestionCategoryCommand, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * SupportResourcesApi - interface
 * @export
 * @interface SupportResourcesApi
 */
export interface SupportResourcesApiInterface {
  /**
   *
   * @summary Delete Support Resource
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SupportResourcesApiInterface
   */
  deleteSupportResource(id: number, options?: any): AxiosPromise<void>;

  /**
   *
   * @summary Delete a Support Resource Question Category
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SupportResourcesApiInterface
   */
  deleteSupportResourceQuestionCategory(id: number, options?: any): AxiosPromise<void>;

  /**
   *
   * @summary Filter Support Resources
   * @param {FilterSupportResourcesQuery} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SupportResourcesApiInterface
   */
  filterSupportResources(
    query?: FilterSupportResourcesQuery,
    options?: any,
  ): AxiosPromise<FilterSupportResourcesModelGridCollection>;

  /**
   *
   * @summary Get all Support Resource Question Category
   * @param {number} questionCategoryId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SupportResourcesApiInterface
   */
  getAllSupportResourceQuestionCategoryCommand(
    questionCategoryId: number,
    options?: any,
  ): AxiosPromise<Array<GetAllSupportResourceQuestionCategoryCommandModel>>;

  /**
   *
   * @summary Gets a Support Resource to edit and save
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SupportResourcesApiInterface
   */
  getSaveSupportResourceCommand(id: number, options?: any): AxiosPromise<SaveSupportResourceFormCommand>;

  /**
   *
   * @summary Save a Support Resource
   * @param {SaveSupportResourceFormCommand} [saveSupportResourceFormCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SupportResourcesApiInterface
   */
  saveSupportResourceForm(
    saveSupportResourceFormCommand?: SaveSupportResourceFormCommand,
    options?: any,
  ): AxiosPromise<number>;

  /**
   *
   * @summary Save a Support Resource Question Category
   * @param {SaveSupportResourceQuestionCategoryCommand} [saveSupportResourceQuestionCategoryCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SupportResourcesApiInterface
   */
  saveSupportResourceQuestionCategory(
    saveSupportResourceQuestionCategoryCommand?: SaveSupportResourceQuestionCategoryCommand,
    options?: any,
  ): AxiosPromise<number>;
}

/**
 * SupportResourcesApi - object-oriented interface
 * @export
 * @class SupportResourcesApi
 * @extends {BaseAPI}
 */
export class SupportResourcesApi extends BaseAPI implements SupportResourcesApiInterface {
  /**
   *
   * @summary Delete Support Resource
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SupportResourcesApi
   */
  public deleteSupportResource(id: number, options?: any) {
    return SupportResourcesApiFp(this.configuration)
      .deleteSupportResource(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete a Support Resource Question Category
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SupportResourcesApi
   */
  public deleteSupportResourceQuestionCategory(id: number, options?: any) {
    return SupportResourcesApiFp(this.configuration)
      .deleteSupportResourceQuestionCategory(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Filter Support Resources
   * @param {FilterSupportResourcesQuery} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SupportResourcesApi
   */
  public filterSupportResources(query?: FilterSupportResourcesQuery, options?: any) {
    return SupportResourcesApiFp(this.configuration)
      .filterSupportResources(query, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get all Support Resource Question Category
   * @param {number} questionCategoryId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SupportResourcesApi
   */
  public getAllSupportResourceQuestionCategoryCommand(questionCategoryId: number, options?: any) {
    return SupportResourcesApiFp(this.configuration)
      .getAllSupportResourceQuestionCategoryCommand(questionCategoryId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Gets a Support Resource to edit and save
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SupportResourcesApi
   */
  public getSaveSupportResourceCommand(id: number, options?: any) {
    return SupportResourcesApiFp(this.configuration)
      .getSaveSupportResourceCommand(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Save a Support Resource
   * @param {SaveSupportResourceFormCommand} [saveSupportResourceFormCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SupportResourcesApi
   */
  public saveSupportResourceForm(saveSupportResourceFormCommand?: SaveSupportResourceFormCommand, options?: any) {
    return SupportResourcesApiFp(this.configuration)
      .saveSupportResourceForm(saveSupportResourceFormCommand, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Save a Support Resource Question Category
   * @param {SaveSupportResourceQuestionCategoryCommand} [saveSupportResourceQuestionCategoryCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SupportResourcesApi
   */
  public saveSupportResourceQuestionCategory(
    saveSupportResourceQuestionCategoryCommand?: SaveSupportResourceQuestionCategoryCommand,
    options?: any,
  ) {
    return SupportResourcesApiFp(this.configuration)
      .saveSupportResourceQuestionCategory(saveSupportResourceQuestionCategoryCommand, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Filters users for a grid representation.
     * @param {FilterUsersQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterUsers: async (query?: FilterUsersQuery, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/users/filter`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Gets user profile for the current user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserProfile: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/users/profile`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Gets a user profile by Id.
     * @param {number} id User Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserProfileById: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getUserProfileById', 'id', id);
      const localVarPath = `/api/v1/users/profile/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Gets user profile form data.
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserProfileForm: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getUserProfileForm', 'id', id);
      const localVarPath = `/api/v1/users/profile/{id}/form`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Invite admin user
     * @param {InviteAdminUserFormCommand} [inviteAdminUserFormCommand] command with user to be inviteds
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inviteAdminUserForm: async (
      inviteAdminUserFormCommand?: InviteAdminUserFormCommand,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/users/admin/invite`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        inviteAdminUserFormCommand,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Updates Accepted at date time
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setInvitationAccepted: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/users/invitation/accepted`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update user company
     * @param {number} userId
     * @param {number} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserCompany: async (userId: number, companyId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('updateUserCompany', 'userId', userId);
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists('updateUserCompany', 'companyId', companyId);
      const localVarPath = `/api/v1/users/update/user/{userId}/company/{companyId}`
        .replace(`{${'userId'}}`, encodeURIComponent(String(userId)))
        .replace(`{${'companyId'}}`, encodeURIComponent(String(companyId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Updates user profile.
     * @param {UpdateUserProfileCommand} [updateUserProfileCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserProfile: async (
      updateUserProfileCommand?: UpdateUserProfileCommand,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/users/profile`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateUserProfileCommand,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Activate/deactivcate user
     * @param {number} userId
     * @param {boolean} isDeleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserStatus: async (userId: number, isDeleted: boolean, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('updateUserStatus', 'userId', userId);
      // verify required parameter 'isDeleted' is not null or undefined
      assertParamExists('updateUserStatus', 'isDeleted', isDeleted);
      const localVarPath = `/api/v1/users/update/user/{userId}/status/{isDeleted}`
        .replace(`{${'userId'}}`, encodeURIComponent(String(userId)))
        .replace(`{${'isDeleted'}}`, encodeURIComponent(String(isDeleted)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Filters users for a grid representation.
     * @param {FilterUsersQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async filterUsers(
      query?: FilterUsersQuery,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FilterUsersModelGridCollection>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.filterUsers(query, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Gets user profile for the current user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUserProfile(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserProfileModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUserProfile(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Gets a user profile by Id.
     * @param {number} id User Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUserProfileById(
      id: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserProfileModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUserProfileById(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Gets user profile form data.
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUserProfileForm(
      id: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateUserProfileCommand>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUserProfileForm(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Invite admin user
     * @param {InviteAdminUserFormCommand} [inviteAdminUserFormCommand] command with user to be inviteds
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async inviteAdminUserForm(
      inviteAdminUserFormCommand?: InviteAdminUserFormCommand,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.inviteAdminUserForm(
        inviteAdminUserFormCommand,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Updates Accepted at date time
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async setInvitationAccepted(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.setInvitationAccepted(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update user company
     * @param {number} userId
     * @param {number} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateUserCompany(
      userId: number,
      companyId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserCompany(userId, companyId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Updates user profile.
     * @param {UpdateUserProfileCommand} [updateUserProfileCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateUserProfile(
      updateUserProfileCommand?: UpdateUserProfileCommand,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserProfile(updateUserProfileCommand, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Activate/deactivcate user
     * @param {number} userId
     * @param {boolean} isDeleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateUserStatus(
      userId: number,
      isDeleted: boolean,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserStatus(userId, isDeleted, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = UsersApiFp(configuration);
  return {
    /**
     *
     * @summary Filters users for a grid representation.
     * @param {FilterUsersQuery} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterUsers(query?: FilterUsersQuery, options?: any): AxiosPromise<FilterUsersModelGridCollection> {
      return localVarFp.filterUsers(query, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Gets user profile for the current user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserProfile(options?: any): AxiosPromise<GetUserProfileModel> {
      return localVarFp.getUserProfile(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Gets a user profile by Id.
     * @param {number} id User Id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserProfileById(id: number, options?: any): AxiosPromise<GetUserProfileModel> {
      return localVarFp.getUserProfileById(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Gets user profile form data.
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserProfileForm(id: number, options?: any): AxiosPromise<UpdateUserProfileCommand> {
      return localVarFp.getUserProfileForm(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Invite admin user
     * @param {InviteAdminUserFormCommand} [inviteAdminUserFormCommand] command with user to be inviteds
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inviteAdminUserForm(inviteAdminUserFormCommand?: InviteAdminUserFormCommand, options?: any): AxiosPromise<void> {
      return localVarFp
        .inviteAdminUserForm(inviteAdminUserFormCommand, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Updates Accepted at date time
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setInvitationAccepted(options?: any): AxiosPromise<void> {
      return localVarFp.setInvitationAccepted(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update user company
     * @param {number} userId
     * @param {number} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserCompany(userId: number, companyId: number, options?: any): AxiosPromise<void> {
      return localVarFp.updateUserCompany(userId, companyId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Updates user profile.
     * @param {UpdateUserProfileCommand} [updateUserProfileCommand]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserProfile(updateUserProfileCommand?: UpdateUserProfileCommand, options?: any): AxiosPromise<void> {
      return localVarFp.updateUserProfile(updateUserProfileCommand, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Activate/deactivcate user
     * @param {number} userId
     * @param {boolean} isDeleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserStatus(userId: number, isDeleted: boolean, options?: any): AxiosPromise<void> {
      return localVarFp.updateUserStatus(userId, isDeleted, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * UsersApi - interface
 * @export
 * @interface UsersApi
 */
export interface UsersApiInterface {
  /**
   *
   * @summary Filters users for a grid representation.
   * @param {FilterUsersQuery} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApiInterface
   */
  filterUsers(query?: FilterUsersQuery, options?: any): AxiosPromise<FilterUsersModelGridCollection>;

  /**
   *
   * @summary Gets user profile for the current user.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApiInterface
   */
  getUserProfile(options?: any): AxiosPromise<GetUserProfileModel>;

  /**
   *
   * @summary Gets a user profile by Id.
   * @param {number} id User Id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApiInterface
   */
  getUserProfileById(id: number, options?: any): AxiosPromise<GetUserProfileModel>;

  /**
   *
   * @summary Gets user profile form data.
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApiInterface
   */
  getUserProfileForm(id: number, options?: any): AxiosPromise<UpdateUserProfileCommand>;

  /**
   *
   * @summary Invite admin user
   * @param {InviteAdminUserFormCommand} [inviteAdminUserFormCommand] command with user to be inviteds
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApiInterface
   */
  inviteAdminUserForm(inviteAdminUserFormCommand?: InviteAdminUserFormCommand, options?: any): AxiosPromise<void>;

  /**
   *
   * @summary Updates Accepted at date time
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApiInterface
   */
  setInvitationAccepted(options?: any): AxiosPromise<void>;

  /**
   *
   * @summary Update user company
   * @param {number} userId
   * @param {number} companyId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApiInterface
   */
  updateUserCompany(userId: number, companyId: number, options?: any): AxiosPromise<void>;

  /**
   *
   * @summary Updates user profile.
   * @param {UpdateUserProfileCommand} [updateUserProfileCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApiInterface
   */
  updateUserProfile(updateUserProfileCommand?: UpdateUserProfileCommand, options?: any): AxiosPromise<void>;

  /**
   *
   * @summary Activate/deactivcate user
   * @param {number} userId
   * @param {boolean} isDeleted
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApiInterface
   */
  updateUserStatus(userId: number, isDeleted: boolean, options?: any): AxiosPromise<void>;
}

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI implements UsersApiInterface {
  /**
   *
   * @summary Filters users for a grid representation.
   * @param {FilterUsersQuery} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public filterUsers(query?: FilterUsersQuery, options?: any) {
    return UsersApiFp(this.configuration)
      .filterUsers(query, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Gets user profile for the current user.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public getUserProfile(options?: any) {
    return UsersApiFp(this.configuration)
      .getUserProfile(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Gets a user profile by Id.
   * @param {number} id User Id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public getUserProfileById(id: number, options?: any) {
    return UsersApiFp(this.configuration)
      .getUserProfileById(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Gets user profile form data.
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public getUserProfileForm(id: number, options?: any) {
    return UsersApiFp(this.configuration)
      .getUserProfileForm(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Invite admin user
   * @param {InviteAdminUserFormCommand} [inviteAdminUserFormCommand] command with user to be inviteds
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public inviteAdminUserForm(inviteAdminUserFormCommand?: InviteAdminUserFormCommand, options?: any) {
    return UsersApiFp(this.configuration)
      .inviteAdminUserForm(inviteAdminUserFormCommand, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Updates Accepted at date time
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public setInvitationAccepted(options?: any) {
    return UsersApiFp(this.configuration)
      .setInvitationAccepted(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update user company
   * @param {number} userId
   * @param {number} companyId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public updateUserCompany(userId: number, companyId: number, options?: any) {
    return UsersApiFp(this.configuration)
      .updateUserCompany(userId, companyId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Updates user profile.
   * @param {UpdateUserProfileCommand} [updateUserProfileCommand]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public updateUserProfile(updateUserProfileCommand?: UpdateUserProfileCommand, options?: any) {
    return UsersApiFp(this.configuration)
      .updateUserProfile(updateUserProfileCommand, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Activate/deactivcate user
   * @param {number} userId
   * @param {boolean} isDeleted
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public updateUserStatus(userId: number, isDeleted: boolean, options?: any) {
    return UsersApiFp(this.configuration)
      .updateUserStatus(userId, isDeleted, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * UtilityApi - axios parameter creator
 * @export
 */
export const UtilityApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Sends emails to users who have started a questionnaire but not yet completed it.  Checks for saved drafts with no published date
     * @param {string} sharedKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendReminderEmails: async (sharedKey: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'sharedKey' is not null or undefined
      assertParamExists('sendReminderEmails', 'sharedKey', sharedKey);
      const localVarPath = `/api/v1/utility/send-reminder-emails/{sharedKey}`.replace(
        `{${'sharedKey'}}`,
        encodeURIComponent(String(sharedKey)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UtilityApi - functional programming interface
 * @export
 */
export const UtilityApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UtilityApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Sends emails to users who have started a questionnaire but not yet completed it.  Checks for saved drafts with no published date
     * @param {string} sharedKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sendReminderEmails(
      sharedKey: string,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.sendReminderEmails(sharedKey, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * UtilityApi - factory interface
 * @export
 */
export const UtilityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = UtilityApiFp(configuration);
  return {
    /**
     *
     * @summary Sends emails to users who have started a questionnaire but not yet completed it.  Checks for saved drafts with no published date
     * @param {string} sharedKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendReminderEmails(sharedKey: string, options?: any): AxiosPromise<number> {
      return localVarFp.sendReminderEmails(sharedKey, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * UtilityApi - interface
 * @export
 * @interface UtilityApi
 */
export interface UtilityApiInterface {
  /**
   *
   * @summary Sends emails to users who have started a questionnaire but not yet completed it.  Checks for saved drafts with no published date
   * @param {string} sharedKey
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UtilityApiInterface
   */
  sendReminderEmails(sharedKey: string, options?: any): AxiosPromise<number>;
}

/**
 * UtilityApi - object-oriented interface
 * @export
 * @class UtilityApi
 * @extends {BaseAPI}
 */
export class UtilityApi extends BaseAPI implements UtilityApiInterface {
  /**
   *
   * @summary Sends emails to users who have started a questionnaire but not yet completed it.  Checks for saved drafts with no published date
   * @param {string} sharedKey
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UtilityApi
   */
  public sendReminderEmails(sharedKey: string, options?: any) {
    return UtilityApiFp(this.configuration)
      .sendReminderEmails(sharedKey, options)
      .then(request => request(this.axios, this.basePath));
  }
}
