






















import Vue, { PropType } from 'vue';

import { SimpleLookup } from '@/core/webapi';
import { AppFormComponent } from './app-form-component';

export default Vue.extend({
  name: 'InputSelectCpt',
  props: {
    model: Object,
    lookups: Array as PropType<SimpleLookup[]>,
    prop: String,
    label: String,
    hint: String,
    placeholder: String,
    disabled: Boolean,
    loading: { type: Boolean, required: false, default: false },
    multiple: { type: Boolean, required: false, default: false },
    // For nested objects
    nestedErrKey: {
      type: String,
      required: false,
      default: null,
    },
    nestedErrModel: {
      type: Object,
      required: false,
      default: null,
    },
  },
  computed: {
    errorMessage() {
      return AppFormComponent.errorMessage(this.nestedErrModel || this.model, this.nestedErrKey || this.prop);
    },
  },
  methods: {
    onChange(value: any) {
      AppFormComponent.onChange(this, value, this.$props.model, this.$props.prop);
    },
  },
});
