import { render, staticRenderFns } from "./Empty.vue?vue&type=template&id=0ccd1486&scoped=true&"
import script from "./Empty.vue?vue&type=script&lang=ts&"
export * from "./Empty.vue?vue&type=script&lang=ts&"
import style0 from "./Empty.vue?vue&type=style&index=0&id=0ccd1486&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ccd1486",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VMain } from 'vuetify/lib/components/VMain';
installComponents(component, {VMain})
