export const questionnairesRoutes = [
  {
    path: '/admin/questionnaires',
    name: 'admin-questionnaires-grid',
    meta: { layout: 'dashboard' },
    component: () => import(/* webpackChunkName: "admin" */ './admin/QuestionnairesGridView.vue'),
  },
  {
    path: '/admin/questionnaire/:questionnaireId/save/:currentGroupId?',
    name: 'admin-questionnaires-save',
    meta: { layout: 'dashboard' },
    component: () => import(/* webpackChunkName: "admin" */ './admin/QuestionnaireSaveView.vue'),
  },
  {
    path: '/user/questionnaires',
    name: 'user-questionnaires-grid',
    meta: { layout: 'dashboard' },
    component: () => import(/* webpackChunkName: "users" */ './user/QuestionnaireGridView.vue'),
  },
  {
    path: '/user/questionnaire/:questionnaireVersionCompanyUserId/:questionnaireVersionId/save/:currentGroupId?',
    name: 'user-questionnaires-save',
    meta: { layout: 'default' },
    component: () => import(/* webpackChunkName: "users" */ './user/QuestionnaireSaveView.vue'),
  },
  {
    path: '/user/questionnaire-submitted/:questionnaireVersionCompanyUserId/:questionnaireVersionId',
    name: 'user-questionnaires-submitted',
    meta: { layout: 'dashboard' },
    component: () => import(/* webpackChunkName: "users" */ './user/QuestionnaireSubmittedView.vue'),
  },
];
