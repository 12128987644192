







import Vue from 'vue';

export default Vue.extend({
  name: 'NoItemsToDisplayCpt',
  props: {
    text: { type: String, required: false, default: 'No items to display.' },
  },
});
