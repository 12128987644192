const RETURN_URL_KEY = 'return-url-to';

export const StorageService = {
  getStorage(): Storage {
    return sessionStorage;
  },

  setReturnUrl(returnUrl: string) {
    this.getStorage().setItem(RETURN_URL_KEY, returnUrl);
  },

  // This is for better login experience
  getAndRemoveReturnUrl(): string {
    const value = this.getStorage().getItem(RETURN_URL_KEY);
    this.getStorage().removeItem(RETURN_URL_KEY);

    return value ? value : '';
  },
};
