




























import Vue from 'vue';

export default Vue.extend({
  name: 'ButtonCpt',
  props: {
    ariaLabel: {
      type: String,
      required: false,
      default: '',
    },
    to: {
      type: String,
      required: false,
      default: null,
    },
    color: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    block: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
      required: false,
      default: 'button',
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
    xSmall: {
      type: Boolean,
      required: false,
      default: false,
    },
    xLarge: {
      type: Boolean,
      required: false,
      default: false,
    },
    text: {
      type: Boolean,
      required: false,
      default: false,
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    depressed: {
      type: Boolean,
      required: false,
      default: true,
    },
    fab: {
      type: Boolean,
      required: false,
      default: false,
    },
    dark: {
      type: Boolean,
      required: false,
      default: false,
    },
    cssClass: {
      type: String,
      required: false,
      default: '',
    },
    href: {
      type: String,
      required: false,
      default: '',
    },
    target: {
      type: String,
      required: false,
      default: '',
    },
    icon: {
      type: Boolean,
      required: false,
      default: false,
    },
    vBind: {
      required: false,
      default: null,
    },
    vOn: {
      required: false,
      default: null,
    },
    link: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    onClick() {
      this.$emit('click');
    },
  },
});
