import { GetInnovationScorecardReportModel } from '@/core/webapi';

export const ReportService = {
  openScorecardFile(model: GetInnovationScorecardReportModel) {
    const linkSource = `data:application/pdf;base64,${model.pdfFile}`;
    const downloadLink = document.createElement('a');

    downloadLink.href = linkSource;
    downloadLink.download = model.fileName;
    downloadLink.click();
  },
};
