import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';

import App from '@/App.vue';
// import '@/registerServiceWorker';
import router from '@/router';
import { store } from '@/core/store';
import MsalPlugin from '@/core/plugins/vue.msal.plugin';
import vuetify from '@/core/plugins/vuetify';
import { TiptapVuetifyPlugin } from 'tiptap-vuetify';
import '@/core/filters/time.filters';

// Maybe move to component
import 'tiptap-vuetify/dist/main.css';
import 'vuetify/dist/vuetify.min.css';
import './styles/app.scss';

import { Empty, Landing, Default, Dashboard } from '@/layouts';

Vue.config.productionTip = false;
Vue.component('empty-layout', Empty);
Vue.component('landing-layout', Landing);
Vue.component('default-layout', Default);
Vue.component('dashboard-layout', Dashboard);
Vue.prototype.$eventHub = new Vue();

Vue.use(VueAxios, axios);

Vue.use(MsalPlugin, {});

Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: 'mdi',
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');
