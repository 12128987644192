














import Vue, { PropType } from 'vue';

import { SimpleLookup } from '@/core/webapi';
import { GridModel } from './grid.models';

export default Vue.extend({
  name: 'GridSelectFilterCpt',
  props: {
    grid: Object as PropType<GridModel<any>>,
    lookups: Array as PropType<SimpleLookup[]>,
    prop: String,
    label: String,
  },
  created() {
    this.$eventHub.$on('GRID_DATA_UPDATED', () => {
      this.$forceUpdate();
    });
  },
});
