














import Vue, { PropType } from 'vue';
import { ButtonCpt } from '@/core/components';
import { GridModel } from './grid.models';

export default Vue.extend({
  name: 'GridToggleButtonFilterCpt',
  components: {
    ButtonCpt,
  },
  props: {
    grid: Object as PropType<GridModel<any>>,
    prop: String,
    label: String,
  },
  created() {
    this.$eventHub.$on('GRID_DATA_UPDATED', () => {
      this.$forceUpdate();
    });
  },
  methods: {
    onChange() {
      this.grid.filter[this.prop] = !this.grid.filter[this.prop];
      this.grid.update();
      this.$forceUpdate();
    },
  },
});
