/* eslint-disable @typescript-eslint/no-unused-vars */
import Vue from 'vue';
import Vuex from 'vuex';

import { ApiService } from '@/core/services';
import { GetUserProfileModel } from '@/core/webapi';

Vue.use(Vuex);

// Storage keys
const USER_PROFILE_KEY = 'ida-jandaya-user-profile';
const PARTNER_PROFILE_CONTEXT_KEY = 'ida-jandaya-service-profile-context';

const MsalService = new Vue({
  methods: {
    logout(redirectToRouteName?: string) {
      this.$msal.logout(redirectToRouteName);
    },
  },
});

function getUserProfileFromStorage() {
  const userProfile = sessionStorage.getItem(USER_PROFILE_KEY);

  if (userProfile) {
    return JSON.parse(userProfile) as any as GetUserProfileModel;
  }
}

function getPartnerProfileContextFromStorage() {
  const context = sessionStorage.getItem(PARTNER_PROFILE_CONTEXT_KEY);

  if (context) {
    return parseInt(context);
  }
}

// export function setPartnerProfileContext(type: RegistrationType.ServiceProvider | RegistrationType.ServiceUser) {
//   sessionStorage.setItem(PARTNER_PROFILE_CONTEXT_KEY, type.toString());
//   store.dispatch('setUserProfile');
// }

/**
 * Vuex Store
 *
 * TODO: This store is NOT strongly typed. It should be.
 *   I did not have the time to look into and implementing strongly typing
 *   the store. Maybe we could do it in another project!
 *   Examples:
 *     - https://next.vuex.vuejs.org/guide/typescript-support.html
 *     - https://medium.com/swlh/properly-typed-vuex-stores-427bf4c6a3d1
 */
export const store = new Vuex.Store({
  state: {
    userProfile: getUserProfileFromStorage(),
    isFetchingUserProfile: false,
  },
  getters: {
    userProfile(state) {
      return getUserProfileFromStorage();
    },
  },
  mutations: {
    setUserProfile(state, payload) {
      state.userProfile = payload;

      if (String(payload)) {
        sessionStorage.setItem(USER_PROFILE_KEY, JSON.stringify(payload));
      } else {
        // There is nothing to store so remove values from localstorage
        sessionStorage.removeItem(USER_PROFILE_KEY);
      }
    },
  },
  actions: {
    setUserProfile({ state, commit }) {
      if (state.isFetchingUserProfile) {
        return;
      }

      state.isFetchingUserProfile = true;

      ApiService.users()
        .getUserProfile()
        .then(({ data }) => {
          commit('setUserProfile', data);
        })
        .catch(e => {
          if (e && e.response && e.response.status === 401) {
            // This user does not have the permissions to access the backend
            // TODO: Show message and redirect to home instead?
            // TODO: The store shouldn't be calling logout anyway, this needs to be handled outside the store.
            MsalService.logout();
          }
        })
        .finally(() => {
          state.isFetchingUserProfile = false;
        });
    },

    clearUserData({ commit, state }) {
      commit('setUserProfile', '');
    },
  },
});
