










import AppVue from '@/AppVue.vue';

import { SnackbarCpt, CallToRegisterCpt } from '@/core/components';

export default AppVue.extend({
  name: 'App',
  components: {
    SnackbarCpt,
    CallToRegisterCpt,
  },
  watch: {
    userProfile() {
      this.onUserProfileUpdated();
    },
  },
  computed: {
    layout() {
      const layout = this.$route.meta?.layout || 'empty';
      return `${layout}-layout`;
    },
  },
  created() {
    // eslint-disable-next-line
    console.log(`${process.env.VUE_APP_ENVIRONMENT} - ${process.env.VUE_APP_VERSION}`);
  },
  methods: {
    onUserProfileUpdated() {
      this.enforceAccountSetup();
    },
    enforceAccountSetup() {
      if (!this.isAuthenticated) {
        return;
      }

      // const redirectTo = RedirectService.getProfileRedirect(this.userProfile, this.$route.name);
      // if (redirectTo && this.$route.name !== redirectTo) {
      //   this.$router.push(`/${redirectTo}`);
      // }
    },
  },
});
