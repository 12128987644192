






































































import Vue from 'vue';
import { AppFormComponent } from './app-form-component';
import { ColourDisplayCpt, ButtonCpt } from '@/core/components';
import ValidationErrorMessageCpt from './ValidationErrorMessageCpt.vue';

export default Vue.extend({
  name: 'InputColourCpt',
  components: { ValidationErrorMessageCpt, ColourDisplayCpt, ButtonCpt },
  props: {
    model: Object,
    prop: String,
    disabled: Boolean,
    label: {
      type: String,
      required: false,
      default: 'Colour',
    },
  },
  computed: {
    errorMessage() {
      return AppFormComponent.errorMessage(this.model, this.prop);
    },
  },
  data: () => ({
    colorMenu: false,
    internalColour: '',
  }),
  watch: {
    model: {
      handler() {
        this.loadInternalValues();
      },
      deep: true,
      immediate: true,
    },
    prop() {
      this.loadInternalValues();
    },
  },
  created() {
    this.loadInternalValues();
  },
  methods: {
    loadInternalValues() {
      this.internalColour = (this.model as any)[this.prop as any] ?? '';
    },
    onChange(value: string) {
      this.$props.model[this.$props.prop] = value;
      AppFormComponent.onChange(this, value, this.$props.model, this.$props.prop);
    },
  },
});
