export const companiesRoutes = [
  {
    path: '/companies',
    name: 'companies-grid',
    meta: { layout: 'dashboard' },
    component: () => import(/* webpackChunkName: "users" */ './CompaniesGridView.vue'),
  },
  {
    path: '/company/:companyId/view',
    name: 'companies-view',
    meta: { layout: 'dashboard' },
    component: () => import(/* webpackChunkName: "users" */ './CompanyView.vue'),
  },
];
