











import Vue, { PropType } from 'vue';

import { AppFormComponent } from './app-form-component';
import { Validatable } from '@/core/models';

export default Vue.extend({
  name: 'ValidationErrorMessageCpt',
  props: {
    model: Object as PropType<Validatable>,
    prop: String,
  },
  computed: {
    errorMessage() {
      return AppFormComponent.errorMessage(this.model, this.prop);
    },
  },
});
