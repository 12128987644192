




























import Vue from 'vue';
import ButtonCpt from './ButtonCpt.vue';

export default Vue.extend({
  name: 'ConfirmCpt',
  components: {
    ButtonCpt,
  },
  props: {
    showDialog: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: 'Are you sure?',
    },
  },
  watch: {
    showDialog() {
      this.internalShowDialog = this.showDialog;
    },
  },
  created() {
    this.internalShowDialog = this.showDialog;
  },
  data: () => ({
    internalShowDialog: false,
  }),
  methods: {
    close(result: boolean) {
      this.internalShowDialog = false;
      this.$emit('close', result);
    },
  },
});
