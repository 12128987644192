export const authRoutes = [
  {
    path: '/register',
    name: 'register',
    meta: { layout: 'empty', allowAnonymous: true },
    component: () => import(/* webpackChunkName: "auth" */ './RegisterView.vue'),
  },
  {
    path: '/authenticate',
    name: 'authenticate',
    meta: { layout: 'empty', allowAnonymous: true },
    component: () => import(/* webpackChunkName: "auth" */ './AuthenticateView.vue'),
  },
  {
    path: '/account-pending-approval',
    name: 'account-pending-approval',
    meta: { layout: 'empty', allowAnonymous: false },
    component: () => import(/* webpackChunkName: "auth" */ './AccountPendingApprovalView.vue'),
  },
  // {
  //   path: '/complete-partner-profile',
  //   name: 'complete-partner-profile',
  //   meta: { layout: 'empty', allowAnonymous: false },
  //   component: () => import(/* webpackChunkName: "auth" */ './CompletePartnerProfileView.vue'),
  // },
];
