















import Vue, { PropType } from 'vue';

import { Validatable } from '@/core/models';

export default Vue.extend({
  name: 'ValidationSummaryCpt',
  props: {
    model: Object as PropType<Validatable>,
  },
  computed: {
    errors() {
      const model = this.model;

      if (!model.validationSummary) {
        return [];
      }

      return model.validationSummary;
    },
  },
});
