


























import Vue from 'vue';

import { AppFormComponent } from './app-form-component';

function fromBase64(base64: string) {
  if (!base64) {
    return null;
  }

  const arr = base64.split(',');
  const mimeSplit = arr[0].match(/:(.*?);/);
  if (mimeSplit === null) {
    return null;
  }

  const mime = mimeSplit[1];
  const fileExtension = mime.split('/')[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  // Filename is not really important, since we're sending it to the WebAPI as base64
  const temporaryFileName = `image.${fileExtension}`;
  return new File([u8arr], temporaryFileName, { type: mime });
}

export default Vue.extend({
  name: 'InputImageCpt',
  props: {
    model: Object,
    prop: String,
    label: String,
    hint: String,
    placeholder: String,
    disabled: Boolean,
  },
  computed: {
    errorMessage() {
      return AppFormComponent.errorMessage(this.model, this.prop);
    },
  },
  data: () => ({
    internalFile: [],
  }),
  watch: {
    model(model: any) {
      const file = fromBase64((model as any)[this.prop]);
      this.internalFile = file ? ([file] as any) : [];
    },
  },
  methods: {
    onChange(file: File) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;

      if (!file) {
        (that.$props.model as any)[that.$props.prop] = '';
        AppFormComponent.onChange(this, '', that.$props.model, that.$props.prop);
        return;
      }

      const reader = new FileReader();
      reader.onloadend = function () {
        const base64 = reader.result;
        (that.$props.model as any)[that.$props.prop] = base64;
        AppFormComponent.onChange(this, base64, that.$props.model, that.$props.prop);
      };
      reader.readAsDataURL(file);
    },
  },
});
