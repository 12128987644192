













import AppVue from '@/AppVue.vue';

export default AppVue.extend({
  name: 'TabNavigation',
  data: () => ({
    links: [
      { text: 'Home', to: '/' },
      { text: 'Find new partners', to: '/find-new-partners' },
      { text: 'News', to: '/news' },
    ],
  }),
});
