




















import Vue from 'vue';
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from 'tiptap-vuetify';

import { AppFormComponent } from './app-form-component';

export default Vue.extend({
  name: 'InputWysiwygCpt',
  components: {
    TiptapVuetify,
  },
  props: {
    model: Object,
    prop: String,
    label: String,
  },
  data: () => ({
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3],
          },
        },
      ],
      Bold,
      HorizontalRule,
      HardBreak,
    ],
    internalValue: '',
  }),
  computed: {
    errorMessage() {
      return AppFormComponent.errorMessage(this.model, this.prop);
    },
  },
  watch: {
    model(model: any) {
      this.internalValue = (model as any)[this.prop];
    },
    internalValue(value: string) {
      // If the <p> tag is not handled, server model validation will be avoided
      // eslint-disable-next-line vue/no-mutating-props
      this.model[this.prop] = value === '<p></p>' ? '' : value;
      AppFormComponent.onChange(this, value, this.model, this.prop);
    },
  },
});
