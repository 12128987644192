












import Vue, { PropType } from 'vue';

import { GridModel } from './grid.models';

export default Vue.extend({
  name: 'GridPagerCpt',
  props: {
    grid: Object as PropType<GridModel<any>>,
  },
  created() {
    this.$eventHub.$on('GRID_DATA_UPDATED', () => {
      this.$forceUpdate();
    });
  },
  methods: {
    onChange(page: number) {
      this.$props.grid.goToPage(page);
    },
  },
});
