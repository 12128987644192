






















import Vue from 'vue';

import { AppFormComponent } from './app-form-component';

export default Vue.extend({
  name: 'InputFieldCpt',
  props: {
    model: Object,
    prop: String,
    label: String,
    hint: String,
    placeholder: String,
    disabled: Boolean,
    autofocus: Boolean,
    loading: { type: Boolean, required: false, default: false },
    type: {
      type: String,
      required: false,
      default: 'text',
    },
    // For nested objects
    nestedErrKey: {
      type: String,
      required: false,
      default: null,
    },
    nestedErrModel: {
      type: Object,
      required: false,
      default: null,
    },
  },
  computed: {
    errorMessage() {
      return AppFormComponent.errorMessage(this.nestedErrModel || this.model, this.nestedErrKey || this.prop);
    },
  },
  methods: {
    onChange(value: any) {
      AppFormComponent.onChange(this, value, this.$props.model, this.$props.prop);
    },
  },
});
