
import { Vue, Component } from 'vue-property-decorator';

import { GetUserProfileModel, GetUserProfileModelCompanyModel } from '@/core/webapi';

// NOTE:
// Couldn't do this in Vue.extend + mixin style, because mixins are not officially
// supported that way when using TS. So I had to do it in TS class style.
// SEE:
// https://stackoverflow.com/a/56128388/413785
// https://stackoverflow.com/a/43509325/413785
// https://stackoverflow.com/a/54273687/413785 (I tried vue-typed-mixins as well, it didn't work)
//
// IMPORTANT: Any properties you add to AppVue, must be exposed inside /src/types/vue.d.ts
// because VueJS 2 doesn't play nicely enough with TypeScript.
// SEE: https://github.com/vuejs/vue/issues/8721
@Component
export default class AppVue extends Vue {
  get isAuthenticated() {
    return this.$msal.isAuthenticated() as boolean;
  }

  get userProfile() {
    return this.$store.state.userProfile as GetUserProfileModel | undefined;
  }

  get partnerProfile() {
    return this.userProfile?.company || new GetUserProfileModelCompanyModel();
  }

  get currentUserId() {
    return this.userProfile?.id;
  }

  get currentUserEmail() {
    return this.userProfile?.email;
  }

  get currentUserName() {
    return this.userProfile?.userName;
  }

  get isAdmin() {
    return this.userProfile?.isAdmin || false;
  }

  get environment() {
    return process.env.VUE_APP_ENVIRONMENT as 'Dev' | 'Test' | 'Production';
  }

  get isDevEnv() {
    return this.environment === 'Dev';
  }

  get isTestEnv() {
    return this.environment === 'Test';
  }

  get isProdEnv() {
    return this.environment === 'Production';
  }
}
