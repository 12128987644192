













import Vue from 'vue';

export default Vue.extend({
  name: 'SpinnerCpt',
  props: {
    text: { type: String, required: false, default: 'Loading, please wait...' },
  },
});
