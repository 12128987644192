import Vue from 'vue';
import _ from 'lodash';

import { Map } from '@/core/models';
import { GridModel, GridFilter, GridCollection, GridHeader } from './grid.models';
import { DataOptions, DataTableHeader } from 'vuetify';

export const GridModelFactory = new Vue({
  methods: {
    /**
     * Builds the GridModel - binds filter, creates a default collection,
     * binds all the grid event handlers to a single object.
     * @param filter - Grid filter object that will be sent down to WebAPI.
     * @param headers - Grid headers (columns) definitions.
     */
    gridFactory<T>(filter: GridFilter, headers: GridHeader[] = []) {
      const collection: GridCollection<T> = {
        pageCount: null,
        items: [],
      } as any;

      const grid: GridModel<T> = {
        filter,
        collection,
        options: this.optionsFactory(filter),
        headers: this.headersFactory(headers),
        isLoading: false,

        onOptionsChanged: (options: DataOptions) => this.onOptionsChanged(filter, options),
        search: (searchClause?: string) => this.search(filter, searchClause),
        clearSearch: () => this.clearSearch(filter),
        sort: (sortBy: string, sortOrder: 'asc' | 'desc' | '') => this.sort(filter, sortBy, sortOrder),
        goToPage: (page: number) => this.goToPage(filter, page),
        update: () => this.update(),

        // The next 6 are currently not in use because the Vuetify
        // is smart enough to handle all this on it's own, but if we change
        // the UI toolkit, these will maybe needed as well
        prev: () => this.prev(filter),
        next: () => this.next(filter, collection),
        first: () => this.first(filter),
        last: () => this.last(filter, collection),
        isFirstPage: () => this.isFirstPage(filter),
        isLastPage: () => this.isLastPage(filter, collection),
      };

      return grid;
    },

    getParamValue(param: string) {
      if (!param) return param;

      switch (param.toLocaleLowerCase()) {
        case 'true':
          return true;
        case 'false':
          return false;
        default:
          return Number.isNaN(param) ? param : Number(param);
      }
    },
    /**
     * Builds the grid filter based on current route query params.
     */
    pageQueryParamsFactory(queryParams: Map<any>, defaultFilterOverrides?: GridFilter) {
      // These are standard, expected params and defaults need to be set
      const filter: GridFilter = {
        page: queryParams.page ? Number(queryParams.page) : 1,
        pageSize: queryParams.pageSize ? Number(queryParams.pageSize) : 20,
        search: queryParams.search || '',
        sortBy: queryParams.sortBy || '',
        sortOrder: queryParams.sortOrder || '',
      };

      // Map any other dynamic params
      _.each(queryParams, (param, paramName) => {
        if (!(filter as any)[paramName]) {
          (filter as any)[paramName] = this.getParamValue(param);
        }
      });

      // Initialize overrides, if any
      _.each(defaultFilterOverrides, (param, paramName) => {
        if (!(filter as any)[paramName]) {
          (filter as any)[paramName] = param;
        }
      });

      return filter;
    },

    /**
     * Initializes Vuetify options object. Otherwise it will
     * incorrectly trigger data re-fetch on initial page load.
     * @param filter - Current grid collection filter.
     * @returns Vuetify DataOptions object.
     */
    optionsFactory(filter: GridFilter) {
      const options = {} as DataOptions;

      if (filter.sortBy) {
        options.sortBy = [filter.sortBy];
        options.sortDesc = [false];

        if (filter.sortOrder) {
          options.sortDesc = [filter.sortOrder === 'desc'];
        }
      }

      return options;
    },

    /**
     * Converts a simplified, custom headers object (made by me),
     * into Vuetify DataTableHeader object for a bit of automation.
     */
    headersFactory(headers: GridHeader[] = []) {
      // For other possible props see: https://vuetifyjs.com/en/api/v-data-table/#props-headers
      return headers.map(
        header =>
          ({
            text: header.title,
            value: header.sortBy,
            sortable: !!header.sortBy,
            align: header.align,
            width: header.width,
          } as DataTableHeader),
      );
    },

    /**
     * Main Vuetify grid event handler.
     * @param filter - Current grid collection filter.
     * @param options - Vuetify grid options object which contains the latest grid state.
     */
    onOptionsChanged(filter: GridFilter, options: DataOptions) {
      const initialFilter = _.cloneDeep(this.clearFalsyParams(filter));
      this.resolveSortParamsFromOptions(filter, options);
      const finalFilter = _.cloneDeep(this.clearFalsyParams(filter));

      if (!_.isEqual(initialFilter, finalFilter)) {
        this.$eventHub.$emit('GRID_FILTER_UPDATED');
      }
    },

    /**
     * Forces grid update (URL update, and data refetch).
     */
    update() {
      this.$eventHub.$emit('GRID_FILTER_UPDATED');
    },

    /**
     * Attach this to the UI library grid events.
     * @param filter - Current grid collection filter.
     * @param searchClause - New search value.
     */
    search(filter: GridFilter, searchClause?: string) {
      const initialSearch = filter.search;

      filter.page = 1;
      // Only search when the search clause is at least 3 characters long
      (filter.search as any) = searchClause && searchClause.length >= 3 ? searchClause : undefined;

      // This is just to prevent filter.search = null or filter.search = ''
      if (!filter.search) {
        (filter.search as any) = undefined;
      }

      const searchChanged = filter.search !== initialSearch;

      if (searchChanged) {
        this.$eventHub.$emit('GRID_FILTER_UPDATED');
      }
    },

    /**
     * Clears grid collection filter search value.
     * @param filter - Current grid collection filter.
     * @param nextFilter - Next grid collection filter callback handler.
     */
    clearSearch(filter: GridFilter) {
      filter.page = 1;
      filter.search = '';

      this.$eventHub.$emit('GRID_FILTER_UPDATED');
    },

    /**
     * Resolves GridFilter sort props from Vuetify options object.
     * @param filter - Current grid collection filter.
     * @param options - Vuetify grid options object which contains the latest grid state.
     */
    resolveSortParamsFromOptions(filter: GridFilter, options: DataOptions) {
      const sortBy = options.sortBy[0];
      const sortOrder = options.sortDesc[0] ? 'desc' : 'asc';
      this.resolveSortParams(filter, sortBy, sortOrder);
    },

    /**
     * Resolves sort params on the GridFilter object.
     * @param filter - Current grid collection filter.
     * @param sortBy - Sort params.
     * @param sortOrder - Sort direction (optional). Allowed: "asc" / "desc".
     */
    resolveSortParams(filter: GridFilter, sortBy: string, sortOrder: 'asc' | 'desc' | '' = '') {
      filter.sortBy = sortBy;
      filter.sortOrder = sortBy ? sortOrder : '';
    },

    /**
     * On grid sort changed event handler.
     * @param filter - Current grid collection filter.
     * @param sortBy - Sort params.
     * @param sortOrder - Sort direction (optional). Allowed: "asc" / "desc".
     */
    sort(filter: GridFilter, sortBy: string, sortOrder: 'asc' | 'desc' | '' = '') {
      this.resolveSortParams(filter, sortBy, sortOrder);

      this.$eventHub.$emit('GRID_FILTER_UPDATED');
    },

    /**
     * On grid specific page selected
     * @param filter - Current grid collection filter.
     * @param page Page to go to.
     */
    goToPage(filter: GridFilter, page: number) {
      filter.page = page;
      this.$eventHub.$emit('GRID_FILTER_UPDATED');
    },

    /**
     * On grid previous page event handler.
     * @param filter - Current grid collection filter.
     */
    prev(filter: GridFilter) {
      if (!filter.page) {
        filter.page = 1;
      }

      filter.page--;

      if (filter.page < 1) {
        filter.page = 1;
      }

      this.$eventHub.$emit('GRID_FILTER_UPDATED');
    },

    /**
     * On grid next page event handler.
     * @param filter - Current grid collection filter.
     * @param collection - Current grid collection model.
     */
    next(filter: GridFilter, collection: GridCollection<any>) {
      if (!filter.page) {
        filter.page = 1;
      }

      filter.page++;

      if (collection.pageCount && filter.page > collection.pageCount) {
        filter.page = collection.pageCount;
      }

      this.$eventHub.$emit('GRID_FILTER_UPDATED');
    },

    /**
     * On grid first page event handler.
     * @param filter - Current grid collection filter.
     */
    first(filter: GridFilter) {
      filter.page = 1;
      this.$eventHub.$emit('GRID_FILTER_UPDATED');
    },

    /**
     * On grid last page event handler.
     * @param filter - Current grid collection filter.
     */
    last(filter: GridFilter, collection: GridCollection<any>) {
      (filter.page as any) = collection.pageCount;
      this.$eventHub.$emit('GRID_FILTER_UPDATED');
    },

    /**
     * Checks if the grid is currently on the first page of data.
     * @param filter - Current grid collection filter.
     */
    isFirstPage(filter: GridFilter) {
      return Number(filter.page) === 1;
    },

    /**
     * Checks if the grid is currently on the last page of data.
     * @param filter - Current grid collection filter.
     * @param collection - Current grid collection model.
     */
    isLastPage(filter: GridFilter, collection: GridCollection<any>) {
      return Number(filter.page) === collection.pageCount;
    },

    /**
     * Clears falsy values (null, undefined, "") from a filter and only keeps the defined params.
     * @param filter - Filter to clean up.
     * @returns Cleaned up filter.
     */
    clearFalsyParams(filter: GridFilter) {
      return _.pickBy(filter, p => !!p) as GridFilter;
    },
  },
});
