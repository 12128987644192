import { AxiosFactory } from '@/core/services/common';
import {
  Configuration,
  UsersApiFactory,
  CompaniesApiFactory,
  QuestionnairesApiFactory,
  HomeApiFactory,
  SupportResourcesApiFactory,
  ReportsApiFactory,
  HouseFloorsApiFactory,
  ContentManagementApiFactory,
  EventsApiFactory,
} from '@/core/webapi';

function buildArgs() {
  return [new Configuration({ basePath: process.env.VUE_APP_WEBAPI_URL }), undefined, AxiosFactory.create()] as any[];
}

export const ApiService = {
  users: () => UsersApiFactory(...buildArgs()),
  companies: () => CompaniesApiFactory(...buildArgs()),
  questionnaires: () => QuestionnairesApiFactory(...buildArgs()),
  home: () => HomeApiFactory(...buildArgs()),
  supportResources: () => SupportResourcesApiFactory(...buildArgs()),
  reports: () => ReportsApiFactory(...buildArgs()),
  houseFloors: () => HouseFloorsApiFactory(...buildArgs()),
  contentManagement: () => ContentManagementApiFactory(...buildArgs()),
  events: () => EventsApiFactory(...buildArgs()),
};

// This global results in slightly odd behaviour, it requires improving
// Wait and see: https://stackoverflow.com/q/70169539/413785

declare global {
  interface Promise<T> {
    handleValidationErrors<TResult1 = T, TResult2 = never>(model: any): Promise<TResult1 | TResult2>;
    getValidatableData<TResult1 = T, TResult2 = never>(): Promise<TResult1 | TResult2>;
    // handleValidationErrors<T extends Validatable>(model: any): Promise<AxiosResponse<T>>;
    // getValidatableData<T extends Validatable>(): Promise<T>;
  }
}

// This is used to automatically attach validation errors and validation summary to a model
Promise.prototype.handleValidationErrors = function (model: any) {
  return this.then(
    response => Promise.resolve(response),
    error => {
      if (error?.response?.status === 422) {
        model.errors = error.response.data?.errors;
        model.validationSummary = error.response.data?.validationSummary;
      }
      return Promise.reject(error);
    },
  );
};

// This is used to extract data from the response and to automatically reset errors to an empty array on data fetch
Promise.prototype.getValidatableData = function () {
  return this.then(
    response => {
      const data = response.data;
      data.errors = {};
      data.validationSummary = [];
      return Promise.resolve({ ...data });
    },
    error => Promise.reject(error),
  );
};

// // This is used to automatically attach validation errors and validation summary to a model
// Promise.prototype.handleValidationErrors = function<T extends Validatable>(model: any) {
//   return this.then(
//     (response: AxiosResponse<T>) => Promise.resolve(response),
//     (error: AxiosError<T>) => {
//       if (error?.response?.status === 422) {
//         model.errors = error.response.data?.errors;
//         model.validationSummary = error.response.data?.validationSummary;
//       }
//       return Promise.reject(error);
//     },
//   );
// };

// // This is used to extract data from the response and to automatically reset errors to an empty array on data fetch
// Promise.prototype.getValidatableData = function<T extends Validatable>() {
//   return this.then(
//     (response: AxiosResponse<T>) => {
//       const data = response.data;
//       data.errors = {};
//       data.validationSummary = [];
//       return Promise.resolve(data);
//     },
//     (error: AxiosError<T>) => Promise.reject(error),
//   );
// };
