
















import Vue, { PropType } from 'vue';

import { GridModel } from './grid.models';

export default Vue.extend({
  name: 'GridSearchCpt',
  props: {
    grid: Object as PropType<GridModel<any>>,
  },
  data: () => ({
    debounce: 0,
    search: '',
  }),
  created() {
    this.search = this.grid.filter.search;
    this.$eventHub.$on('GRID_DATA_UPDATED', () => {
      this.$forceUpdate();
    });
  },
  methods: {
    onChange() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.grid.search(this.search);
      }, 350);
    },

    onClear() {
      this.grid.search('');
    },
  },
});
