import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

// 00953a - initial colour
// 167e48 - best one so far
// 06923d - from https://idaireland.getproven.com/?view=grid
const color = '#2C6343'; // 57ad70 // '#16997f'; // #479f6f
const secondary = '#B54209';
const accent = '#A7C762';
const error = '#B30000';
const info = '#08599B';

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: color,
        secondary: secondary,
        accent: accent,
        success: color,
        error: error,
        info: info,
      },
      dark: {
        primary: color,
      },
    },
  },
});
