/* eslint-disable */
export const LoggingService = {
  log(...data: any[]) {
    this.info(data);
  },
  debug(...data: any[]) {
    // console.debug(data);
  },
  info(...data: any[]) {
    // console.info(data);
  },
  warn(...data: any[]) {
    // console.warn(data);
  },
  error(...data: any[]) {
    // console.error(data);
  },
};
/* eslint-enable */
