export const homeRoutes = [
  {
    path: '/',
    name: 'home',
    meta: { layout: 'landing', allowAnonymous: true },
    component: () => import(/* webpackChunkName: "home" */ './HomeView.vue'),
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: { layout: 'dashboard' },
    component: () => import(/* webpackChunkName: "home" */ './DashboardView.vue'),
  },
];
