import Vue from 'vue';
import _ from 'lodash';
import { AxiosPromise } from 'axios';

// NOTE: Had to use router like this, because of some vue-router retardation
// SEE: https://stackoverflow.com/q/49228042/413785
import router from '@/router';
import { GridModel, GridCollection, GridFilter } from './grid.models';
import { GridModelFactory } from './grid-model.factory';
import { GridParams } from '.';

export const GridFactory = new Vue({
  data: () => ({
    grid: {} as GridModel<any>,
  }),
  methods: {
    /**
     * Initializes the grid component. This method is supposed to be called from
     * the class that's implementing the BaseGridComponent class.
     * Builds a grid object that contains filter, collection and event handlers.
     *
     * @param params - Grid factory params.
     */
    create<T>(params: GridParams<T>): GridModel<T> {
      const filter = this.$_GridFactory_defaultQueryParamsFactory(params.defaultFilterOverrides);

      // Initialize the shared grid model
      this.grid = GridModelFactory.gridFactory<T>(filter, params.headers);

      // Prevent duplication of events, will potentially have to
      // improve this in the future, but for now this is ok
      this.$eventHub.$off('GRID_FILTER_UPDATED');

      // On grid interaction, update the route and re-fetch data
      this.$eventHub.$on('GRID_FILTER_UPDATED', () => {
        this.$_GridFactory_updateRoute(filter, params.routeName);
        this.$_GridFactory_filterCollection(params.filterMethod, this.grid);
      });

      // Initialize the first fetch
      this.$eventHub.$emit('GRID_FILTER_UPDATED');

      return this.grid;
    },

    // SEE: https://vuejs.org/v2/style-guide/#Private-property-names-essential

    /**
     * Builds default query params filter. If any additional params need to be added, or any of the
     * default ones changed - override/extend the query params passed to the initializeGrid() in the
     * concrete grid implementation class.
     */
    $_GridFactory_defaultQueryParamsFactory(defaultFilterOverrides?: GridFilter) {
      return GridModelFactory.pageQueryParamsFactory(router.currentRoute.query, defaultFilterOverrides);
    },

    /**
     * Updates the current grid collection route with currently defined query params (based on filter).
     * @param filter - Current grid collection filter.
     * @param route - Route name to transition to on filter change.
     */
    $_GridFactory_updateRoute(filter: GridFilter, routeName?: string) {
      if (routeName) {
        const definedParams = GridModelFactory.clearFalsyParams(filter);

        router.push({ name: routeName, query: definedParams as any }).catch(error => {
          // SEE: https://stackoverflow.com/a/59431264/413785
          if (error.name !== 'NavigationDuplicated') {
            throw error;
          }
        });
      }
    },

    /**
     * Handles the actual data fetching/filtering.
     * @param filterMethod - Filter method to be called on filter change to fetch new data.
     * @param filter - Current grid collection filter.
     */
    $_GridFactory_filterCollection<T>(
      filterMethod: (filter: GridFilter) => AxiosPromise<GridCollection<T>>,
      grid: GridModel<T>,
    ) {
      grid.isLoading = true;
      this.$forceUpdate();

      const definedParams = GridModelFactory.clearFalsyParams(grid.filter);

      filterMethod(definedParams)
        .then(collection => _.extend(this.grid.collection, collection.data))
        .catch(() => {
          _.extend(this.grid.collection, {
            pageCount: 0,
            totalItemCount: 0,
            items: [],
          });
        })
        .finally(() => {
          grid.isLoading = false;
          this.$eventHub.$emit('GRID_DATA_UPDATED');
          this.$forceUpdate();
        });
    },
  },
});
