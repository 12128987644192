


























import Vue, { PropType } from 'vue';

import { SimpleLookup } from '@/core/webapi';
import { AppFormComponent } from './app-form-component';

export default Vue.extend({
  name: 'InputComboboxCpt',
  props: {
    model: Object,
    lookups: Array as PropType<SimpleLookup[]>,
    prop: String,
    label: String,
    hint: String,
    placeholder: String,
    disabled: Boolean,
  },
  computed: {
    items() {
      return this.lookups ?? [];
    },
    errorMessage() {
      return AppFormComponent.errorMessage(this.model, this.prop);
    },
  },
  data: () => ({
    internalSelected: [] as SimpleLookup[],
  }),
  watch: {
    model(model: any) {
      const selectedIds = model[this.prop].filter((p: number) => !!p);
      this.internalSelected = this.lookups.filter(p => selectedIds.includes(p.id));
      this.$forceUpdate();
    },
  },
  methods: {
    onChange(value: SimpleLookup[]) {
      const selectedIds = value.map(p => p.id).filter(p => !!p);
      (this.$props.model as any)[this.$props.prop] = selectedIds;
      AppFormComponent.onChange(this, selectedIds, this.$props.model, this.$props.prop);

      // Clear search text
      this.internalSelected = this.lookups.filter(p => selectedIds.includes(p.id));
    },
  },
});
