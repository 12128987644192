





















import Vue from 'vue';
import ButtonCpt from './ButtonCpt.vue';

export default Vue.extend({
  name: 'TextReadMoreCpt',
  components: {
    ButtonCpt,
  },
  props: {
    text: {
      type: String,
      required: true,
    },
    maxSize: {
      type: Number,
      required: false,
      default: 150,
    },
  },
  data: () => ({
    show: false,
  }),
});
