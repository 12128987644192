












































import Vue from 'vue';
import ButtonCpt from './ButtonCpt.vue';

export default Vue.extend({
  name: 'CallToRegisterCpt',
  components: {
    ButtonCpt,
  },
  data: () => ({
    showDialog: false,
  }),
  created() {
    this.$eventHub.$on('CALL_TO_REGISTER', () => {
      this.showDialog = true;
    });
  },
  methods: {
    async login() {
      this.showDialog = false;
      return await this.$msal.login();
    },
    register() {
      this.showDialog = false;
      return this.$router.push('register');
    },
  },
});
